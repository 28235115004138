import React, { useCallback, useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

import MemberList from "./MemberList";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { DatePicker } from "antd";
import { headerOptions } from "../../../utils/utilities/utilityFunctions";
import axios from "axios";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";

const GetAllMembers = () => {
  const {
    getAllMemberWiseReportData,
    initialState,
    initialStateLoading,
    mainURL,
    userId,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");
  const [activeTab, setActiveTab] = useState("member");
  const [filters, setFilters] = useState({
    team: [],
    showAvailableTimeEntries: false,
    userActive: "active",
  });

  const handleFilterChange = (type, value) => {
    if (type === "team" && value.length > 5) {
      ReactHotToast("You can only select up to 5 teams.", "error");
    } else {
      setFilters((prev) => ({
        ...prev,
        [type]: value,
      }));
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, filters]);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      const status = activeTab === "member" ? "active" : "inactive";
      getAllMemberWiseReportData(
        formattedStartDate,
        formattedEndDate,
        currentPage,
        pageSize,
        status,
        searchQuery,
        filters
      );
    }, 500),
    [
      activeTab,
      currentPage,
      pageSize,
      formattedStartDate,
      formattedEndDate,
      filters,
    ]
  );
  useEffect(() => {
    if (searchInput.trim() === "") {
      const status = activeTab === "member" ? "active" : "inactive";
      getAllMemberWiseReportData(
        formattedStartDate,
        formattedEndDate,
        currentPage,
        pageSize,
        status,
        "",
        filters
      );
    } else {
      debouncedSearch(searchInput);
    }

    return () => debouncedSearch.cancel();
  }, [
    searchInput,
    currentPage,
    pageSize,
    filters,
    activeTab,
    debouncedSearch,
    formattedStartDate,
    formattedEndDate,
  ]);

  // useEffect(() => {
  //   if (searchInput !== "") {
  //     debouncedSearch(searchInput);
  //   } else {
  //     getAllMemberWiseReportData(
  //       formattedStartDate,
  //       formattedEndDate,
  //       currentPage,
  //       pageSize,
  //       "",
  //       filters
  //     );
  //   }

  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [
  //   searchInput,
  //   currentPage,
  //   pageSize,
  //   filters,
  //   debouncedSearch,
  //   isUpdated,
  //   formattedStartDate,
  //   formattedEndDate,
  // ]);
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState({
    rangeStartDate: null,
    rangeEndDate: null,
  });
  const handleRangeChange = (selectedDates) => {
    if (selectedDates && selectedDates.length === 2) {
      const [start, end] = selectedDates;
      if (start.isBefore(dayjs()) && end.isBefore(dayjs())) {
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        setDates({ rangeStartDate: start, rangeEndDate: end });
      }
    } else {
      setSelectedStartDate(dayjs());
      setSelectedEndDate(dayjs());
      setDates({ rangeStartDate: null, rangeEndDate: null });
    }

    setCurrentPage(1);
  };
  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);

    setCurrentPage(1);
  };
  async function TeamLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}get/team/${userId}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSearchInput("");
    setCurrentPage(1);
  };
  return (
    <MemberList
      memberReportData={initialState?.allMemberReports || []}
      isLoading={initialStateLoading?.getAllMemberWiseReportDataLoading}
      setIsUpdated={setIsUpdated}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      handleRangeChange={handleRangeChange}
      setPageSize={setPageSize}
      isUpdated={isUpdated}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      formattedStartDate={formattedStartDate}
      formattedEndDate={formattedEndDate}
      setSelectedStartDate={setSelectedStartDate}
      setSelectedEndDate={setSelectedEndDate}
      setDates={setDates}
      handleInputChange={handleInputChange}
      searchInput={searchInput}
      filters={filters}
      onFilterChange={handleFilterChange}
      TeamLoadOptions={TeamLoadOptions}
      activeTab={activeTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default GetAllMembers;
