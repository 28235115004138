import React, { useCallback, useContext, useEffect, useState } from "react";

import ClientWiseContent from "./Components/ClientWiseContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";

const ClientWise = () => {
  const { initialState, getAllClientsReportData, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
 
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      getAllClientsReportData(currentPage, pageSize, query, );
    }, 500),
    [currentPage, pageSize, ]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchQuery !== "") {
      debouncedSearch(searchQuery);
    } else {
      getAllClientsReportData(currentPage, pageSize, "",);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, currentPage, pageSize, , debouncedSearch, isUpdated]);

  // Handle filter changes


  // Handle search changes
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };
  useEffect(() => {
      setCurrentPage(1);
  }, [searchQuery]);
  return (
    <ClientWiseContent
      clientsReportData={initialState?.allReports?.client_data||[]}
      setIsUpdated={setIsUpdated}
      isLoading={initialStateLoading?.getAllClientsReportDataIsLoading}
      totalPages={initialState?.allReports?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
    />
  );
};

export default ClientWise;
