import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import {
  PlusIconSVG,
  projectsIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";

import styles from "./CheckTimeEntryDetails.module.css";
import {
  formatDate,
  formatTime,
  getTime,
  getTwelveHoursTime,
} from "../../../../../utils/utilities/utilityFunctions";

const MyVerticallyCenteredModal = ({ show, onHide, timeEntry }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Time Entry Details</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p className={`${styles.jobName} mb-3 fw-bold`}>
          {timeEntry?.task_name}
        </p>
        <p className={`${styles.text} mb-3`}>
          <span className="fw-semibold"> Member: </span>
          <span> {timeEntry?.member_name}</span>
        </p>
        <p className={`${styles.text} mb-3`}>
          <span className="fw-semibold"> Description: </span>
          <span className="mx-2"> {timeEntry?.work_description}</span>
        </p>
        <p className={`${styles.text} mb-3`}>
          <span className="fw-semibold">Entry Date:</span>
          <span className="mx-2">{formatDate(timeEntry?.working_date)}</span>
        </p>
        <div className="d-flex gap-4 mb-3">
          <p className={`${styles.text} m-0`}>
            <span className="fw-semibold">Start Time:</span>
            <span className="mx-2">
              {getTwelveHoursTime(timeEntry?.work_start_time)}
            </span>
          </p>
          <p className={`${styles.text} m-0`}>
            <span className="fw-semibold">End Time:</span>
            <span className="mx-2">
              {getTwelveHoursTime(timeEntry?.work_end_time)}
            </span>
          </p>
        </div>

        <p className={`${styles.text} mb-3`}>
          <span className="fw-semibold">Reviewer:</span>
          <span className="mx-2">{timeEntry?.reviewer_name}</span>
        </p>
        <p className={`${styles.text} mb-3`}>
          <span className="fw-semibold">Adjustment Hours: </span>
          <span className="mx-2">
            {formatTime(timeEntry?.adjustment_hours)}
          </span>
        </p>
        <p className={`${styles.text} mb-1`}>
          <span className="fw-semibold"> Adjustment Reason: </span>
          <span className="mx-2"> {timeEntry?.adjustment_hours_reason}</span>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export const CheckTimeEntryDetails = ({ timeEntry }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <PlusIconSVG />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        timeEntry={timeEntry}
      />
    </>
  );
};
