import React, { useContext, useState, useMemo, useEffect } from "react";
import { debounce } from "lodash";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import {
  clientsIcon1,
  adHoc,
  searchIcon,
  PlusIconSVG,
  TrashSVG,
  InvoiceIcon,
  EditSVG,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import AdHocTable from "./AdHocTable";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
// import { EditBillingServicesModal } from "./EditBillingServicesModal";
import { format, parse, parseISO, isValid } from "date-fns";
import { AsyncPaginate } from "react-select-async-paginate";
import PaginationComponent from "../../../Components/Pagination/PaginationComponent";
const userID = localStorage.getItem("userId");

const AdHocContent = ({
  adHocClientsList,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  searchInput,
  setSearchInput,
  pageSize,
  isUpdated,
  totalPages,
  setPageSize,
  handleSearch,
  handleInputChange,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const {
    mainURL,
    logout,
    initialState,
    getAllClients,
    getUserDetails,
    userDetails,
  } = useContext(ContextAPI);

  const navigate = useNavigate();
  const [isUserValid, setIsUserValid] = useState(false);
  const [client, setClient] = useState("");
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [nextPageScroll, setNextPageScroll] = useState(true);
  const [page, setPage] = useState(1);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getAllClients();
    getUserDetails();
  }, []);

  const detectAndParseDate = (dateStr) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    } else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }

    return null;
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "N.A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N.A";

    const day = format(dateObj, "d");
    const month = format(dateObj, "MMM");
    const year = format(dateObj, "yyyy");

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };
  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Ad Hoc",
      pageURL: "",
    },
  ];
  const [options, setOptions] = useState({
    clientOptions: [],
  });

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
   
    {
      Header: "BPO No",
      accessor: "bpo_no",
      Cell: ({ row }) => {
        try {
          return JSON.parse(row.original?.additional_data)?.bpo_no ||  <span style={{ color: "grey" }}> {"N/A"} </span>;
        } catch {
          return "N.A";
        }
      },
    },
    
    
    // {
    //   Header: "BPO No",
    //   accessor: "bpo_no",
    //   filter: "custom",
    //   Cell: ({ row }) => {
    //     const additionalData = JSON.parse(row.original.additional_data);
    //     return (
    //       additionalData.bpo_no || (
    //         <span style={{ color: "grey" }}> {"N/A"} </span>
    //       )
    //     );
    //   },
    // },
    {
      Header: "Created Date",
      accessor: "created_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.created_on);
        return (
          <div className="gap-1">
            <p className="m-0">{startDate}</p>
          </div>
        );
      },
    },

    {
      Header: "invoice No",
      accessor: "invoice_no",
    },

    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <Tooltip
            id="generate-invoice-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          {row.original.invoice_url ? null : (
            <div
              data-tooltip-id="generate-invoice-tooltip"
              data-tooltip-content="Generate Invoice"
              data-tooltip-place="top"
              onClick={() => {
                const invoiceNo = row.original.invoice_no;
                const clientEmail = row.original.client_email;
                const adHocMailInvoice = "adHocMailInvoice";
                navigate(`/hoc-invoice/${row.original.client_id}`, {
                  state: {
                    id: row.original.id,
                    invoiceNo: invoiceNo,
                    adHocMailInvoice: adHocMailInvoice,
                    clientEmail: clientEmail,
                  },
                });
              }}
            >
              <PlusIconSVG />
            </div>
          )}
          <Tooltip
            id="preview-invoice-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          {row.original.invoice_url ? (
            <div
              data-tooltip-id="preview-invoice-tooltip"
              data-tooltip-content="Preview Invoice"
              data-tooltip-place="top"
            >
              <div
                onClick={() => {
                  const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                  const rowData = row.original;
                  const assignId = row.original.id;
                  const clientEmail = row.original.client_email;
                  navigate(`/ad-hoc-billing-invoice/${row.original.id}`, {
                    state: {
                      adHocMail: "adHocMail",
                      invoice_id: row.original.id,
                      invoice_url: invoiceUrl,
                      row: rowData,
                      assignId: assignId || null,
                      clientEmail: clientEmail,
                      breadcrumbs: [
                        { pageName: "Ad Hoc Billing", pageURL: "/add-hoc" },
                        { pageName: "Invoice", pageURL: "" },
                      ],
                    },
                  });
                }}
              >
                <InvoiceIcon />
              </div>
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const columnHeaders = ["Sr no.", "invoice No", "Bpo No", "Client Name"];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => adHocClientsList, [adHocClientsList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "invoice no", key: "invoice_no" },
      { label: "Created Date", key: "created_on" },
      { label: "Client Name", key: "client_name" },
    ],
    fileName: "Ad Hoc Client List",
  };

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      clientOptions:
        clients &&
        clients?.map(({ client_name, additional_data, client_id }) => {
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          return { label, value: client_id };
        }),
    }));
  }, [clients]);

  const addNewClient = async () => {
    const body = {
      client_id: client?.clientId?.value ?? null,
      current_user:
        localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
    };

    setIsUserValid(true);

    try {
      const url = `${mainURL}add/invoice/ad-hoc-billing`;
      const result = await axios.post(url, body, { headers: headerOptions() });

      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setClient({ clientId: null });
        setValueForClient(null);
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsUserValid(false);
    }
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    if (client) {
      addNewClient();
    } else {
      ReactHotToast("Please select client!", "error");
    }
  };

  const handleDropDown = (dropDown, option) => {
    if (dropDown === "clientId") {
      setClient((prev) => ({ ...prev, clientId: option }));
    }
    setOptions((prev) => ({
      ...prev,
      clientOptions:
        clients &&
        clients?.map((client) => {
          const { client_name, additional_data, client_id } = client;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          const billingRates = additional_data?.billing_rates || [];

          return {
            label,
            value: client_id,
            billingRates,
          };
        }),
    }));
  };

  // Infinite Scrolling

  // Fetch clients based on page and limit
  // const fetchClients = async (currentPage) => {
  //   setIsDropdownLoading(true);
  //   try {
  //     if (userDetails?.member_id) {
  //       let URL = `${mainURL}list/all-client/${
  //         userID || userDetails?.member_id
  //       }?limit=${10}&page=${currentPage}`;

  //       const response = await axios.get(URL, {
  //         headers: headerOptions(),
  //       });
  //       // const response = await getAllClients();
  //       const newClients = response?.data?.client_list?.client_data;
  //       if (newClients.length === 0) {
  //         setNextPageScroll(false);
  //       }
  //       // Append new data to existing list
  //       setClients((prev) => [...prev, ...newClients]);

  //       setOptions((prev) => ({
  //         ...prev,
  //         clientOptions:
  //           clients &&
  //           clients?.map((client) => {
  //             const { client_name, additional_data, client_id } = client;
  //             const companyName = additional_data?.company_name || "";
  //             const bpoNo = additional_data?.bpo_no?.toString() || "";
  //             const label = `${client_name} (${companyName}${
  //               bpoNo ? ` - ${bpoNo}` : ""
  //             })`;
  //             const billingRates = additional_data?.billing_rates || [];

  //             return {
  //               label,
  //               value: client_id,
  //               billingRates,
  //             };
  //           }),
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching clients:", error);
  //   } finally {
  //     setIsDropdownLoading(false);
  //   }
  // };
  const fetchClients = async (currentPage) => {
    setIsDropdownLoading(true);
    try {
      if (userDetails?.member_id) {
        let URL = `${mainURL}list/all-client/${
          userID || userDetails?.member_id
        }?limit=${10}&page=${currentPage}`;

        const response = await axios.get(URL, {
          headers: headerOptions(),
        });

        const newClients = response?.data?.client_list?.client_data || [];

        if (newClients.length === 0) {
          setNextPageScroll(false);
        }

        setClients((prev) => [...prev, ...newClients]);

        // Ensure correct response structure for react-select-async-paginate
        const clientOptions = newClients.map((client) => {
          const { client_name, additional_data, client_id } = client;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          const billingRates = additional_data?.billing_rates || [];

          return {
            label,
            value: client_id,
            billingRates,
          };
        });

        setOptions((prev) => ({
          ...prev,
          clientOptions: {
            options: clientOptions,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setIsDropdownLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchClients(page);
  }, [page, userDetails?.member_id]);

  // Handle scroll to bottom
  const [valueForClient, setValueForClient] = useState(null);

  async function LoadClientsOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}/list/all-client/${userID || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.client_list?.client_data.map(
        (item) => {
          try {
            // Parse the additional client data
            const { client_name, additional_data, client_id } = item;
            const companyName = additional_data?.company_name || "";
            const bpoNo = additional_data?.bpo_no?.toString() || "";
            const label = `${client_name} (${companyName}${
              bpoNo ? ` - ${bpoNo}` : ""
            })`;

            // Create the label for the job

            return {
              value: client_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.client_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response.data?.client_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance, isUpdated]);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={adHoc} alt="members" />
          <p className="m-0 fs-4">Ad Hoc Billing</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>

      <section className="main-content_form-section gap-3 d-flex flex-column justify-content-start align-items-center  m-auto">
        <form
          onSubmit={handleAddClient}
          className="w-100 mt-5 d-flex  align-items-end gap-3"
        >
          <div className="relative-wrapper w-25">
            <label htmlFor="clientId">Select Client:</label>
            {/* <Select
              name="clientId"
              closeMenuOnSelect={true}
              options={options.clientOptions}
              onChange={(option) => handleDropDown("clientId", option)}
              onMenuScrollToBottom={nextPageScroll && handleScroll}
              onInputChange={(inputValue) => debouncedHandleSearch(inputValue)}
              isLoading={isDropdownLoading}
              value={client.clientId}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            /> */}

            <AsyncPaginate
              className="react-select-custom-styling__container"
              value={valueForClient}
              loadOptions={LoadClientsOptions}
              onChange={(selectedOption) => {
                setValueForClient(selectedOption);
                handleDropDown("clientId", selectedOption);
              }}
              additional={{
                page: 1,
              }}
              placeholder="Select Client"
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Client found for "${inputValue}"`
                  : "No Client found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Client", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          <button type="submit" className=" custom-btn">
            {isUserValid ? <SpinningLoader /> : "Add Client"}
          </button>
        </form>
      </section>

      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <AdHocTable
            tableInstance={tableInstance}
            headers={headers}
            adHocClientsList={adHocClientsList}
            columnHeaders={columnHeaders}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={adHocClientsList}
            text="adHocBilling"
          />
        </>
      )}
    </div>
  );
};

export default AdHocContent;
