import React from "react";
import styles from "../Invoices.module.css";

const InvoiceFooter = ({ isPreview, invoice, setInvoice }) => {
  return (
    <div
      className="card-footer bg-white form-group"
      style={{ padding: "1rem" }}
    >
      <label className={styles.heading}>Note: </label>
      {!isPreview ? (
        <input
          placeholder="Eg: Thank you for working with us!"
          type="text"
          style={{ paddingRight: "20px", textOverflow: "ellipsis" }}
          value={invoice.note}
          onChange={(e) =>
            setInvoice((prev) => ({ ...prev, note: e.target.value }))
          }
        />
      ) : (
        <p className="m-0 primary-font-color font-outfit">
          {invoice.note !== "" ? invoice.note : "No notes available"}
        </p>
      )}

      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: "100%", marginTop: "10px" }}
      >
        <div
          className="d-flex flex-column font-outfit primary-font-color px-3 mt-2"
          style={{ fontSize: "14px" }}
        >
          <span className="fw-bold">Doshi (London) Ltd.</span>
          <span>T/A Doshi & Co.</span>
          <span>VAT Reg. No: 885 6841 65</span>
        </div>
        <div
          className="d-flex flex-column mt-2 font-outfit primary-font-color px-3"
          style={{ fontSize: "14px", textAlign:"center" }}
        >
          <span>Directors : Mr Veer Doshi BBA, Mr Dhruv Doshi B.Sc.</span>
          <span>Associates : DAS UK Ltd, Chartered Accountants</span>
          <span>Consultants : Mr Tushar Magia B.Com, FCA</span>
          <span>Co. Reg : 5315639</span>
        </div>
        <div
          className="d-flex flex-column mt-2 font-outfit primary-font-color"
          style={{ fontSize: "14px" }}
        >
          <span>Bank Account : Nat West Bank</span>
          <span>Sort Code : 60-15-16</span>
          <span>Account Number : 64063798</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooter;
