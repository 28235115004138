// import React, { useContext } from "react";

// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext"
// import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";

// const BillableTimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const shouldShowContent =
//   userDetails?.member_role === "it_member" ||
//   userDetails?.member_role === "operation_member";

//   return (
//     <>

//      {shouldShowContent && <BillableTimeEntriesContent />}

//     </>
//   );
// };

// export default BillableTimeEntries;

import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import axios from "axios";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";
import { headerOptions } from "../../../../utils/utilities/utilityFunctions";
import dayjs from "dayjs";
const BillableTimeEntries = () => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const {
    getAllBillableTimeEntries,
    getAllJobCategories,
    initialState,
    initialStateLoading,
    userDetails,
    userId,
    mainURL,
  } = useContext(ContextAPI);

  const [filters, setFilters] = useState({
    status: null, 
    entryAs: null, 
    showDiscountedHoursEntries: false, 
    member: null,
    date: null,
    team:null
  });
  const shouldShowContent =
    userDetails?.member_role === "it_member" ||
    userDetails?.member_role === "operation_member";

  useEffect(() => {
    // If search is cleared, reset the page to 1
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      getAllBillableTimeEntries(currentPage, pageSize, query, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllBillableTimeEntries(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, currentPage, pageSize, filters, debouncedSearch, isUpdated]);

  const [valueForEmployee, setValueForEmployee] = useState(null);

  async function UsersLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}list/all-member/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );
      const transformedData = response.data?.members_list?.users
        ?.filter(
          (item) =>
            item.member_role !== "it_member" &&
            item.member_role !== "operation_member"  && item?.current_status !== "inactive"
        )
        .map((item) => {
          try {
            const label = `${item.member_name || "N/A"}`;
            return {
              value: item.member_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.member_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

          setValueForEmployee(combinedOptions);
      const hasMore =
        Array.isArray(response.data?.members_list?.users) &&
        response.data.members_list.users.length > 0;

      return {
        options: combinedOptions,
        hasMore: response.data?.members_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  const [valueForTeam, setValueForTeam] = useState(null);
  async function UsersTeamLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}get/team/${userId || userDetails?.member_id}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
          setValueForTeam(combinedOptions)
      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  const handleMemberChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      member: selectedOption,
    }));
  };

  // const handleChange = (date, dateString) => {
  //   const dayjsDate = dayjs(date);
  //   setFilters((prev) => ({
  //     ...prev,
  //     date:dayjsDate,
  //     dateString: dateString, // Save as dayjs object
  //   }));

  // };
  const handleChange = (date, dateString) => {
    setFilters((prev) => ({
      ...prev,
      // date: dayjs(date),
      date: date ? dayjs(date) : null,
    }));
  };

  return (
    <>
      {shouldShowContent && (
        <BillableTimeEntriesContent
          billableTimeEntriesPropsData={initialState?.allBillableTimeEntries}
          setIsUpdated={setIsUpdated}
          isLoading={initialStateLoading?.getAllBillableTimeEntriesIsLoading}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleInputChange={handleInputChange}
          setFilters={setFilters}
          filters={filters}
          UsersLoadOptions={UsersLoadOptions}
          valueForEmployee={valueForEmployee}
          valueForTeam={valueForTeam}
          handleChange={handleChange}
          handleMemberChange={handleMemberChange}
          UsersTeamLoadOptions={UsersTeamLoadOptions}
        />
      )}
    </>
  );
};

export default BillableTimeEntries;
