import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import dayjs from "dayjs";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { fileIcon } from "../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import TimeEntriesTable from "../../Components/TimeEntriesTable";
import { UpdateTimeEntryModal } from "./components/UpdateTimeEntryModal";
// import TimeEntriesFilter from "../../Components/TimeEntriesFilter";
import TimeEntriesFilter from "../../AdminOrManager/TimeEntries/components/TimeEntriesFilter";
import { AddTimeEntryModal } from "./components/AddTimeEntryModal";
import {
  formatDate,
  formatTime,
} from "../../../../utils/utilities/utilityFunctions";
import { CheckTimeEntryDetails } from "./components/CheckTimeEntryDetails";
import PaginationComponent from "../../../../Components/Pagination/PaginationComponent";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Jobs",
    pageURL: "/jobs",
  },
  {
    pageName: "Time Entries",
    pageURL: "/jobs/time-entries",
  },
];

// constructing headers for CSV Link

const AllTimeEntriesContent = ({
  setIsUpdated,
  isLoading,
  fetchedTimeEntriesList,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
  filters,
  onFilterChange,
  UsersLoadOptions,
  TeamLoadOptions,
  value,
  setValue,
  handleMemberChange,
  handleChange,
  setFilters,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [timeEntriesData, setTimeEntriesData] = useState([]);

  const filterDiscountedData = (dataset) => {
    return dataset?.filter((entry) =>
      Boolean(entry?.adjustment_hours_reason?.trim())
    );
  };

  useEffect(() => {
    const filteredEntries =
      fetchedTimeEntriesList &&
      fetchedTimeEntriesList?.filter((entry) => {
        const matchesStatus = filters.status
          ? entry.time_entries_status === filters.status.value
          : true;
        const matchesEntryAs = filters.entryAs
          ? entry.entries_as === filters.entryAs.value
          : true;
        const matchesReviewer = filters.reviewer?.value
          ? entry.reviewer_name === filters.reviewer.value
          : true;
        const notAdvancedBilling = entry.time_entries_type !== "billable_hours";
        const member = filters.member
          ? entry.time_entered_by === filters.member.value
          : true;
        const date = filters.date
        ? entry.working_date === dayjs(filters.date).format("YYYY-MM-DD")
        : true;
        const team = filters.team ? entry.team_id === filters.team.value : true;
        return (
          matchesStatus &&
          matchesEntryAs &&
          matchesReviewer &&
          notAdvancedBilling &&
          member &&
          date &&
          team
        );
      });

    let entriesData = filters.showDiscountedHoursEntries
      ? filterDiscountedData(filteredEntries)
      : filteredEntries;

    setTimeEntriesData(entriesData);
  }, [filters, fetchedTimeEntriesList]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Member",
      accessor: "member_name",
    },
    {
      Header: "Job",
      accessor: "task_name",
      Cell: ({ row }) => row.original.task_name || "---",
    },
    {
      Header: "Team",
      accessor: "team_name",
      Cell: ({ row }) => row.original.team_name ?? "Individual",
    },

    {
      Header: "Time Entries Type",
      accessor: "time_entries_type",
      Cell: ({ row }) => {
        const entryType = row.original.time_entries_type;

        switch (entryType) {
          case "advanced_billing":
            return "Advanced Billing";
          case "side_works":
            return "Side Work";
          default:
            return entryType;
        }
      },
    },

    {
      Header: "Reviewer",
      accessor: "reviewer_name",
      Cell: ({ row }) => row.original.reviewer_name ?? "---",
    },
    {
      Header: "Status",
      accessor: "time_entries_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start align-items-center">
          <Stack direction="horizontal">
            {row.original.time_entries_status === "approved" ? (
              <Badge bg="success">Approved</Badge>
            ) : (
              <Badge bg="danger">Pending</Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Working Date",
      accessor: "working_date",
      Cell: ({ row }) => {
        // console.log("rppppww", row.original.working_date);
        const date = formatDate(row.original.working_date);
        const time = formatTime(row.original.working_time);
        // console.log("date", date);
        // console.log("time", time);
        return (
          <div className="d-flex flex-column justify-content-start gap-1">
            <p className="m-0">{date}</p>
            <p className="m-0">{time}</p>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "work_description",
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <>
          {row.original.time_entries_status === "pending" && (
            <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
              <Tooltip
                id="edit-time-entry-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="edit-time-entry-tooltip"
                data-tooltip-content="Update Time Entry"
                data-tooltip-place="top"
              >
                <UpdateTimeEntryModal
                  setIsUpdated={setIsUpdated}
                  timeEntryData={row.original}
                />
              </div>
            </div>
          )}

          {row.original.time_entries_status === "approved" &&
          Boolean(row.original.adjustment_hours_reason?.trim()) ? (
            <>
              <Tooltip
                id="check-entry-details-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="check-entry-details-tooltip"
                data-tooltip-content="Check Entry Details"
                data-tooltip-place="top"
              >
                <CheckTimeEntryDetails timeEntry={row.original} />
              </div>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no.",
    "Member",
    "Job",
    "Team",
    "Reviewer",
    "Status",
    "Working Date",
    "Description",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => timeEntriesData, [timeEntriesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Member", key: "member_name" },
      { label: "Job", key: "task_name" },
      { label: "Team", key: "team_name" },
      { label: "Time Entries Type", key: "time_entries_type" },
      { label: "Reviewer", key: "reviewer_name" },
      { label: "Status", key: "time_entries_status" },
      { label: "Date", key: "working_date" },
      { label: "Time", key: "working_time" },
      { label: "Description", key: "work_description" },
    ],
    fileName: "Time Entries",
  };

  //Pagination Handling Functions
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    // localStorage.setItem("pageSize", newSize); // Save the page size
    // localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  // Function to transform data for CSV export
  const transformDataForCSV = (TimeEntries) => {
    return TimeEntries.map((timeEntry) => {
      return {
        member_name: timeEntry?.member_name,
        task_name: timeEntry?.task_name ? timeEntry?.task_name : "--",
        team_name: timeEntry?.team_name ? timeEntry?.team_name : "--",
        time_entries_type:
          timeEntry?.time_entries_type === "advanced_billing"
            ? "Advanced Billing"
            : timeEntry?.time_entries_type === "side_works"
            ? "Side works"
            : "",
        reviewer_name: timeEntry?.reviewer_name,
        time_entries_status: timeEntry?.time_entries_status,
        working_date: timeEntry?.working_date,
        working_time: timeEntry?.working_time,
        work_description: timeEntry?.work_description,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <div className="d-flex justify-content-between align-items-center mr-40 ml-30 mb-15 ">
        {/* Title on the Left */}
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">Time Entries</p>
        </div>

        {/* Right side: Search bar and Button */}
        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper w-25">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search..."
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>

          <AddTimeEntryModal setIsUpdated={setIsUpdated} />
        </div>
      </div>
      <TimeEntriesFilter
        filters={filters}
        onFilterChange={onFilterChange}
        forTeamLeader={false}
        timeEntries={fetchedTimeEntriesList}
        filterDiscountedData={filterDiscountedData}
        TeamLoadOptions={TeamLoadOptions}
        UsersLoadOptions={UsersLoadOptions}
   
        handleChange={handleChange}
        // handleMemberChange={handleMemberChange}
        setFilters={setFilters}
      />

      {/* Time Entries Table */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <TimeEntriesTable
            tableInstance={tableInstance}
            headers={headers}
            timeEntriesData={csvInvoiceData}
            columnHeaders={columnHeaders}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={fetchedTimeEntriesList}
          />
         
        </>
      )}
    </div>
  );
};

export default AllTimeEntriesContent;
