import React, { useContext, useState, useEffect, useCallback } from "react";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import AllTimeEntriesContent from "./AdminOrManager/TimeEntries/AllTimeEntriesContent";
import MyTeamTimeEntriesContent from "./MemberOrTeamLeaderJobs/TimeEntries/MyTeamTimeEntriesContent";
import { headerOptions } from "../../utils/utilities/utilityFunctions";
import { debounce } from "lodash";
import axios from "axios";
import dayjs from "dayjs";
const TimeEntries = () => {
  const {
    getAllTimeEntriesForPagination,
    getTeamDetailsByMemberId,
    getAllJobCategories,
    getAllMyTeamTimeEntries,
    initialState,
    initialStateLoading,
    getUserDetails,
    userDetails,
    mainURL,
    userId,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    status: null,
    entryAs: null,
    showDiscountedHoursEntries: false,
    member: null,
    date: null,
    myTeamID: null,
    assignedTo: null,
    team: null,
    timeEntriesType:null
  });

  const storedRole = localStorage.getItem("userRole");
  const storedTeamId = localStorage.getItem("teamID");

  useEffect(() => {
    getAllJobCategories();
    getUserDetails();
  }, []);

  useEffect(() => {
    // If search is cleared, reset the page to 1
    if (searchQuery === "") {
      setCurrentPage(1);
    }
  }, [searchQuery, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchQuery(trimmedInput);
    setCurrentPage(1);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery, roleType) => {
      if (roleType === "it_member" || roleType === "operation_member") {
        getAllTimeEntriesForPagination(
          currentPage,
          pageSize,
          searchQuery,
          filters
        );
      } else if (
        roleType === "member" ||
        roleType === "leader" ||
        roleType === "members,team_sub_leader" ||
        roleType === "team_leaders,members"
      ) {
        getAllMyTeamTimeEntries(
          storedTeamId || userDetails.team_id,
          currentPage,
          pageSize,
          searchQuery,
          filters
        );
      }
    }, 500),
    [
      currentPage,
      pageSize,
      filters,
      searchQuery,
      storedTeamId || userDetails?.team_id,
    ]
  );

  useEffect(() => {
    if (searchQuery !== "") {
      debouncedSearch(searchQuery, storedRole);
    } else {
      if (storedRole === "it_member" || storedRole === "operation_member") {
        getAllTimeEntriesForPagination(currentPage, pageSize, "", filters);
      } else if (
        storedRole === "member" ||
        storedRole === "leader" ||
        storedRole === "team_leaders,members" ||
        storedRole === "members,team_sub_leader" ||
        storedRole === "team_leaders,members"
      ) {
        getAllMyTeamTimeEntries(
          storedTeamId || userDetails.team_id,
          currentPage,
          pageSize,
          "",
          filters
        );
      }
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchQuery,
    currentPage,
    pageSize,
    filters,
    debouncedSearch,
    isUpdated,
    storedRole,
    storedTeamId,
    userDetails?.team_id,
  ]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // Reset pagination when filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);

  // const handleMemberChange = (selectedOption) => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     member: selectedOption,
  //   }));
  // };

  const handleChange = (date, dateString) => {
    setFilters((prev) => ({
      ...prev,
      // date: dayjs(date),
      date: date ? dayjs(date) : null,
    }));
  };

// Member
  async function UsersLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}list/all-member/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );
      const transformedData = response.data?.members_list?.users
        ?.filter(
          (item) =>
            item.member_role !== "it_member" &&
            item.member_role !== "operation_member" &&
            item?.current_status !== "inactive"
        )
        .map((item) => {
          try {
            const label = `${item.member_name || "N/A"}`;
            return {
              value: item.member_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.member_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      const hasMore =
        Array.isArray(response.data?.members_list?.users) &&
        response.data.members_list.users.length > 0;

      return {
        options: combinedOptions,
        hasMore: response.data?.members_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  // Team Member List
  async function TeamMemberLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}list/all-member/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
            team_id: storedTeamId || "",
          },
          headers: headerOptions(),
        }
      );

      const transformedData = response.data?.members_list?.users
        ?.filter(
          (item) =>
            item.member_role !== "it_member" &&
            item.member_role !== "operation_member" &&
            item?.current_status !== "inactive"
        ) // Filter out unwanted roles
        .map((item) => {
          try {
            // Create the label for the member
            const label = `${item.member_name || "N/A"}`;

            return {
              value: item.member_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.member_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response.data?.members_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
// Team Option
  async function TeamLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}get/team/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
    
      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  return (
    <>
      {storedRole === "it_member" || storedRole === "operation_member" ? (
        <AllTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={
            initialStateLoading?.getAllTimeEntriesForPaginationIsLoading
          }
          fetchedTimeEntriesList={initialState?.allTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filters={filters}
          onFilterChange={handleFilterChange}
          TeamLoadOptions={TeamLoadOptions}
          UsersLoadOptions={UsersLoadOptions}
    
          handleChange={handleChange}
          // handleMemberChange={handleMemberChange}
          setFilters={setFilters}
        />
      ) : (
        <MyTeamTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={initialStateLoading?.getAllMyTeamTimeEntriesIsLoading}
          fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filters={filters}
          onFilterChange={handleFilterChange}
          TeamMemberLoadOptions={TeamMemberLoadOptions}

          handleChange={handleChange}
          // handleMemberChange={handleMemberChange}
          setFilters={setFilters}
        />
      )}
    </>
  );
};

export default TimeEntries;
