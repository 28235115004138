
import React, { useContext, useEffect, useState } from "react";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { useLocation, useParams } from "react-router-dom";
import { SendMailAdvanceBilling } from "./SendMailAdvanceBilling";
import { UpdateAdvanceBillingJobInvoiceModal } from "./UpdateAdvanceBillingJobInvoiceModel";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";

const AdvancBillingJobInvoice = () => {
  const { getSingleAdvanceBillingInvoice, initialState, mainURL } = useContext(ContextAPI);
  const { id } = useParams();
  const {row, assignId, clientEmail, adHocMail } = useLocation().state || {};
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const [invoiceMeta, setInvoiceMeta] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    if (id) getSingleAdvanceBillingInvoice(id);
  }, [id]);

  useEffect(() => {
    const { singleAdvancebillingInvoice } = initialState;
    if (singleAdvancebillingInvoice) {
      setInvoiceMeta(singleAdvancebillingInvoice);
      setInvoiceUrl(singleAdvancebillingInvoice.invoice_url);
    }
  }, [initialState.singleAdvancebillingInvoice]);

  useEffect(() => {
    if (invoiceUrl && isLoading) {
      setIsLoading(false);
      setTimestamp(Date.now());
    }
  }, [invoiceUrl, isLoading]);

  const newInvoiceUrl = invoiceUrl ? `${mainURL}/${invoiceUrl}?t=${timestamp}` : null;
  
  const breadcrumbs = useLocation().state?.breadcrumbs || [
    { pageName: "Adv Billing Jobs", pageURL: "/advance-billing" },
    { pageName: "Adv Billing Invoice" },
  ];
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>
      
      <div className="d-flex justify-content-end gap-4" style={{ marginRight: "30px" }}>
        <SendMailAdvanceBilling
          invoiceUrl={newInvoiceUrl}
          assignId={assignId}
          row={row}
          adHocMail={adHocMail}
          clientEmail={clientEmail}
        />
        <UpdateAdvanceBillingJobInvoiceModal
          id={invoiceMeta?.assign_id}
          status={invoiceMeta?.payment_status}
          teamId={invoiceMeta?.team_id}
          jobName={invoiceMeta?.job_name}
          jobDescription={invoiceMeta?.job_description}
          editJobDescription={invoiceMeta?.edit_job_description}
          editJobName={invoiceMeta?.edit_job_name}
          amount={invoiceMeta?.amount}
          vat={invoiceMeta?.vat_percent}
          partialPaid={invoiceMeta?.partial_paid}
          onUpdate={() => { setIsLoading(true); getSingleAdvanceBillingInvoice(id); }}
        />
      </div>

      <div className="invoice-job" style={{ padding: "20px", textAlign: "center" }}>
        {isLoading ? (
          <SpinningLoader />
        ) : (
          <iframe 
            key={timestamp} 
            src={newInvoiceUrl} 
            title="Invoice" 
            style={{
              width: "100%", height: "700px", border: "none", borderRadius: "8px", 
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)"
            }} 
            onLoad={() => setIsLoading(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AdvancBillingJobInvoice;

