import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import { debounce } from "lodash";
import { projectsIcon } from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import SelectElement from "../../../templates/SelectElement";
import Select from "react-select";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import {
  headerOptions,
  handleAPIError,
} from "../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import "react-datepicker/dist/react-datepicker.css";
import { AsyncPaginate } from "react-select-async-paginate";
import { DatePicker, Space, message } from "antd";
import moment from "moment";
import dayjs from "dayjs";
const userID = localStorage.getItem("userId");
const MyVerticallyCenteredModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nextPageScroll, setNextPageScroll] = useState(true);
  const [page, setPage] = useState(1);
  const [clients, setClients] = useState([]);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const {
    mainURL,
    logout,
    initialState,
    getAllClients,
    getUserDetails,
    getAllAdvanceBillingJobs,
    getAllTeams,
    userDetails,
    getAllJobs,
    getAllJobCategories,
    getAllBillingServices,
    getAllMembers,
  } = useContext(ContextAPI);

  const [advanceJobDetails, setAdvanceJobDetails] = useState({
    jobName: "",
    jobTypeId: "",
    clientId: "",
    description: "",
    startDate: "",
    endDate: "",
    allocatedHours: "",
    amount: "",
    jobArrivalDate: "",
  });

  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [options, setOptions] = useState({
    jobCategoryOptions: [],
    clientOptions: [],
    teamOptions: [],
    billingServicesOptions: [],
    membersOptions: [],
  });

  useEffect(() => {
    getUserDetails();
    getAllClients();
    getAllTeams();
    getUserDetails();
    // getAllJobs();
    getAllBillingServices();
    getAllMembers();
    getAllJobCategories();
    // getAllAdvanceBillingJobs()
  }, []);

  const handleClear = () => {
    setAdvanceJobDetails(() => ({
      jobName: "",
      jobTypeId: "",
      clientId: "",
      teamId: "",
      description: "",
      startDate: "",
      endDate: "",
      allocatedHours: "",
      jobAssignedTo: "",
      amount: "",
      jobArrivalDate: "",
    }));
  };
  useEffect(() => {
    setAdvanceJobDetails((prev) => ({
      ...prev,
      jobArrivalDate: {
        date: dayjs(),
        dateString: dayjs().format("DD-MM-YYYY"),
      },
    }));
  }, []);
  //  setting Dropdown Options for Jobs, Members List & Teams List
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      jobCategoryOptions: initialState?.jobCategories?.map((category) => ({
        label: category?.job_category_name,
        value: category?.job_category_id,
      })),

      clientOptions:
        clients &&
        clients?.map((client) => {
          const { client_name, additional_data, client_id } = client;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          const billingRates = additional_data?.billing_rates || [];

          return {
            label,
            value: client_id,
            billingRates,
          };
        }),

      membersOptions: initialState.membersList
        .filter(
          (member) =>
            (member.member_role === "members" ||
              member.member_role === "members,team_sub_leader" ||
              member.member_role === "team_leaders,members") &&
            member.current_status === "active"
        )
        .map(({ member_id, member_name }) => ({
          label: member_name,
          value: member_id,
        })),

      teamOptions: initialState?.teamsList
        ?.filter((team) => team.status === "active")
        .map(({ id, team_name }) => ({
          label: team_name,
          value: id,
        })),

      billingServicesOptions: initialState.billingServicesList
        .filter(({ service_status }) => service_status === "active")
        .map(({ services_name, services_id }) => ({
          label: services_name,
          value: services_id,
        })),
    }));
  }, [
    initialState.jobCategories,
    // initialState.clientsList,
    clients,
    initialState.teamsList,
    initialState.billingServicesList,
    initialState.membersList,
  ]);

  // for adding a new job api
  const addNewAdvanceBillingJob = async () => {
    setIsLoading(() => true);

    try {
      const formattedArrivalDate = moment(
        advanceJobDetails.jobArrivalDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        job_name: advanceJobDetails?.jobName,
        job_type: advanceJobDetails?.jobTypeId?.value,
        client_id: advanceJobDetails?.clientId?.value,
        team_id: advanceJobDetails?.teamId?.value,
        allocated_hours: advanceJobDetails?.allocatedHours,
        job_description: advanceJobDetails?.description,
        job_arrival_date: formattedArrivalDate,
        // recipient_id: advanceJobDetails?.jobAssignedTo.value,
        amount: advanceJobDetails?.amount,
      };
      const url = `${mainURL}add/job/advanced-billing`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 201) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
        props.onHide();
        handleClear();
        setValueForClient(null);
        setValueForTeam(null);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const onChangeDate = (element, date, dateString) => {
    setAdvanceJobDetails((prev) => ({
      ...prev,
      [element]: { date, dateString },
    }));
  };

  const handleChange = (date, dateString, element) => {
    onChangeDate(element, date, dateString);
  };

  const handleAddNewAdvanceJob = (e) => {
    e.preventDefault();

    const {
      teamId,
      jobName,
      jobTypeId,
      clientId,
      description,
      allocatedHours,
      amount,
      jobAssignedTo,

      //   startDate,
    } = advanceJobDetails;

    const bool =
      teamId !== "" &&
      clientId !== "" &&
      jobTypeId !== "" &&
      jobName !== "" &&
      amount !== "" &&
      // jobAssignedTo !== "" &&
      allocatedHours !== "";

    if (jobName && jobTypeId && clientId && bool) {
      addNewAdvanceBillingJob();
    } else {
      const conditions = {
        [!jobName]: "Please enter Job Name!",
        [!jobTypeId]: "Please select Job Type!",
        [!clientId]: "Please select Client!",
        [!teamId]: "Please select Team!",
        [!description]: "Please add Description!",
        [!allocatedHours]: "Please enter Allocated Hours",
        // [!jobAssignedTo]: "Please select Member",
        [!amount]: "Please enter amount!",
      };

      const errorMessage = conditions[true];

      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };
  const handleDropDown = (dropDown, option) => {
    if (dropDown === "jobTypeId") {
      setAdvanceJobDetails((prev) => ({
        ...prev,
        jobTypeId: option,
      }));
    }
    if (dropDown === "teamId") {
      setAdvanceJobDetails((prev) => ({
        ...prev,
        teamId: option,
      }));
    }
    if (dropDown === "clientId") {
      const selectedClient = initialState?.clientsList.find(
        (client) => client?.client_id === option?.value
      );
      if (selectedClient) {
        try {
          const additionalData = selectedClient.additional_data;
          const serviceLookup = initialState.billingServicesList
            .filter(({ service_status }) => service_status === "active")
            .reduce((acc, { services_name, services_id, service_rate }) => {
              acc[services_id] = { name: services_name, rate: service_rate };
              return acc;
            }, {});

          const billingServices = (additionalData.billing_services || []).map(
            ({ service_id }) => ({
              label: serviceLookup[service_id]?.name || `Service ${service_id}`,
              value: service_id,
            })
          );

          setOptions((prev) => ({
            ...prev,
            billingServicesOptions: billingServices,
          }));

          setAdvanceJobDetails((prev) => ({
            ...prev,
            clientId: option,
            billingServiceSelected: null,
            billingRate: "",
          }));

          if (advanceJobDetails.billingServiceSelected) {
            const selectedServiceRate =
              serviceLookup[advanceJobDetails.billingServiceSelected.value]
                ?.rate || "";
            setAdvanceJobDetails((prev) => ({
              ...prev,
              billingRate: selectedServiceRate,
            }));
          }
        } catch (error) {
          console.error(
            `Error parsing additional_data for job_id ${selectedClient.client_id}:`,
            error
          );
        }
      }
      setOptions((prev) => ({
        ...prev,
        clientOptions:
          clients &&
          clients?.map((client) => {
            const { client_name, additional_data, client_id } = client;
            const companyName = additional_data?.company_name || "";
            const bpoNo = additional_data?.bpo_no?.toString() || "";
            const label = `${client_name} (${companyName}${
              bpoNo ? ` - ${bpoNo}` : ""
            })`;
            const billingRates = additional_data?.billing_rates || [];

            return {
              label,
              value: client_id,
              billingRates,
            };
          }),
      }));
    } else if (dropDown === "billingServiceSelected") {
      const selectedServiceId = option.value;
      const selectedClient = initialState.clientsList.find(
        (client) => client?.client_id === advanceJobDetails.clientId?.value
      );

      if (selectedClient) {
        try {
          const additionalData = selectedClient.additional_data;
          const selectedService = additionalData.billing_services.find(
            ({ service_id }) => service_id === selectedServiceId
          );

          setAdvanceJobDetails((prev) => ({
            ...prev,
            billingServiceSelected: option,
            billingRate: selectedService?.service_rate || "",
          }));
        } catch (error) {}
      }
    }
  };

  // async
  const [valueForClient, setValueForClient] = useState(null);
  const [valueForTeam, setValueForTeam] = useState(null);

  async function LoadClientsOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}/list/all-client/${userID || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // console.log("responseresponse", response);

      // Transform the data into the required format
      const transformedData = response.data?.client_list?.client_data.map(
        (item) => {
          try {
            // Parse the additional client data
            const { client_name, additional_data, client_id } = item;
            const companyName = additional_data?.company_name || "";
            const bpoNo = additional_data?.bpo_no?.toString() || "";
            const label = `${client_name} (${companyName}${
              bpoNo ? ` - ${bpoNo}` : ""
            })`;

            // Create the label for the job

            return {
              value: client_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.client_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      const hasMore =
        Array.isArray(response?.data?.team_list?.team_data) &&
        response?.data?.team_list?.team_data.length > 0;

      return {
        options: combinedOptions,
        hasMore: response.data?.client_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function LoadTeamsOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}get/team/${userID || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      const hasMore =
        Array.isArray(response?.data?.team_list?.team_data) &&
        response?.data?.team_list?.team_data.length > 0;

      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Add New Advance Billing Job</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleAddNewAdvanceJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label className="jobType" htmlFor="jobTypeId">
              Select Job Category:
            </label>
            <Select
              name="jobTypeId"
              closeMenuOnSelect={true}
              options={options.jobCategoryOptions}
              onChange={(option) => handleDropDown("jobTypeId", option)}
              value={advanceJobDetails.jobTypeId}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="clientId">Select Client:</label>
            {/* <Select
              name="clientId"
              closeMenuOnSelect={true}
              options={options.clientOptions}
              onChange={(option) => handleDropDown("clientId", option)}
              value={advanceJobDetails.clientId}
              onMenuScrollToBottom={nextPageScroll && handleScroll}
              onInputChange={(inputValue) => debouncedHandleSearch(inputValue)}
              isLoading={isDropdownLoading}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            /> */}
            <AsyncPaginate
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={valueForClient}
              loadOptions={LoadClientsOptions}
              onChange={(selectedOption) => {
                setValueForClient(selectedOption);
                handleDropDown("clientId", selectedOption);
              }}
              additional={{
                page: 1,
              }}
              placeholder="Select Client"
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Client found for "${inputValue}"`
                  : "No Client found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Client", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          <div className="form-group mt-4 w-100">
            <label htmlFor="teamId">Select Team:</label>
            {/* <Select
              name="teamId"
              closeMenuOnSelect={true}
              options={options.teamOptions}
              onChange={(option) => handleDropDown("teamId", option)}
              value={advanceJobDetails.teamId}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            /> */}
            <AsyncPaginate
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={valueForTeam}
              loadOptions={LoadTeamsOptions}
              onChange={(selectedOption) => {
                setValueForTeam(selectedOption);
                handleDropDown("teamId", selectedOption);
              }}
              additional={{
                page: 1,
              }}
              placeholder="Select Team"
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Team found for "${inputValue}"`
                  : "No Team found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Teams", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          {/* <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Select Member:</label>
            <Select
              name="assignType"
              closeMenuOnSelect={true}
              options={options.membersOptions}
              onChange={(item) => handleAssignTo(item)}
              value={advanceJobDetails.jobAssignedTo}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div> */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobName">Job Name:</label>
            <input
              id="jobName"
              name="jobName"
              placeholder="Enter job name"
              type="text"
              required
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  jobName: e.target.value,
                }))
              }
              value={advanceJobDetails.jobName}
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Job Description:</label>
            <textarea
              id="jobDescription"
              name="jobDescription"
              className="w-100"
              rows={3}
              placeholder="Enter job description"
              value={advanceJobDetails.description}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobArrivalDate ">Job Arrive Date:</label>
            <DatePicker
              defaultValue={dayjs()}
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "jobArrivalDate")
              }
              format="DD-MM-YYYY"
              value={advanceJobDetails.jobArrivalDate.date}
              name="jobArrivalDate"
              placeholder="Select / Enter date "
              disabledDate={(current) => new Date() < new Date(current)}
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Allocated Hours:</label>
            <input
              id="allocatedHours"
              name="allocatedHours"
              type="number"
              className="w-100"
              step="0.01"
              placeholder="Enter allocated hours"
              value={advanceJobDetails.allocatedHours}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  allocatedHours: e.target.value,
                }))
              }
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="amount">Amount:</label>
            <input
              id="amount"
              name="amount"
              className="w-100"
              type="number"
              placeholder="Enter amount"
              value={advanceJobDetails.amount}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
            />
          </div>

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Add Advance Job"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export const AdvanceBillingJobModal = ({ setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <button className="custom-btn d-flex justify-content-center align-items-center gap-2">
          Add Advance Job <span className="fw-light fs-4">+</span>
        </button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
