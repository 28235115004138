// import React, { useContext, useMemo, useEffect } from "react";
// import { Tooltip } from "react-tooltip";

// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";
// import { json, useNavigate } from "react-router-dom";
// import {
//   advanceBilling,
//   advanceBillingg,
//   InvoiceIcon,
//   fileIcon,
// } from "../../../utils/ImportingImages/ImportingImages";
// import PageHeader from "../../../templates/PageHeader";
// import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../templates/Breadcrumbs";
// import AdvanceBillingTable from "./AdvanceBillingTable";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
// import { AdvanceBillingJobModal } from "./AdvanceBillingJobModel";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";
// import { format, parse, parseISO, isValid } from "date-fns";
// import { DeleteAdvanceBillingJobModal } from "./DeleteAdvanceBillingJobModel";
// import { UpdateAdvanceBillingJobModal } from "./UpdateAdvanceBillingJobModel";
// import {
//   formatTime,
//   formatDate,
// } from "../../../utils/utilities/utilityFunctions";
// import { UpdateAdvanceBillingJobInvoiceModal } from "./UpdateAdvanceBillingJobInvoiceModel";
// const AdvancBillingContent = ({
//   advancBillingJobsData,
//   setIsUpdated,
//   isLoading,
//   currentPage,
//   setCurrentPage,
//   pageSize,
//   totalPages,
//   setPageSize,
// }) => {
//   const navigate = useNavigate();
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const { getAllAdvanceBillingJobs, mainURL } = useContext(ContextAPI);
//   // useEffect(() => {
//   //   getAllAdvanceBillingJobs();
//   // }, []);
//   // const detectAndParseDate = (dateStr) => {
//   //   // Check if the date string is in 'yyyy-MM-dd' format
//   //   if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
//   //     return parseISO(dateStr);
//   //   }
//   //   // Check if the date string is in 'dd-MM-yyyy' format
//   //   else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
//   //     return parse(dateStr, "dd-MM-yyyy", new Date());
//   //   }
//   //   // Invalid date format
//   //   return null;
//   // };
//   // const formatDate = (dateStr) => {
//   //   if (!dateStr) return "N.A";

//   //   const dateObj = detectAndParseDate(dateStr);

//   //   if (!dateObj || !isValid(dateObj)) return "N.A";

//   //   const day = format(dateObj, "d"); // Day without leading zero
//   //   const month = format(dateObj, "MMM"); // Month abbreviation
//   //   const year = format(dateObj, "yyyy"); // Year

//   //   const suffix = (day) => {
//   //     if (day >= 11 && day <= 13) return "th";
//   //     switch (day % 10) {
//   //       case 1:
//   //         return "st";
//   //       case 2:
//   //         return "nd";
//   //       case 3:
//   //         return "rd";
//   //       default:
//   //         return "th";
//   //     }
//   //   };

//   //   return `${day}${suffix(day)} ${month} ${year}`;
//   // };

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/dashboard",
//     },
//     {
//       pageName: "Adv Billing Jobs",
//       // pageURL: "/advance-billing-jobs",
//     },
//   ];

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "sr no.",
//       Cell: ({ row }) => row.index + 1,
//     },
//     // {
//     //   Header: "BPO No",
//     //   accessor: "bpo_no",
//     // },
//     {
//       Header: "Job Name",
//       accessor: "job_name",
//     },
//     {
//       Header: "Client Name",
//       accessor: "client_name",
//     },

//     {
//       Header: "Assigned on",
//       accessor: "assigned_on",
//       Cell: ({ row }) => {
//         const startDate = formatDate(row.original.assigned_on);
//         return (
//           <div className="gap-1">
//             <p className="m-0">{startDate}</p>
//           </div>
//         );
//       },
//     },

//     {
//       Header: "Allocated Hours",
//       accessor: "allocated_hours",
//       Cell: ({ row }) =>
//         row.original.allocated_hours?.split(":")[0] || (
//           <span style={{ color: "grey" }}>N/A</span>
//         ),
//     },

//     {
//       Header: "Approved Time",
//       accessor: "approved_time",
//       Cell: ({ row }) => {
//         return (
//           <div className="d-flex justify-content-start">
//             <Stack direction="horizontal">
//               {row.original.approved_time === "yes" ? (
//                 <Badge bg="success">Yes</Badge>
//               ) : (
//                 <Badge bg="danger">No</Badge>
//               )}
//             </Stack>
//           </div>
//         );
//       },
//     },

//     {
//       Header: "Status",
//       accessor: "job_status",
//       Cell: ({ row }) => (
//         <div className="d-flex justify-content-start">
//           <Stack direction="horizontal">
//             {row.original.job_status === "Completed" ? (
//               <Badge bg="success">Completed</Badge>
//             ) : row.original.job_status === "On Hold" ? (
//               <Badge bg="danger">On Hold</Badge>
//             ) : (
//               <Badge bg="warning" text="dark">
//                 In Progress
//               </Badge>
//             )}
//           </Stack>
//         </div>
//       ),
//     },

//     {
//       Header: "Edit",
//       Cell: ({ row }) => (
//         <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
//           <>
//             <Tooltip
//               id="preview-invoice-tooltip"
//               style={{
//                 background: "#000",
//                 color: "#fff",
//               }}
//               opacity={0.9}
//             />
//             {row.original.invoice_url ? (
//               <div
//                 data-tooltip-id="preview-invoice-tooltip"
//                 data-tooltip-content="Preview Invoice"
//                 data-tooltip-place="top"
//               >
//                 <div
//                   onClick={() => {
//                     const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
//                     const rowData = row.original;
//                     const assignId = row.original.assign_id;
//                     const clientEmail = row.original.client_email;
//                     navigate("/advance-billing-invoice", {
//                       state: {
//                         invoice_id: row.original.id,
//                         invoice_url: invoiceUrl,
//                         row: rowData,
//                         assignId: assignId || null,
//                         clientEmail: clientEmail,
//                       },
//                     });
//                   }}
//                 >
//                   <InvoiceIcon />
//                 </div>
//               </div>
//             ) : null}

//             {/* <Tooltip
//               id="send-invoice-tooltip"
//               style={{
//                 background: "#000",
//                 color: "#fff",
//               }}
//               opacity={0.9}
//             /> */}

//             <Tooltip
//               id="edit-advance-job-tooltip"
//               style={{
//                 background: "#000",
//                 color: "#fff",
//               }}
//               opacity={0.9}
//             />
//             <div
//               data-tooltip-id="edit-advance-job-tooltip"
//               data-tooltip-content="Edit Status"
//               data-tooltip-place="top"
//             >
//               <UpdateAdvanceBillingJobModal
//                 id={row.original.assign_id}
//                 status={row.original.job_status}
//                 teamId={row.original.team_id}
//                 jobName={row.original.job_name}
//                 jobDescription={row.original.job_description}
//                 setIsUpdated={setIsUpdated}
//                 // id={row.original.assign_id}
//                 // status={row.original.job_status}
//                 // teamId={row.original.team_id}
//                 // jobName={row.original.job_name}
//                 // jobDescription={row.original.job_description}
//                 // allocatedHours={row.original.allocated_hours}
//                 // amount={row.original.amount}
//                 // setIsUpdated={setIsUpdated}
//               />
//             </div>

//             <Tooltip
//               id="edit-advance-job-tooltip"
//               style={{
//                 background: "#000",
//                 color: "#fff",
//               }}
//               opacity={0.9}
//             />
//             <div
//               data-tooltip-id="edit-advance-job-tooltip"
//               data-tooltip-content="Edit Invoice"
//               data-tooltip-place="top"
//             >
//               <UpdateAdvanceBillingJobInvoiceModal
//                 id={row.original.assign_id}
//                 status={row.original.job_status}
//                 teamId={row.original.team_id}
//                 jobName={row.original.job_name}
//                 jobDescription={row.original.job_description}
//                 setIsUpdated={setIsUpdated}
//               />
//             </div>

//             <Tooltip
//               id="delete-advance-job-tooltip"
//               style={{
//                 background: "#000",
//                 color: "#fff",
//               }}
//               opacity={0.9}
//             />
//             <div
//               data-tooltip-id="delete-advance-job-tooltip"
//               data-tooltip-content="Delete Advance Job"
//               data-tooltip-place="top"
//             >
//               <DeleteAdvanceBillingJobModal
//                 id={row.original.assign_id}
//                 setIsUpdated={setIsUpdated}
//               />
//             </div>
//           </>
//         </div>
//       ),
//     },
//   ];

//   const columnHeaders = [
//     "Sr no.",
//     "Assigned On",
//     "Job Name",
//     "Client Name",
//     "Status",
//     "Edit",
//   ];

//   const columns = useMemo(() => tableColumns, []);
//   // const data = useMemo(() => advancBillingJobsData, [advancBillingJobsData]);
//   const data = useMemo(() => {
//     return advancBillingJobsData?.sort((a, b) => {
//       if (a.job_status === "In Progress" && b.job_status !== "In Progress") {
//         return -1;
//       }
//       if (a.job_status !== "In Progress" && b.job_status === "In Progress") {
//         return 1;
//       }
//       return 0;
//     });
//   }, [advancBillingJobsData]);

//   // constructing headers for CSV Link
//   const headers = {
//     headings: [
//       { label: "Job Name", key: "job_name" },
//       { label: "Client Name", key: "client_name" },
//       { label: "Assigned On", key: "assigned_on" },
//       { label: "Status", key: "job_status" },
//       { label: "Allocated Hours", key: "allocated_hours" },
//       { label: "Approved Hours", key: "approved_time" },
//     ],
//     fileName: "Advance Billing Jobs",
//   };

//   // Function to transform data for CSV export
//   const transformDataForCSV = (invoices) => {
//     return invoices.map((invoice) => {
//       return {
//         job_name: invoice.job_name,
//         client_name: invoice.client_name,
//         assigned_on: invoice.assigned_on,
//         job_status: invoice.job_status,
//         allocated_hours: invoice.allocated_hours,
//         approved_time: invoice?.approved_time === "yes" ? "Yes" : "No",
//       };
//     });
//   };
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     localStorage.setItem("currentPage", page); // Save the page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch
//   };

//   const handlePageSizeChange = (event) => {
//     const newSize = Number(event.target.value);
//     setPageSize(newSize); // Update the component's state
//     tableInstance.setPageSize(newSize); // Update the React Table's internal state
//     setCurrentPage(1); // Reset to the first page
//     localStorage.setItem("pageSize", newSize); // Save the page size
//     localStorage.setItem("currentPage", 1); // Save the new page number
//     setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
//   };
//   // Use the function to get CSV data
//   const csvAdvanceData = transformDataForCSV(advancBillingJobsData);
//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageSize: pageSize },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   useEffect(() => {
//     tableInstance.setPageSize(pageSize);
//   }, [pageSize, tableInstance]);
//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       <div className="relative-wrapper zIndex-2">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={advanceBillingg}
//           headerTitle={"Adv Billing Jobs"}
//         >
//           <AdvanceBillingJobModal setIsUpdated={setIsUpdated} />
//         </PageHeader>
//       </div>

//       {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : advancBillingJobsData.length > 0 ? (
//         <>
//           <AdvanceBillingTable
//             tableInstance={tableInstance}
//             headers={headers}
//             advancBillingJobsData={csvAdvanceData}
//           />

//           <div
//             className="d-flex justfy-content-center align-items-center gap-3 smallText"
//             style={{
//               width: "45%",
//               justifyContent: "space-between",
//               position: "absolute",
//               marginLeft: "90px",
//               bottom: "48px",
//             }}
//           >
//             <div className="d-flex gap-1 align-items-center">
//               <button
//                 className="cursor-pointer"
//                 style={{ border: "none", background: "transparent" }}
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
//                   />
//                 </svg>
//               </button>
//               <input
//                 className="gotoPage-input-field"
//                 type="number"
//                 defaultValue={currentPage}
//                 readOnly
//               />
//               <span className="px-2">/</span>

//               <span> {totalPages}</span>
//               <button
//                 style={{ border: "none", background: "transparent" }}
//                 className="cursor-pointer"
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={
//                   advancBillingJobsData?.length < pageSize || totalPages === 1
//                 }
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
//                   />
//                 </svg>
//               </button>
//             </div>
//             <div className="d-flex justify-content-center align-items-center">
//               <span className="px-1 smallText">Rows /page</span>
//               <select value={pageSize} onChange={handlePageSizeChange}>
//                 <option value={10}>10</option>
//                 <option value={25}>25</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//               </select>
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="mt-4 mr-40 ml-30 mb-15">
//           <h5>No data found!</h5>
//         </div>
//       )}
//     </div>
//     // </div>
//   );
// };

// export default AdvancBillingContent;

import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { json, useNavigate } from "react-router-dom";
import {
  advanceBilling,
  advanceBillingg,
  InvoiceIcon,
  fileIcon,
  reportsIcon,
  searchIcon,
  clientsIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import AdvanceBillingTable from "./AdvanceBillingTable";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { AdvanceBillingJobModal } from "./AdvanceBillingJobModel";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { format, parse, parseISO, isValid } from "date-fns";
import { DeleteAdvanceBillingJobModal } from "./DeleteAdvanceBillingJobModel";
import { UpdateAdvanceBillingJobModal } from "./UpdateAdvanceBillingJobModel";
import {
  formatTime,
  formatDate,
} from "../../../utils/utilities/utilityFunctions";
import { UpdateAdvanceBillingJobInvoiceModal } from "./UpdateAdvanceBillingJobInvoiceModel";
import Select from "react-select";
import PaginationComponent from "../../../Components/Pagination/PaginationComponent";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { AsyncPaginate } from "react-select-async-paginate";
import "../../../App.css";
const AdvancBillingContent = ({
  advancBillingJobsData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
  filters,
  onFilterChange,
  LoadClientsOptions,
  valueForClient,
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getAllAdvanceBillingJobs, mainURL } = useContext(ContextAPI);
  const [totalRecords, setTotalRecords] = useState(201);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Adv Billing Jobs",
      // pageURL: "/advance-billing-jobs",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
      Cell: ({ row }) => row.original.sr_no,
    },
    // {
    //   Header: "BPO No",
    //   accessor: "bpo_no",
    // },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Assigned to",
      accessor: "team_name",
    },

    {
      Header: "Job Arrival Date",
      accessor: "job_arrival_date",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.job_arrival_date);
        return (
          <div className="gap-1">
            <p className="m-0">{startDate}</p>
          </div>
        );
      },
    },

    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
      Cell: ({ row }) =>
        row.original.allocated_hours?.split(":")[0] || (
          <span style={{ color: "grey" }}>N/A</span>
        ),
    },

    {
      Header: "Approved Time",
      accessor: "approved_time",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-start">
            <Stack direction="horizontal">
              {row.original.approved_time === "yes" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    },

    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <>
            <Tooltip
              id="preview-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            {row.original.invoice_url ? (
              <div
                data-tooltip-id="preview-invoice-tooltip"
                data-tooltip-content="Preview Invoice"
                data-tooltip-place="top"
              >
                <div
                  onClick={() => {
                    const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                    const rowData = row.original;
                    const assignId = row.original.assign_id;
                    const clientEmail = row.original.client_email;
                    // navigate("/advance-billing-invoice", {
                    navigate(
                      `/advance-billing-invoice/${row.original.assign_id}`,
                      {
                        state: {
                          invoice_id: row.original.id,
                          invoice_url: invoiceUrl,
                          row: rowData,
                          assignId: assignId || null,
                          clientEmail: clientEmail,
                        },
                      }
                    );
                  }}
                >
                  <InvoiceIcon />
                </div>
              </div>
            ) : null}

            {/* <Tooltip
              id="send-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            /> */}

            <Tooltip
              id="edit-advance-job-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="edit-advance-job-tooltip"
              data-tooltip-content="Edit Status"
              data-tooltip-place="top"
            >
              <UpdateAdvanceBillingJobModal
                id={row.original.assign_id}
                status={row.original.job_status}
                teamId={row.original.team_id}
                jobName={row.original.job_name}
                jobDescription={row.original.job_description}
                setIsUpdated={setIsUpdated}
              />
            </div>

            {/* <Tooltip
              id="edit-advance-job-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="edit-advance-job-tooltip"
              data-tooltip-content="Edit Invoice"
              data-tooltip-place="top"
            >
              <UpdateAdvanceBillingJobInvoiceModal
                id={row.original.assign_id}
                status={row.original.job_status}
                teamId={row.original.team_id}
                jobName={row.original.job_name}
                jobDescription={row.original.job_description}
                setIsUpdated={setIsUpdated}
              />
            </div> */}

            <Tooltip
              id="delete-advance-job-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="delete-advance-job-tooltip"
              data-tooltip-content="Delete Advance Job"
              data-tooltip-place="top"
            >
              <DeleteAdvanceBillingJobModal
                id={row.original.assign_id}
                setIsUpdated={setIsUpdated}
              />
            </div>
          </>
        </div>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no.",
    "Job Name",
    "Client Name",
    "Job Arrival Date",
    "Allocated Hours",
    "Approved Time",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => advancBillingJobsData, [advancBillingJobsData]);
  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];
  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Assigned to", key: "team_name" },
      { label: "Period Start Date", key: "assigned_on" },
      { label: "Allocated Hours", key: "allocated_hours" },
      { label: "Approved Hours", key: "approved_time" },
      { label: "Job Arrival Date", key: "job_arrival_date" },
      { label: "Status", key: "job_status" },
    ],
    fileName: "Advance Billing Jobs",
  };

  // Function to transform data for CSV export
  const transformDataForCSV = (invoices) => {
    return invoices.map((invoice) => {
      return {
        job_name: invoice.job_name,
        client_name: invoice.client_name,
        assigned_on: invoice.assigned_on,
        team_name: invoice.team_name,
        job_status: invoice.job_status,
        allocated_hours: invoice.allocated_hours,
        approved_time: invoice?.approved_time === "yes" ? "Yes" : "No",
      };
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };
  // Use the function to get CSV data
  const csvAdvanceData = transformDataForCSV(advancBillingJobsData);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   const maxPageNumbers = 5; // For example, showing 5 page numbers at once

  //   // We need to show a range of pages: current page +/- 2 (for 5 pages)
  //   const startPage = Math.max(1, currentPage - 2);
  //   const endPage = Math.min(totalPages, currentPage + 2);

  //   // Add the first page (1) and possibly ellipses if there's a gap
  //   if (startPage > 1) {
  //     pageNumbers.push(
  //       <button
  //         key={1}
  //         onClick={() => handlePageChange(1)}
  //         disabled={1 === currentPage}
  //         className={`page-button ${1 === currentPage ? "active" : ""}`}
  //       >
  //         {1}
  //       </button>
  //     );
  //     if (startPage > 2) {
  //       pageNumbers.push(<span key="ellipsis-start">...</span>);
  //     }
  //   }

  //   // Add the pages in the range
  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(
  //       <button
  //         key={i}
  //         onClick={() => handlePageChange(i)}
  //         disabled={i === currentPage}
  //         className={`page-button ${i === currentPage ? "active" : ""}`}
  //       >
  //         {i}
  //       </button>
  //     );
  //   }

  //   // Add ellipses if necessary and the last page number
  //   if (endPage < totalPages) {
  //     if (endPage < totalPages - 1) {
  //       pageNumbers.push(<span key="ellipsis-end">...</span>);
  //     }
  //     pageNumbers.push(
  //       <button
  //         key={totalPages}
  //         onClick={() => handlePageChange(totalPages)}
  //         disabled={totalPages === currentPage}
  //         className={`page-button ${
  //           totalPages === currentPage ? "active" : ""
  //         }`}
  //       >
  //         {totalPages}
  //       </button>
  //     );
  //   }

  //   return pageNumbers;
  // };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // For example, showing 5 page numbers at once

    // We need to show a range of pages: current page +/- 2 (for 5 pages)
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Add the first page (1) and possibly ellipses if there's a gap
    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          disabled={1 === currentPage}
          className={`page-button ${1 === currentPage ? "active" : ""}`}
        >
          {1}
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <span key="ellipsis-start" className="ellipsis">
            ...
          </span>
        );
      }
    }

    // Add the pages in the range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === currentPage}
          className={`page-button ${i === currentPage ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add ellipses if necessary and the last page number
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="ellipsis-end" className="ellipsis">
            ...
          </span>
        );
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          disabled={totalPages === currentPage}
          className={`page-button ${
            totalPages === currentPage ? "active" : ""
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      {/* <div className="relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={advanceBillingg}
          headerTitle={"Adv Billing Jobs"}
        >
          <AdvanceBillingJobModal setIsUpdated={setIsUpdated} />
        </PageHeader>
      </div> */}
      <div className="d-flex justify-content-between align-items-center  mr-40 ml-30 mb-15 ">
        {/* Title on the Left */}
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">Adv Billing Jobs</p>
        </div>

        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper w-25">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              type="text"
              className="input-field"
              placeholder="Search"
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) =>
                onSearchChange(e.target.value.replace(/^\s+/, ""))
              }
            />
          </div>

          <AdvanceBillingJobModal setIsUpdated={setIsUpdated} />
        </div>
      </div>

      <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) => onFilterChange("status", option)}
            value={filters.status}
            placeholder="Select status"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
        {/* <div className="relative-wrapper w-25">
          <img className="search-icon" src={clientsIcon} alt="search-icon" />
          <AsyncPaginate
            isClearable={true}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
            value={filters.client}
            options={valueForClient}
            loadOptions={LoadClientsOptions}
            onChange={(option) => onFilterChange("client", option)}
            additional={{
              page: 1,
            }}
            placeholder={`Select Client`}
            debounceTimeout={300}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? `No Client found for "${inputValue}"`
                : "No Client found"
            }
            onError={(error) => {
              ReactHotToast("Error loading clients", "error");
              console.error("Async Paginate Error:", error);
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e0e0e0"
                  : "white",
                cursor: "pointer",
                color: state.isSelected ? "white" : "black",
                ":hover": {
                  backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div> */}
      </div>

      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <AdvanceBillingTable
            tableInstance={tableInstance}
            headers={headers}
            advancBillingJobsData={csvAdvanceData}
          />
          {/* <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
  
            <div className="d-flex gap-1 align-items-center">

              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>

              <div
                className="pagination-numbers"
                style={{
                  display: "flex",
                  gap: "5px", 
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {renderPageNumbers()}
              </div>

          
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> */}

          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={advancBillingJobsData}
          />
        </>
      )}
    </div>
  );
};

export default AdvancBillingContent;
