import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import TeamsContent from "./components/TeamsContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import debounce from "lodash/debounce";

const MemberOrTeamLeaderTeams = () => {
  const {
    initialState,
    getTeamDetailsByMemberId,
    userDetails,
    getTeamIdOnly,
    initialStateLoading,
    getTeamJobs,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [activeTab, setActiveTab] = useState("myJobs");
  const [filters, setFilters] = useState({ status: null });
  const [isLoadingData, setIsLoadingData] = useState(false);

  const storedTeamID = localStorage.getItem("teamID");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSearchInput("");
    setCurrentPage(1);
  };
  const searchInputRef = useRef(searchInput);
  searchInputRef.current = searchInput;
  const fetchData = async (taskType, searchTerm) => {
    try {
      await getTeamJobs(
        currentPage,
        pageSize,
        storedTeamID || userDetails?.team_id,
        taskType,
        searchTerm,
        filters
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async () => {
      const searchTerm = searchInputRef.current;
      const taskType =
        activeTab === "myJobs" ? "billable_hours" : "advance_billing";
      if (storedTeamID || userDetails?.team_id) {
        await fetchData(taskType, searchTerm);
      }
    }, 500),
    [
      activeTab,
      storedTeamID,
      userDetails?.team_id,
      filters,
      currentPage,
      pageSize,
    ]
  );

  useEffect(() => {
    if (searchInput !== "") {
      debouncedSearch();
    } else {
      const taskType =
        activeTab === "myJobs" ? "billable_hours" : "advance_billing";
      if (storedTeamID || userDetails?.team_id) {
        fetchData(taskType, "");
      }
    }
  }, [
    searchInput,
    currentPage,
    pageSize,
    filters,
    activeTab,
    storedTeamID,
    userDetails?.team_id,
    debouncedSearch,
    isUpdated,
  ]);
  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchInput(trimmedInput);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <TeamsContent
      activeTab={activeTab}
      handleTabChange={handleTabChange}
      teamData={initialState?.myTeamsJobs || []}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      totalPages={initialState?.totalPages}
      setFilters={setFilters}
      filters={filters}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      searchInput={searchInput}
      // setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
      isLoading={initialStateLoading?.getTeamJobsIsLoading}
      setIsLoadingData={setIsLoadingData}
    />
  );
};

export default MemberOrTeamLeaderTeams;
