import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { debounce } from "lodash";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import { teamsIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import {
  handleAPIError,
  headerOptions,
  onlyActiveMembersNotInATeam,
} from "../../../../utils/utilities/utilityFunctions";
import { AsyncPaginate } from "react-select-async-paginate";

const animatedComponents = makeAnimated();

const AddTeam = () => {
  const { logout, userDetails, mainURL } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const userID = localStorage.getItem("userId") || userDetails?.member_id;

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/teams",
    },
    {
      pageName: "Teams",
      pageURL: "/teams",
    },
    {
      pageName: "Add Team",
      pageURL: "/teams/add-team",
    },
  ];

  const [teamDetails, setTeamDetails] = useState({
    teamName: "",
    teamLeader: "",
    teamMembers: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  // const handleTeamLeader = (selected) => {
  //   console.log("selected", selected);

  //   setTeamDetails((prev) => ({
  //     ...prev,
  //     teamLeader: selected,
  //   }));
  // };

  // const handleTeamLeader = (selected) => {
  //   setTeamDetails((prev) => ({
  //     ...prev,
  //     teamLeader: selected,
  //     teamMembers: prev.teamMembers.filter(
  //       ({ value }) => value !== selected?.value
  //     ),
  //   }));
  // };
  // const handleTeamLeader = (selected) => {
  //   setTeamDetails((prev) => ({
  //     ...prev,
  //     teamLeader: selected,
  //   }));
  // };

  // const handleTeamMembers = (selected) =>
  //   setTeamDetails((prev) => ({ ...prev, teamMembers: selected }));
  // const handleTeamMembers = (selected) => {
  //   setTeamDetails((prev) => ({ ...prev, teamMembers: selected }));
  //   // setOptions((prev)=> ({ ...prev, teamMembersOptions:  arrTeamMembers &&
  //   //   arrTeamMembers?.map((m) => ({
  //   //     label: m.name,
  //   //     value: m.id,
  //   //   }))}))
  // };

  const selectedTeamMembers = () =>
    selectedTeamMemberValue?.map(({ value }) => value).join(",");

  // for adding a new team api
  const addNewTeam = async () => {
    setIsLoading(() => true);
    try {
      const body = {
        current_user:
          +localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        team_name: enteredTeamName,
        team_leader: selectedTeamLeaderValue?.value,
        team_member: selectedTeamMembers(),
      };

      const url = `${mainURL}add/team`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 201) {
        ReactHotToast(result.data.message, "success");
        // setTeamDetails(() => ({
        //   teamName: "",
        //   teamLeader: "",
        //   teamMembers: [],
        // }));
        setSelectedTeamLeaderValue("");
        setEnteredTeamName("");
        setSelectedTeamMemberValue([]);

        setToggle((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleAddNewTeam = (e) => {
    e.preventDefault();
    const bool =
      enteredTeamName !== "" &&
      selectedTeamLeaderValue !== "" &&
      selectedTeamMemberValue.length > 0;

    if (bool) {
      addNewTeam();
    } else {
      if (enteredTeamName === "") {
        ReactHotToast("Please provide team name!", "error");
      } else if (selectedTeamLeaderValue === "") {
        ReactHotToast("Please select team leader!", "error");
      } else if (selectedTeamMemberValue.length <= 0) {
        ReactHotToast("Please select team members!", "error");
      }
    }
  };

  // ============================================================================
  // const fetchTeamOptions = async () => {
  //   setIsDropdownLoading(true);
  //   try {
  //     if (memberPageNo === 1) {
  //       await getMembersFreeFromTeam(memberPageNo);
  //       // setArrTeamMembers(initialState?.unassignedMembersList?.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching options:", error);
  //     setIsDropdownLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     (initialState?.unassignedMembersList?.has_more === true &&
  //       memberPageNo === 2) ||
  //     (initialState?.unassignedMembersList?.has_more === true &&
  //       memberPageNo > 2) ||
  //     initialState?.unassignedMembersList?.data?.length > 0
  //   ) {
  //     setIsDropdownLoading(true);
  //     getMembersFreeFromTeam(memberPageNo);
  //     if (
  //       initialState?.unassignedMembersList?.data ||
  //       Object.entries(initialState?.unassignedMembersList?.data)?.length > 0
  //     ) {
  //       if (arrTeamMembers) {
  //         setArrTeamMembers(
  //           ...arrTeamMembers,
  //           initialState?.unassignedMembersList?.data
  //         );
  //       }

  //       if (arrTeamMembers?.length > 0) {
  //         let b =
  //           arrTeamMembers &&
  //           arrTeamMembers?.concat(initialState?.unassignedMembersList?.data);
  //         setArrTeamMembers(b);
  //         if (!initialState?.unassignedMembersList?.has_more) {
  //           setIsDropdownLoading(false);
  //         }
  //       }
  //     }
  //   }
  // }, [initialState?.unassignedMembersList?.has_more, memberPageNo]);

  // // Fetch options on scroll to bottom
  // const handleMenuScrollToBottom = () => {
  //   setMembersPageNo((prevPage) => prevPage + 1); // Load next page
  // };

  // useEffect(() => {
  //   if (arrTeamMembers?.length > 0) {
  //     // Map arrTeamMembers to create the team leader options
  //     const teamLeaderOptions = arrTeamMembers.map((member) => ({
  //       label: member.name,
  //       value: member.id,
  //     }));

  //     // Get the selected team leader's ID
  //     const teamLeaderId = teamDetails?.teamLeader?.value;

  //     // Filter out the selected team leader from the members' list
  //     const teamMembersOptions = arrTeamMembers
  //       .filter((member) => member.id !== teamLeaderId) // Exclude team leader
  //       .map((member) => ({
  //         label: member.name,
  //         value: member.id,
  //       }));

  //     // Update options in state
  //     setOptions({ teamLeaderOptions, teamMembersOptions });
  //   }
  // }, [arrTeamMembers, teamDetails]);

  // React.useEffect(() => {
  //   fetchTeamOptions();
  // }, [memberPageNo]); // Refetch when page changes
  // =======================================================================
  // Applying debouncing to reduce un-necessary api calling
  // const debouncedHandleSearch = debounce((inputValue, string) => {
  //   if (inputValue && string === "teamLeader") {
  //     searchHandlerFunctionForTeamLeaderName(inputValue);
  //     // Map arrTeamMembers to create the team leader options
  //     const teamLeaderOptions = arrTeamMembers.map((member) => ({
  //       label: member.name,
  //       value: member.id,
  //     }));

  //     setOptions((prev) => ({ ...prev, teamLeaderOptions }));
  //   } else if (inputValue && string === "teamMembers") {
  //     searchHandlerFunctionForTeamMembersName(inputValue);
  //     // Get the selected team leader's ID
  //     const teamLeaderId = teamDetails?.teamLeader?.value;
  //     // Filter out the selected team leader from the members' list
  //     const teamMembersOptions = arrTeamMembers
  //       .filter((member) => member.id !== teamLeaderId) // Exclude team leader
  //       .map((member) => ({
  //         label: member.name,
  //         value: member.id,
  //       }));
  //     setOptions((prev) => ({ ...prev, teamMembersOptions }));
  //   }
  // }, 100);

  // const debouncedHandleSearch = debounce((inputValue, string) => {
  //   if (!inputValue) {
  //     // Map arrTeamMembers to create the team leader options
  //     const teamLeaderOptions = arrTeamMembers.map((member) => ({
  //       label: member.name,
  //       value: member.id,
  //     }));

  //     // Get the selected team leader's ID
  //     const teamLeaderId = teamDetails?.teamLeader?.value;

  //     // Filter out the selected team leader from the members' list
  //     const teamMembersOptions = arrTeamMembers
  //       .filter((member) => member.id !== teamLeaderId) // Exclude team leader
  //       .map((member) => ({
  //         label: member.name,
  //         value: member.id,
  //       }));

  //     // Update options in state
  //     setOptions({ teamLeaderOptions, teamMembersOptions });
  //   }

  //   if (string === "teamLeader") {
  //     searchHandlerFunctionForTeamLeaderName(inputValue);
  //   } else if (string === "teamMembers") {
  //     searchHandlerFunctionForTeamMembersName(inputValue);
  //   }
  // }, 100);

  // const searchHandlerFunctionForTeamLeaderName = async (searchData) => {
  //   if (!searchData || typeof searchData !== "string") return;
  //   try {
  //     setIsDropdownLoading((prev) => ({ ...prev, teamLeader: true }));
  //     const response = await axios.get(
  //       `${mainURL}get/member-without-team/${userDetails?.member_id}?&page=1&limit=10&searchQuery=${searchData}`,
  //       {
  //         headers: headerOptions(),
  //       }
  //     );
  //     if (response?.status === 200) {
  //       setOptions((prev) => ({
  //         ...prev,
  //         teamLeaderOptions:
  //           response?.data?.unassigned_member_list?.data &&
  //           response?.data?.unassigned_member_list?.data?.map((leader) => {
  //             return {
  //               label: leader?.name,
  //               value: leader?.id,
  //             };
  //           }),
  //       }));
  //       setIsDropdownLoading((prev) => ({ ...prev, teamLeader: false }));
  //     }
  //   } catch (error) {
  //     console.log("Error fetching jobs:", error);
  //   } finally {
  //     setIsDropdownLoading((prev) => ({ ...prev, teamLeader: false })); // Hide the loading spinner
  //   }
  // };

  // const searchHandlerFunctionForTeamMembersName = async (searchData) => {
  //   if (!searchData || typeof searchData !== "string") return;
  //   try {
  //     setIsDropdownLoading((prev) => ({ ...prev, teamMember: true }));
  //     const response = await axios.get(
  //       `${mainURL}get/member-without-team/${userDetails?.member_id}?&page=1&limit=10&searchQuery=${searchData}`,
  //       {
  //         headers: headerOptions(),
  //       }
  //     );
  //     if (response?.status === 200) {
  //       setOptions((prev) => ({
  //         ...prev,
  //         teamMembersOptions:
  //           response?.data?.unassigned_member_list?.data &&
  //           response?.data?.unassigned_member_list?.data?.map((member) => {
  //             return {
  //               label: member?.name,
  //               value: member?.id,
  //             };
  //           }),
  //       }));
  //       setIsDropdownLoading((prev) => ({ ...prev, teamMember: false }));
  //     }
  //   } catch (error) {
  //     console.log("Error fetching jobs:", error);
  //   } finally {
  //     setIsDropdownLoading((prev) => ({ ...prev, teamMember: false })); // Hide the loading spinner
  //   }
  // };
  // =======================================================================
  // async paginate
  const [selectedTeamLeaderValue, setSelectedTeamLeaderValue] = useState("");
  const [enteredTeamName, setEnteredTeamName] = useState("");
  const [selectedTeamMemberValue, setSelectedTeamMemberValue] = useState([]);

  async function loadOptions(search, loadedOptions, { page }) {
    // console.log("loadOptions");
    try {
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userID}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // console.log("responseresponse", response);

      const newOptions =
        (response?.data?.unassigned_member_list?.data &&
          response?.data?.unassigned_member_list?.data?.map((leader) => {
            return {
              label: leader?.name,
              value: leader?.id,
            };
          })) ||
        [];

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      return {
        options: combinedOptions,
        hasMore: response?.data?.unassigned_member_list?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  // Handle selection of Team Leader
  const handleTeamLeader = (selectedOption) => {
    setSelectedTeamLeaderValue(selectedOption);

    // Remove the selected leader from the team members
    setSelectedTeamMemberValue((prev) =>
      prev.filter((member) => member.value !== selectedOption?.value)
    );
  };

  // Handle selection of Team Members
  const handleTeamMembers = (selectedOptions) => {
    setSelectedTeamMemberValue(selectedOptions || []);

    // Remove the selected member from the leader if applicable
    if (
      selectedTeamLeaderValue &&
      selectedOptions?.some(
        (opt) => opt.value === selectedTeamLeaderValue.value
      )
    ) {
      setSelectedTeamLeaderValue(null);
    }
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={teamsIcon1} alt="teams" />
          <p className="m-0 fs-4">Add Team</p>
        </div>
      </section>

      <section className="main-content_form-section">
        <form
          onSubmit={handleAddNewTeam}
          className="d-flex flex-column justify-content-center align-items-start width-35 m-auto"
        >
          <div className="form-group mt-5 w-100">
            <label htmlFor="teamName">Team Name:</label>
            <input
              id="teamName"
              name="teamName"
              placeholder="Eg: Team Audit section"
              type="text"
              required
              value={enteredTeamName}
              onChange={(e) =>
                // setTeamDetails((prev) => ({
                //   ...prev,
                //   teamName: e.target.value,
                // }))
                setEnteredTeamName(e.target.value)
              }
            />
          </div>
          <div className="form-group mt-4 w-100">
            <label htmlFor="teamLeader">Team Leader:</label>
            {/* <Select
              closeMenuOnSelect={true}
              options={options.teamLeaderOptions}
              onChange={(member) => handleTeamLeader(member)}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={teamDetails.teamLeader}
              name="teamLeader"
              placeholder="Select Team Leader"
              onMenuScrollToBottom={
                // initialState?.unassignedMembersList?.has_more === true ||
                initialState?.unassignedMembersList?.has_more === true &&
                // searchText !== "searchingAssignJob" &&
                // entryDetails?.entryAs === "Team" &&
                handleMenuScrollToBottom
              }
              onInputChange={(inputValue) =>
                debouncedHandleSearch(inputValue, "teamLeader")
              }
              isLoading={isDropdownLoading?.teamLeader}
            /> */}
            <AsyncPaginate
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={selectedTeamLeaderValue}
              loadOptions={loadOptions}
              onChange={(selectedOption) => {
                setSelectedTeamLeaderValue(selectedOption);
                handleTeamLeader(selectedOption);
              }}
              additional={{
                page: 1,
              }}
              debounceTimeout={300} // Prevent excessive API calls
              placeholder={`Select Team Leader`}
              // Enhanced User Experience Features
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Member found for "${inputValue}"`
                  : "No Member found"
              }
              onError={(error) => {
                ReactHotToast("Error loading members", "error");
              }}
              // Custom Styles for Better Readability
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          <div className="form-group mt-4 w-100">
            <label htmlFor="teamMembers">Team Members:</label>
            {/* <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              placeholder="Select Team Members"
              options={options.teamMembersOptions}
              onChange={(member) => handleTeamMembers(member)}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={teamDetails.teamMembers}
              name="teamMembers"
              onMenuScrollToBottom={
                // initialState?.unassignedMembersList?.has_more === true ||
                initialState?.unassignedMembersList?.has_more === true &&
                // searchText !== "searchingAssignJob" &&
                // entryDetails?.entryAs === "Team" &&
                handleMenuScrollToBottom
              }
              onInputChange={(inputValue) =>
                debouncedHandleSearch(inputValue, "teamMembers")
              }
              isLoading={isDropdownLoading?.teamMember}
            /> */}
            <AsyncPaginate
              isMulti
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={selectedTeamMemberValue}
              loadOptions={loadOptions}
              onChange={(selectedOption) => {
                setSelectedTeamMemberValue(selectedOption);
                handleTeamMembers(selectedOption);
              }}
              additional={{
                page: 1,
              }}
              debounceTimeout={300} // Prevent excessive API calls
              placeholder={`Select Team Members`}
              // Enhanced User Experience Features
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Member found for "${inputValue}"`
                  : "No Member found"
              }
              onError={(error) => {
                ReactHotToast("Error loading members", "error");
              }}
              // Custom Styles for Better Readability
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          <button type="submit" className="mt-5 custom-btn d-flex m-auto">
            {isLoading ? <SpinningLoader /> : "Add Team"}
          </button>
        </form>
      </section>
    </div>
  );
};

export default AddTeam;
