import React, { useState, useEffect, useContext, useCallback } from "react";

import TeamsContent from "./components/TeamsContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";

const AdminOrManagerTeams = () => {
  const { getAllTeams, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTriggered, setSearchTriggered] = useState(false);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllTeams(currentPage, pageSize, searchQuery);
    }, 500),
    [currentPage, pageSize]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllTeams(currentPage, pageSize, "");
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);


  return (
    <TeamsContent
      teamsData={initialState?.teamsList}
      setIsUpdated={setIsUpdated}
      isLoading={initialStateLoading?.getAllTeamsIsLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
    />
  );
};

export default AdminOrManagerTeams;
