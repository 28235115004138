import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { Tooltip } from "react-tooltip";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import PageHeader from "../../../templates/PageHeader";
import {
  searchIcon,
  settingsIcon1,
} from "../../../utils/ImportingImages/ImportingImages";
import LogsTable from "./components/LogsTable";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { LogsModal } from "./components/LogsModal";
import { userRole } from "../../../utils/utilities/utilityFunctions";
import PaginationComponent from "../../../Components/Pagination/PaginationComponent";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Logs",
    pageURL: "/logs",
  },
];

const columnHeaders = ["Sr no", "Name", "Role", "Edit"];

const headers = {
  headings: [
    { label: "Member Name", key: "name" },
    { label: "Role", key: "user_role" },
    { label: "Login Time", key: "login_on" },
  ],
  fileName: "Member Logs",
};
const AdminOrManagerLogs = ({
  fetchedCurrentPage,
  membersData,
  isUpdated,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchInput,
  handleInputChange,
}) => {
  const { getAllLogs, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [allUserLogsData, setAllUserLogsData] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  useEffect(() => {
    getAllLogs();
  }, [isUpdated]);

  // creating logs dataset [merged all members api & logs api]
  useEffect(() => {
    const getLog = (memberId) =>
      initialState?.allLogs
        ?.filter(({ user_id }) => user_id === memberId)
        .map(({ login_on }) => login_on);

    const data = membersData?.reduce((acc, curr) => {
      const bool = acc?.find((user) => user?.id === curr.member_id);
      if (!bool) {
        // const userLogs = getLog(curr.member_id);
        const userLogs = getLog(curr.member_id) || [];
        const newMember = {
          name: curr.member_name,
          id: curr.member_id,
          role: userRole(curr.member_role),
          srNo: curr.sr_no,
          userLogs,
        };
        acc.push(newMember);
      }
      return acc;
    }, []);

    setAllUserLogsData(data);
  }, [membersData, initialState.allLogs]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "srNo",
    },
    {
      Header: "Member Name",
      accessor: "name",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <>
          <Tooltip
            id="check-user-logs-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            className="text-center"
            data-tooltip-id="check-user-logs-tooltip"
            data-tooltip-content={`Check ${row.original.name} logs`}
            data-tooltip-place="top"
          >
            <LogsModal userLogData={row.original} />
          </div>
        </>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => allUserLogsData, [allUserLogsData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className={"main-content_header add-border-bottom"}>
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={settingsIcon1} alt={"Member Logs"} />
          <p className="m-0 fs-4">{"Member Logs"}</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>

      <section className="width-65 m-auto mt-5">
        {initialStateLoading?.getAllLogsIsLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : (
          <div className="logs-table">
            <LogsTable
              tableInstance={tableInstance}
              headers={headers}
              logsData={initialState.allLogs}
            />
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              dataList={membersData}
              text="membersLogAdmin"
            />
            {/* <div
              className="d-flex justfy-content-center align-items-center smallText"
              style={{
                width: "45%",
                position: "absolute",
                marginLeft: "90px",
                bottom: "48px",
                gap: "280px",
              }}
            >
              <div className="d-flex gap-1 align-items-center">
                <button
                  className="cursor-pointer"
                  style={{ border: "none", background: "transparent" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                    />
                  </svg>
                </button>
                <input
                  className="gotoPage-input-field"
                  type="number"
                  defaultValue={currentPage}
                  readOnly
                />
                <span className="px-2">/</span>

                <span> {totalPages}</span>
                <button
                  style={{ border: "none", background: "transparent" }}
                  className="cursor-pointer"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={
                    (membersData && membersData?.length < pageSize) ||
                    totalPages === 1
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="px-1 smallText">Rows /page</span>
                <select value={pageSize} onChange={handlePageSizeChange}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div> */}
          </div>
        )}
      </section>
    </div>
  );
};

export default AdminOrManagerLogs;
