import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { debounce } from "lodash";
import { teamsIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";

import {
  formatDate,
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import { AsyncPaginate } from "react-select-async-paginate";

const animatedComponents = makeAnimated();

const EditTeamDetails = () => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [isDropdownLoading, setIsDropdownLoading] = useState({
    teamLeader: false,
    teamSubLeader: false,
    teamMember: false,
    assignJob: false,
    advanceBillingJob: false,
  });

  const {
    getAllMembers,
    getSingleTeamDetails,
    getMembersFreeFromTeam,
    userDetails,
    logout,
    mainURL,
    initialState,
  } = useContext(ContextAPI);
  // const { teamData } = location.state ?? "";
  const TeamID = useParams();
  const [loading, setLoading] = useState(false);
  const [parentJobFilterData, setParentJobFilterData] = useState([]);
  const [
    parentAdvancedBillingJobFilterData,
    setParentAdvancedBillingJobFilterData,
  ] = useState([]);

  const userID = localStorage.getItem("userId") || userDetails?.member_id;

  const [arrTeamMembers, setArrTeamMembers] = useState([]);
  const [memberPageNo, setMembersPageNo] = useState(1);
  const [teamMembersListOption, setTeamMembersListOption] = useState([]);
  const [teamLeadersListOption, setTeamLeadersListOption] = useState([]);
  const [teamSubLeadersListOption, setTeamSubLeadersListOption] = useState([]);

  const navigate = useNavigate();
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Teams",
      pageURL: "/teams",
    },
    {
      pageName: "Manage Team",
      pageURL: "/teams/manage-team",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [updatedTeamData, setUpdatedTeamData] = useState({
    teamName: initialState?.singleTeam?.team_name || "",
    teamCode: initialState?.singleTeam?.team_code || "",
    teamLeader: "",
    teamSubLeader: [],
    teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
    teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
    teamMembersCount: initialState?.singleTeam?.member_count || 0,
    teamMembersName: [],
    teamStatus: initialState?.singleTeam?.status || "",

    jobs: initialState?.singleTeam?.assigned_jobs_list,
    advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
    selectedJob: null,
    selectedAdvacedBillingJob: null,
  });

  useEffect(() => {
    if (Object.entries(initialState)) {
      setUpdatedTeamData({
        teamName: initialState?.singleTeam?.team_name || "",
        teamCode: initialState?.singleTeam?.team_code || "",
        teamLeader: "",
        teamSubLeader: [],
        teamMembersName: [],
        teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
        teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
        teamMembersCount: initialState?.singleTeam?.member_count || 0,
        teamStatus: initialState?.singleTeam?.status || "",
        jobs: initialState?.singleTeam?.assigned_jobs_list,
        advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
        selectedJob: null,
        selectedAdvacedBillingJob: null,
      });
    }
  }, [initialState?.singleTeam]);

  // fetching all members list
  useEffect(() => {
    getAllMembers();
  }, []);

  const getJobDetails = () => {
    return (
      parentJobFilterData &&
      parentJobFilterData?.find(
        (job) => job?.task_id === updatedTeamData?.selectedJob?.value
      )
    );
  };

  // If user role is "member" OR "Team Leader but only from this same team then OK"
  const condition1 = (user) =>
    user.member_role === "members" ||
    user.member_role === "members,team_sub_leader" ||
    (user.member_role === "team_leaders,members" &&
      initialState?.singleTeam?.team_leader === user.member_id);

  // "If member is not in any team" AND "member is not a part of this Team"
  // const condition2 = (user) =>
  //   user.is_added_in_team !== "1" &&
  //   ! initialState?.singleTeam?.team_member.split(",").includes(user.member_id);
  const condition2 = (user) => {
    const teamMembers = initialState?.singleTeam?.team_member?.split(",") || []; // Fallback to empty array if undefined
    return (
      user.is_added_in_team !== "1" && !teamMembers.includes(user.member_id)
    );
  };

  // "If member is assigned a Team" AND "member is a part of this Team"
  // const condition3 = (user) =>
  //   user.is_added_in_team === "1" &&
  //   ( initialState?.singleTeam?.team_member.split(",").includes(user.member_id) ||
  //     // teamData.sub_leaders.split(",").includes(user.member_id)||
  //     ( initialState?.singleTeam?.sub_leaders &&
  //       initialState?.singleTeam?.sub_leaders.split(",").includes(user.member_id)) ||
  //       initialState?.singleTeam?.team_leader === user.member_id);

  const condition3 = (user) => {
    const teamMembers = initialState?.singleTeam?.team_member?.split(",") || [];
    const subLeaders = initialState?.singleTeam?.sub_leaders?.split(",") || [];

    return (
      user.is_added_in_team === "1" &&
      (teamMembers.includes(user.member_id) ||
        subLeaders.includes(user.member_id) ||
        initialState?.singleTeam?.team_leader === user.member_id)
    );
  };

  // helper function for creating Members Dropdown options
  // step 1: taking the complete list of members from initialState
  // step 2: filtering that dataset with 3 conditions
  // step 3: checking that each one of them is active user
  // step 4: lastly mapping over them all and return only specfic object details using map

  const generateOptions = (dataset, excludeMemberId) => {
    return dataset
      ?.filter((member) => {
        return (
          condition1(member) &&
          (condition2(member) || condition3(member)) &&
          member.current_status === "active" &&
          member.member_id !== excludeMemberId
        );
      })
      .map((member) => ({
        label: member.member_name,
        value: member.member_id,
      }));
  };

  useEffect(() => {
    if (initialState?.singleTeam) {
      const teamMembersArray =
        initialState?.singleTeam?.team_member?.split(",") || [];
      const subLeadersArray =
        initialState?.singleTeam?.sub_leaders?.split(",") || [];

      // Filter members based on team_members and sub_leaders
      const defaultMembers = generateOptions(initialState?.membersList).filter(
        (member) => teamMembersArray.includes(member.value)
      );

      // Find the default team leader
      const defaultTeamLeader = generateOptions(
        initialState?.membersList
      )?.find(
        (member) => member.value === initialState?.singleTeam?.team_leader
      );

      // Find the selected sub-team leaders
      const selectedSubTeamLeaders = initialState?.membersList
        .filter((member) => subLeadersArray.includes(member.member_id))
        .map((member) => ({
          label: member.member_name,
          value: member.member_id,
        }));

      // Update the team data with the selected values
      setUpdatedTeamData((prev) => ({
        ...prev,
        teamMembersName: defaultMembers,
        teamLeader: defaultTeamLeader,
        teamSubLeader: selectedSubTeamLeaders,
      }));
    }
  }, [initialState?.membersList, initialState?.singleTeam]);

  useEffect(() => {
    // Filter team leader from teamMembersName and set team leader email
    const removeTeamLeaderAndSubLeader = (dataset) =>
      dataset.filter(
        (member) => member.value !== updatedTeamData.teamLeader?.value
      );

    const teamLeader =
      initialState?.membersList.find(
        (member) => member.member_id === updatedTeamData.teamLeader?.value
      )?.member_email ?? "";

    setUpdatedTeamData((prev) => ({
      ...prev,
      teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
      teamLeaderEmail: teamLeader,
    }));
  }, [initialState?.membersList, updatedTeamData.teamLeader]);

  // Sub team leader
  useEffect(() => {
    // Update team members list to exclude selected sub-leaders
    const removeTeamLeaderAndSubLeader = (dataset) =>
      dataset.filter((member) =>
        updatedTeamData.teamSubLeader
          ? !updatedTeamData.teamSubLeader.some(
              (subLeader) => subLeader.value === member.value
            )
          : true
      );

    setUpdatedTeamData((prev) => ({
      ...prev,
      teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
    }));
  }, [initialState?.membersList, updatedTeamData.teamSubLeader]);

  useEffect(() => {
    const membersArray =
      initialState?.singleTeam?.team_member?.split(",") || [];

    const teamLeaderOptions = generateOptions(initialState?.membersList).filter(
      (member) => {
        return !membersArray.includes(member.value);
      }
    );
  }, [initialState?.membersList, initialState?.singleTeam?.team_member]);

  useEffect(() => {
    getSingleTeamDetails(TeamID?.teamId);
  }, [TeamID?.teamId]);

  const getadvacedBillingJobDetails = () => {
    return parentAdvancedBillingJobFilterData?.find(
      (job) =>
        job?.task_id === updatedTeamData?.selectedAdvacedBillingJob?.value
    );
  };
  const handleAdvanceBillingJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedAdvacedBillingJob: option,
    }));
  };
  const handleAssignJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedJob: option,
    }));
  };

  // ===================================================================
  const updateTeam = async () => {
    setIsLoading(() => true);
    try {
      const body = {
        current_user:
          +localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        team_name: updatedTeamData.teamName,
        team_leader: +updatedTeamData.teamLeader?.value,
        team_sub_leader: updatedTeamData.teamSubLeader
          .map(({ value }) => value)
          .join(","),
        team_member: updatedTeamData.teamMembersName
          .map(({ value }) => value)
          .join(","),
        team_id: +initialState?.singleTeam?.id,
        status: updatedTeamData.teamStatus,
      };

      const url = `${mainURL}update/team`;
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        navigate("/teams");
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleUpdateTeam = (e) => {
    e.preventDefault();
    if (
      updatedTeamData.teamName &&
      updatedTeamData.teamLeader &&
      updatedTeamData.teamLeaderEmail &&
      updatedTeamData.teamMembersName &&
      updatedTeamData.teamStatus
    ) {
      updateTeam();
    } else {
      if (updatedTeamData.teamName === "") {
        ReactHotToast("Please input team name!", "error");
      } else if (updatedTeamData.teamLeader === "") {
        ReactHotToast("Please select team leader!", "error");
        // } else if (updatedTeamData.teamLeaderEmail === "") {
        //   ReactHotToast("Please input team leader email!", "error");
      } else if (updatedTeamData.teamMembersName.length <= 0) {
        ReactHotToast("Please select team members!", "error");
      } else if (updatedTeamData.teamStatus === "") {
        ReactHotToast("Please select team status!", "error");
      }
    }
  };

  // =====================================================================
  // =====================================================================
  // async paginate

  const [selectedAssignedJobValue, setSelectedAssignedJobValue] = useState("");
  const [selectedAdvancedJobValue, setSelectedAdvancedJobValue] = useState("");

  const [selectedTeamLeaderValue, setSelectedTeamLeaderValue] = useState(null);
  const [selectedSubLeaderValue, setSelectedSubLeaderValue] = useState(null);
  const [selectedTeamMemberValue, setSelectedTeamMemberValue] = useState(null);
  const [parentTeamLeaderRoleFilterData, setParentTeamLeaderRoleFilterData] =
    useState([]);

  const [
    parentTeamSubLeaderRoleFilterData,
    setParentTeamSubLeaderRoleFilterData,
  ] = useState([]);

  useEffect(() => {
    if (
      parentTeamLeaderRoleFilterData?.length > 0 ||
      parentTeamSubLeaderRoleFilterData?.length > 0
    ) {
      const teamLeaderId = updatedTeamData?.teamLeader?.value;
      const subLeaderIds =
        updatedTeamData?.teamSubLeader?.map((subLeader) => subLeader?.value) ||
        [];

      //   // Filter for team members list: exclude selected team leader and sub-leaders
      //   const teamMembersOptions = parentRoleFilterData
      //     ?.filter(
      //       (member) =>
      //         member.id !== teamLeaderId && !subLeaderIds.includes(member.id)
      //     )
      //     ?.map((m) => ({
      //       label: m.name,
      //       value: m.id,
      //     }));

      //   // Filter for team sub-leaders list: exclude the selected team leader
      const teamSubLeadersOptions = parentTeamSubLeaderRoleFilterData
        ?.filter((member) => member.id !== teamLeaderId)
        ?.map((m) => ({
          label: m.name,
          value: m.id,
        }));

      // Filter for team leader options (all members can be leaders here)
      const teamLeaderOptions = parentTeamLeaderRoleFilterData?.map((m) => ({
        label: m.name,
        value: m.id,
      }));

      //   setTeamMembersListOption(teamMembersOptions);
      setTeamLeadersListOption(teamLeaderOptions);
      setTeamSubLeadersListOption(teamSubLeadersOptions);
    }
  }, [
    parentTeamLeaderRoleFilterData,
    parentTeamSubLeaderRoleFilterData,
    updatedTeamData?.teamLeader,
    updatedTeamData?.teamSubLeader,
  ]);

  async function assignJobLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${TeamID?.teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAssignJobs = response.data.filterData.data;

      let combinedAssignedjobs = [...parentJobFilterData, ...newAssignJobs];
      setParentJobFilterData(combinedAssignedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${TeamID?.teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            // Safely parse additional_data
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAdvancedJobs = response?.data?.filterData?.data;

      let combinedAdvancedjobs = [
        ...parentAdvancedBillingJobFilterData,
        ...newAdvancedJobs,
      ];
      setParentAdvancedBillingJobFilterData(combinedAdvancedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function teamLeaderLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userDetails?.member_id || userID}`,
        {
          params: {
            page,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const newOptions =
        response?.data?.unassigned_member_list?.data &&
        response?.data?.unassigned_member_list?.data
          ?.map((item) => {
            try {
              return {
                label: item.name,
                value: item.id,
              };
            } catch (parseError) {
              console.error("Error parsing item:", parseError);
              return {
                value: item.user_id,
                label: "Parsing Error - Invalid Role Data",
              };
            }
          })
          .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newTeamData = response?.data?.unassigned_member_list?.data;

      let combinedTeamData = [
        ...parentTeamLeaderRoleFilterData,
        ...newTeamData,
      ];
      setParentTeamLeaderRoleFilterData(combinedTeamData);

      return {
        options: combinedOptions,
        hasMore: response?.data?.unassigned_member_list?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function teamSubLeaderLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userDetails?.member_id || userID}`,
        {
          params: {
            page,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const newOptions =
        response?.data?.unassigned_member_list?.data &&
        response?.data?.unassigned_member_list?.data
          ?.map((item) => {
            try {
              return {
                label: item.name,
                value: item.id,
              };
            } catch (parseError) {
              console.error("Error parsing item:", parseError);
              return {
                value: item.user_id,
                label: "Parsing Error - Invalid Role Data",
              };
            }
          })
          .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newTeamData = response?.data?.unassigned_member_list?.data;

      let combinedTeamData = [
        ...parentTeamSubLeaderRoleFilterData,
        ...newTeamData,
      ];
      setParentTeamSubLeaderRoleFilterData(combinedTeamData);

      return {
        options: combinedOptions,
        hasMore: response?.data?.unassigned_member_list?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={teamsIcon1} alt="Manage Team" />
          <p className="m-0 fs-4">Manage Team</p>
        </div>
      </section>
      <section className="main-content_form-section d-flex justify-content-center align-items-start w-75 m-auto">
        <form
          onSubmit={handleUpdateTeam}
          className="w-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div className="d-flex justify-content-center align-items-start gap-5 w-100">
            <div className="w-50">
              <div className="form-group mt-5">
                <label htmlFor="teamCode">Team Code:</label>
                <input
                  id="teamCode"
                  name="teamCode"
                  placeholder="Eg: 00101"
                  type="text"
                  value={updatedTeamData?.teamCode}
                  required
                  disabled
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamName">Team Name:</label>
                <input
                  id="teamName"
                  name="teamName"
                  placeholder="Eg: Team Titans"
                  type="text"
                  value={updatedTeamData?.teamName}
                  onChange={(e) =>
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamName: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamLeader">Team Leader:</label>

                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  //   value={selectedTeamLeaderValue}
                  value={updatedTeamData.teamLeader}
                  loadOptions={(search, loadedOptions, additional) =>
                    teamLeaderLoadOptions(search, loadedOptions, additional)
                  }
                  options={teamLeadersListOption}
                  onChange={(selectedOption) => {
                    setSelectedTeamLeaderValue(selectedOption);
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamLeader: selectedOption,
                    }));
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select team leader...."}
                  debounceTimeout={300} // Prevent excessive API calls
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No team leaders found for "${inputValue}"`
                      : "No team leaders found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading team leaders", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamSubLeader">Team Sub-Leader:</label>
                <AsyncPaginate
                  isMulti
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  //   value={selectedSubLeaderValue}
                  value={updatedTeamData.teamSubLeader}
                  loadOptions={(search, loadedOptions, additional) =>
                    teamSubLeaderLoadOptions(search, loadedOptions, additional)
                  }
                  options={teamSubLeadersListOption}
                  onChange={(selectedOption) => {
                    setSelectedSubLeaderValue(selectedOption);
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamSubLeader: selectedOption,
                    }));
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select sub-leader...."}
                  debounceTimeout={300} // Prevent excessive API calls
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No sub-leaders found for "${inputValue}"`
                      : "No sub-leaders found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading sub-leaders", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamLeaderEmail">Team Leader Email:</label>
                <input
                  id="teamLeaderEmail"
                  name="teamLeaderEmail"
                  placeholder="Eg: mailto:rajshah@gmail.com"
                  type="email"
                  value={updatedTeamData?.teamLeaderEmail}
                  disabled
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamMemberCount">Team Member Count:</label>
                <input
                  id="teamMemberCount"
                  name="teamMemberCount"
                  placeholder="Eg: 12"
                  type="number"
                  value={updatedTeamData?.teamMembersCount}
                  disabled
                  required
                />
              </div>
              <button
                type="submit"
                className="mt-4 w-25 custom-btn d-flex justify-content-center align-items-center"
              >
                {isLoading ? <SpinningLoader /> : "Update"}
              </button>
            </div>
            <div className="w-50">
              <div className="form-group mt-5">
                <label htmlFor="teamMembers">Team Members:</label>
                <AsyncPaginate
                  isMulti
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  //   value={selectedTeamMemberValue}
                  value={updatedTeamData.teamMembersName}
                  //   loadOptions={(search, loadedOptions, additional) =>
                  //     teamLoadOptions(search, loadedOptions, additional, "member")
                  //   }
                  onChange={(selectedOption) => {
                    setSelectedTeamMemberValue(selectedOption);
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamMembersName: selectedOption,
                    }));
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select team member...."}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No team members found for "${inputValue}"`
                      : "No team members found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading team members", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamStatus">Team Status:</label>
                <Select
                  name="teamStatus"
                  closeMenuOnSelect={true}
                  options={statusOptions}
                  onChange={({ value }) =>
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamStatus: value,
                    }))
                  }
                  value={
                    updatedTeamData.teamStatus === "active"
                      ? { label: "Active", value: "active" }
                      : { label: "Inactive", value: "inactive" }
                  }
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>

              {/*  Assign Job List  */}
              <div className="form-group mt-4">
                <label htmlFor="clientName">Assigned Job List:</label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={selectedAssignedJobValue}
                  loadOptions={assignJobLoadOptions}
                  onChange={(selectedOption) => {
                    setSelectedAssignedJobValue(selectedOption);
                    handleAssignJobSelectFunction(selectedOption);
                  }}
                  additional={{
                    page: 1,
                  }}
                  debounceTimeout={300}
                  placeholder={`Select Assigned Job`}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Job found for "${inputValue}"`
                      : "No Job found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading Job", "error");
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              {updatedTeamData.selectedJob && (
                <div className={`projectDetails mt-4`}>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <p className={`bigText fs-5 m-0`}>
                      Job Name:{" "}
                      <div className={`smallText fs-6 mt-2`}>
                        {" "}
                        {updatedTeamData.selectedJob.label}{" "}
                      </div>{" "}
                    </p>
                    <Stack direction="horizontal">
                      {getJobDetails()?.job_status === "Completed" ? (
                        <Badge bg="success">Completed</Badge>
                      ) : getJobDetails()?.job_status === "On Hold" ? (
                        <Badge bg="danger">On Hold</Badge>
                      ) : (
                        <Badge bg="warning" text="dark">
                          {" "}
                          In Progress{" "}
                        </Badge>
                      )}{" "}
                    </Stack>{" "}
                  </div>{" "}
                  {getJobDetails()?.job_description && (
                    <>
                      <p className="fs-6 m-0 mt-2 fw-semibold">
                        Project Description:{" "}
                      </p>
                      <p>{getJobDetails()?.job_description}</p>
                    </>
                  )}
                  <p className="fs-6 m-0 mt-2 fw-semibold">
                    Estimated Timeline:{" "}
                  </p>
                  <p className="fs-6 m-0">
                    {formatDate(getJobDetails()?.assigned_on)} to{" "}
                    {formatDate(getJobDetails()?.due_on)}
                  </p>
                </div>
              )}

              {/*  Advanced Billing Job List  */}
              <div className="form-group mt-4">
                <label htmlFor="advanceBillingJobs">
                  Advanced Billing Job List:
                </label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={selectedAdvancedJobValue}
                  loadOptions={advanceBillingloadOptions}
                  onChange={(selectedOption) => {
                    setSelectedAdvancedJobValue(selectedOption);
                    handleAdvanceBillingJobSelectFunction(selectedOption);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select job...."}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No jobs found for "${inputValue}"`
                      : "No jobs found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              {updatedTeamData?.selectedAdvacedBillingJob && (
                <div className={`projectDetails mt-4`}>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <p className={`bigText fs-5 m-0`}>
                      Job Name:{" "}
                      <div className={`smallText fs-6 mt-1`}>
                        {updatedTeamData.selectedAdvacedBillingJob.label}
                      </div>
                    </p>
                    <Stack direction="horizontal">
                      {getadvacedBillingJobDetails()?.job_status ===
                      "Completed" ? (
                        <Badge bg="success">Completed</Badge>
                      ) : getadvacedBillingJobDetails()?.job_status ===
                        "On Hold" ? (
                        <Badge bg="danger">On Hold</Badge>
                      ) : (
                        <Badge bg="warning" text="dark">
                          In Progress
                        </Badge>
                      )}
                    </Stack>
                  </div>
                  {getadvacedBillingJobDetails()?.job_description && (
                    <p className="fs-6 m-0 mt-3 fw-semibold">
                      Project Description:{" "}
                      <span>
                        {getadvacedBillingJobDetails()?.job_description}
                      </span>
                    </p>
                  )}
                  <p className="fs-6 m-0 mt-3 fw-semibold">
                    Period Start Date:{" "}
                  </p>
                  <p className="fs-6 m-0">
                    {formatDate(getadvacedBillingJobDetails()?.assigned_on)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
export default EditTeamDetails;
