import { useMemo } from "react";
import Select from "react-select";
import {
  searchIcon,
  reportsIcon,
  userIcon,
  membersIcon,
  clientsIcon,
  teamsIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import { DatePicker } from "antd";
import { AsyncPaginate } from "react-select-async-paginate";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import dayjs from "dayjs";
const entryAsOptions = [
  { label: "Member", value: "member" },
  { label: "Team", value: "team" },
];
const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
];
const timeEntriesTypeOption = [
  { label: "Billable Hours", value: "billable_hours" },
  { label: "Advanced Billing", value: "advanced_billing" },
  { label: "Side Works", value: "side_works" },
];
const TimeEntriesFilter = ({
  filters,
  onFilterChange,
  forTeamLeader,
  timeEntries,
  filterDiscountedData,
  UsersLoadOptions,
  TeamMemberLoadOptions,
  TeamLoadOptions,
  value,
  handleChange,
  setFilters,
}) => {
  // console.log("valueForEmployee",value)
  return (
    <div className="mr-40 ml-30 mt-5">
      <div className="d-flex justify-content-between align-items-center gap-4">
        <div className="flex-1 d-flex gap-4">
          {!forTeamLeader && (
            <div className="relative-wrapper w-25">
              <img className="search-icon" src={searchIcon} alt="search-icon" />
              <Select
                closeMenuOnSelect={true}
                isClearable={true}
                options={entryAsOptions}
                onChange={(option) => onFilterChange("entryAs", option)}
                value={filters.entryAs}
                placeholder="Select Entry as"
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
              />
            </div>
          )}

          {/* {!forTeamLeader && filters.showDiscountedHoursEntries ? (
            <div className="relative-wrapper w-25">
              <img className="search-icon" src={userIcon} alt="user-icon" />
              <Select
                closeMenuOnSelect={true}
                isClearable={true}
                options={reviewerOptions}
                onChange={(option) => onFilterChange("reviewer", option)}
                value={filters.reviewer}
                placeholder="Select Reviewer"
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
              />
            </div>
          ) : ( */}
          <div className="relative-wrapper w-25">
            <img className="search-icon" src={reportsIcon} alt="reports-icon" />
            <Select
              closeMenuOnSelect={true}
              isClearable={true}
              options={statusOptions}
              onChange={(option) => onFilterChange("status", option)}
              value={filters.status}
              placeholder="Select Status"
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          {/* <div className="relative-wrapper w-25">
            <img className="search-icon" src={membersIcon} alt="reports-icon" />

            <AsyncPaginate
              isClearable={true}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={filters.member}
              onChange={(option) => onFilterChange("member", option)}
          
              loadOptions={UsersLoadOptions || TeamMemberLoadOptions}
              // onChange={(selectedOption) => {
              //   setFilters((prev) => ({
              //     ...prev,
              //     member: selectedOption,
              //   }));
              // }}
              additional={{
                page: 1,
              }}
              placeholder={`Select Employee`}
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Employee found for "${inputValue}"`
                  : "No Employee found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Employee", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#007bff"
                    : state.isFocused
                    ? "#e0e0e0"
                    : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div> */}
          {/* {!forTeamLeader &&  (
          <div className="relative-wrapper w-25">
            <img className="search-icon" src={teamsIcon} alt="reports-icon" />

            <AsyncPaginate
              isClearable={true}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={filters.team}
              options={value}
              loadOptions={TeamLoadOptions}
              onChange={(selectedOption) => {
                setFilters((prev) => ({
                  ...prev,
                  team: selectedOption,
                }));
              }}
              additional={{
                page: 1,
              }}
              placeholder={`Select Team`}
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Team found for "${inputValue}"`
                  : "No Team found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Teams", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#007bff"
                    : state.isFocused
                    ? "#e0e0e0"
                    : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
          )} */}
          {/* <div className="relative-wrapper w-25">
         
            <DatePicker
              defaultValue={dayjs()}
              className="form-control datepicker"
              placeholder="Select Working date"
              popupClassName="pop-up-box"
              value={filters.date}
              onChange={(date, dateString) => handleChange(date, dateString)}
              format="DD-MM-YYYY"
            />
          </div> */}
        </div>

        {/* {!forTeamLeader && (
          <div className="form-group flex-row align-items-center justify-content-start">
            <label
              style={{ width: "max-content" }}
              htmlFor="discountedHoursEntries"
            >
              Adjusted Hours:
            </label>
            <input
              id="discountedHoursEntries"
              name="discountedHoursEntries"
              type="checkbox"
              style={{ marginLeft: "10px" }}
              className="cursor-pointer checkbox-input"
              checked={filters.showDiscountedHoursEntries}
              onChange={(e) =>
                onFilterChange("showDiscountedHoursEntries", e.target.checked)
              }
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
export default TimeEntriesFilter;
