import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";

import JobsContent from "./components/JobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { headerOptions } from "../../../../utils/utilities/utilityFunctions";
import axios from "axios";

const AdminOrManagerJobs = () => {
  const {
    getAllJobs,
    initialState,
    initialStateLoading,
    mainURL,
    userDetails,userId
  } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const userID = localStorage.getItem("userId");
  const [filters, setFilters] = useState({ client: null, category: null });
  useEffect(() => {
    setCurrentPage(1);
    setPageSize(10);
  }, [filters]);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllJobs(currentPage, pageSize, searchQuery, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllJobs(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
    filters,
  ]);
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
    setCurrentPage(1);
  };

  const LoadClientsOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await axios.get(`${mainURL}/list/all-client/${userID}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      const clientData = response.data?.client_list?.client_data || [];

      const transformedData = clientData.map((item) => {
        try {
          const { client_name, additional_data, client_id } = item;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name}${
            bpoNo ? ` - ${bpoNo}` : ""
          }`;

          return {
            value: client_id,
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.client_id,
            label: "Parsing Error - Invalid Client Data",
          };
        }
      });

      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
      const hasMore = response.data?.client_list?.hasMore ?? false;
      return {
        options: combinedOptions,
        hasMore: hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading client options:", error);

      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const JobsLoadOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await axios.get(
        `${mainURL}get/all-job-type/${userId}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const jobs = response.data?.job_category_list?.data || [];
      if (jobs.length === 0) {
        return {
          options: [],
          hasMore: false,  
          additional: {
            page: page + 1,
          },
        };
      }
  
      const transformedData = jobs.map((item) => {
        try {
  
          const label = `${item?.job_category_name || "N/A"}`;
          return {
            value: item?.job_category_name,  
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.job_category_name,
            label: "Parsing Error - Invalid Job Data",
          };
        }
      });
  
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      const hasMore = response?.data?.job_category_list?.has_more ?? false;  

      return {
        options: combinedOptions,
        hasMore: hasMore,
        additional: {
          page: page + 1,  
        },
      };
    } catch (error) {
      console.error("Error loading job options:", error);
      return {
        options: [],
        hasMore: false, 
      };
    }
  };
  

  return (
    <JobsContent
      jobsData={initialState?.jobs}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      isLoading={initialStateLoading?.getAllJobsIsLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
      setFilters={setFilters}
      filters={filters}
      onFilterChange={handleFilterChange}
      LoadClientsOptions={LoadClientsOptions}
      JobsLoadOptions={JobsLoadOptions}
    />
  );
};

export default AdminOrManagerJobs;
