import React, { useCallback, useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import AdminOrManagerLogs from "./AdminOrManager/AdminOrManagerLogs";
import MemberOrTeamLeaderLogs from "./MemberOrTeamLeader/MemberOrTeamLeaderLogs";
import { debounce } from "lodash";

const Logs = () => {
  const { getAllMembers, userDetails, initialState, initialStateLoading } =
    useContext(ContextAPI);
  let teamID = localStorage.getItem("teamID");

  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  // Retrieve page number from localStorage, default to 1 if not found

  const [currentPage, setCurrentPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllMembers(
        currentPage,
        pageSize,
        searchQuery,
        teamID || userDetails?.team_id
      );
    }, 500),
    [currentPage, pageSize]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllMembers(currentPage, pageSize, "", teamID || userDetails?.team_id);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
    teamID,
    userDetails?.team_id,
  ]);

  return (
    <>
      {userDetails?.member_role === "it_member" ||
      userDetails?.member_role === "operation_member" ? (
        <AdminOrManagerLogs
          membersData={initialState?.membersList}
          totalPages={initialState?.totalPages}
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
          isLoading={initialStateLoading?.getAllMembersIsLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fetchedCurrentPage={initialState?.currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleInputChange={handleInputChange}
        />
      ) : (
        <MemberOrTeamLeaderLogs  />
      )}
    </>
  );
};

export default Logs;
