import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import {
  usersIcon,
  searchIcon,
  reportsIcon,
  teamsIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import Select from "react-select";
import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import MembersTable from "./MembersTable";
import { EditMemberModal } from "./EditMemberModal";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { DeleteMemberModal } from "./DeleteMemberModal";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { isGreaterThan10 } from "../../../utils/utilities/utilityFunctions";
import PageSizePopover from "../../../Components/PageSizePopover/PageSizePopover";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { nnAPIKey } from "../../../Context/ApiContext/ApiContext";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import PaginationComponent from "../../../Components/Pagination/PaginationComponent";

import { values } from "lodash";
import { AsyncPaginate } from "react-select-async-paginate";
const MembersContent = ({
  membersData,
  isUpdated,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchInput,
  handleInputChange,
  handleSearch,
  filters,
  setFilters,
  onFilterChange,
  TeamLoadOptions,
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const token = localStorage.getItem("token");
  const bearer = `Bearer ${token}`;
  const { getAllMembers, mainURL, userRoleCount, initialState } =
    useContext(ContextAPI);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Members",
      pageURL: "/members",
    },
  ];
  const roleOptions = [
    { label: "IT members", value: "it_member" },
    { label: "Operation Member", value: "operation_member" },
    { label: "Team Leaders", value: "team_leaders,members" },
    { label: "Team Sub Leader", value: "members,team_sub_leader" },
    { label: "Members", value: "members" },
  ];
  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no.",
      //  Cell: ({ row }) => row.index + 1,
      Cell: ({ row }) => row?.original?.sr_no,
    },
    {
      Header: "Member Code",
      accessor: "member_code",
      Cell: ({ row }) => row.original?.member_code || "N.A",
    },
    {
      Header: "Name",
      accessor: "member_name",
      Cell: ({ row }) => row.original?.member_name || "N.A",
    },
    {
      Header: "Email ID",
      accessor: "member_email",
    },
    {
      Header: "Postion",
      accessor: "member_role",
      Cell: ({ row }) => {
        return row.original.member_role === "it_member"
          ? "IT Member"
          : row.original.member_role === "operation_member"
          ? "Operation Member"
          : row.original.member_role === "team_leaders"
          ? "Team Leader"
          : row.original.member_role === "members"
          ? "Member"
          : row.original.member_role === "team_leaders,members"
          ? "Team Leader, Member"
          : row.original.member_role === "members,team_sub_leader"
          ? "Member, Sub Leader"
          : row.original.member_role === "team_sub_leader"
          ? "Sub Leader"
          : "";
      },
    },
    {
      Header: "Status",
      accessor: "current_status",
      Cell: ({ row }) => {
        return row.original.current_status === "active"
          ? "Active"
          : row.original.current_status === "inactive"
          ? "Suspended"
          : "";
      },
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-member-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-member-tooltip"
            data-tooltip-content="Edit Member Details"
            data-tooltip-place="top"
          >
            <EditMemberModal
              memberData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>

          <Tooltip
            id="delete-member-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-member-tooltip"
            data-tooltip-content="Delete Member"
            data-tooltip-place="top"
          >
            <DeleteMemberModal
              memberData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no",
    "Member Code",
    "Name",
    "Email ID",
    "Postion",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => membersData, [membersData]);
  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Member Code", key: "member_code" },
      { label: "Member Name", key: "member_name" },
      { label: "Email ID", key: "member_email" },
      { label: "Position", key: "member_role" },
      { label: "Registered on", key: "registered_on" },
      { label: "Status", key: "current_status" },
    ],
    fileName: "Members List",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance, isUpdated]);

  const membersCount = initialState.userRoleCount;

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setSearchInput(value);
  //   if (value.trim() === "") {
  //     // Reset to original members data if the search input is cleared
  //     setFilteredMembers(membersData);
  //     setSearchPerformed(false);
  //   }
  // };

  return (
    <>
      <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
        <div className="mr-40 ml-30 mb-15">
          <Breadcrumbs crumbs={breadCrumbs} />
        </div>

        <section className="main-content_header add-border-bottom">
          <div className="d-flex justify-content-center align-items-center page-heading">
            <p className="m-0 fs-4">Members</p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <div className="relative-wrapper">
              <img className="search-icon" src={searchIcon} alt="search-icon" />
              <input
                className="input-field"
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
            <button
              onClick={() => navigate("/members/add-member")}
              className="custom-btn d-flex justify-content-center align-items-center gap-2"
            >
              Add Member <span className="fw-light fs-4">+</span>
            </button>
          </div>
        </section>
        {/* <div className="mr-40 ml-30 mb-2 d-flex justify-content-start align-items-center gap-4 mt-5">
          <div className="relative-wrapper w-25 ">
            <img className="search-icon" src={reportsIcon} alt="search-icon" />

            <Select
              closeMenuOnSelect={true}
              isClearable={true}
              options={roleOptions}
              value={filters?.role || null}
              onChange={(option) => onFilterChange("role", option)}
              placeholder="Select role"
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <div className="relative-wrapper w-25">
          <img className="search-icon" src={teamsIcon} alt="search-icon" />
          <AsyncPaginate
            isClearable={true}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
            value={filters.team}
            loadOptions={TeamLoadOptions}
            onChange={(option) => onFilterChange("team", option)}
            additional={{
              page: 1,
            }}
            placeholder={`Select Team`}
            debounceTimeout={300}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? `No Team found for "${inputValue}"` : "No Team found"
            }
            onError={(error) => {
              ReactHotToast("Error loading Teams", "error");
              console.error("Async Paginate Error:", error);
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e0e0e0"
                  : "white",
                cursor: "pointer",
                color: state.isSelected ? "white" : "black",
                ":hover": {
                  backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>
        
        </div> */}
        <section className="mt-5 parent-count-wrapper">
          <div className="count-wrapper">
            <p className="count">{isGreaterThan10(membersCount?.it_member)}</p>
            <p className="role">IT members</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.operation_member)}
            </p>
            <p className="role">Operation members</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.team_leaders)}
            </p>
            <p className="role">Team Leaders</p>
          </div>
          <div className="count-wrapper">
            <p className="count">
              {isGreaterThan10(membersCount?.team_sub_leader)}
            </p>
            <p className="role">Team Sub-Leaders</p>
          </div>
          <div className="count-wrapper">
            <p className="count">{isGreaterThan10(membersCount?.members)}</p>
            <p className="role">Members</p>
          </div>
        </section>

        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : (
          <>
            <MembersTable
              columnHeaders={columnHeaders}
              tableInstance={tableInstance}
              headers={headers}
              membersData={membersData}
            />
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              dataList={membersData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default MembersContent;
