import React, { useContext, useEffect, useState } from "react";

import JobCategoryContent from "./Components/JobCategoryContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const JobCategory = () => {
  const { getAllJobCategories, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  // useEffect(() => {
  //   getAllJobCategories();
  // }, [isUpdated]);

  const [searchInput, setSearchInput] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const [searchTriggered, setSearchTriggered] = useState(false);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    // Check if `searchInput` is empty and call the API immediately.
    if (searchInput === "" && !searchTriggered) {
      getAllJobCategories(currentPage, pageSize, "");
      return; // Exit the useEffect early to skip the setTimeout.
    }

    // Otherwise, use the setTimeout for delayed API calls.
    const timer = setTimeout(() => {
      if (searchTriggered && searchInput !== "") {
        getAllJobCategories(currentPage, pageSize, searchInput);
      }
    }, 500);

    // Cleanup the timer to avoid memory leaks.
    return () => clearTimeout(timer);
  }, [currentPage, pageSize, searchInput, searchTriggered, isUpdated]);

  return (
    <JobCategoryContent
      jobCategories={initialState?.jobCategories}
      isLoading={initialStateLoading?.getAllJobCategoriesIsLoading}
      setIsUpdated={setIsUpdated}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
    />
  );
};

export default JobCategory;
