// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import {
//   EditSVG,
//   projectsIcon,
// } from "../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../utils/utilities/utilityFunctions";
// const animatedComponents = makeAnimated();
// const statusOptions = [
//   { label: "Unpaid", value: "1" },
//   { label: "Partially Paid", value: "2" },
//   { label: "Paid", value: "3" },
// ];
// const taxOptions = [
//   { label: "VAT 5%", value: "5" },
//   { label: "VAT 10%", value: "10" },
//   { label: "VAT 15%", value: "15" },
//   { label: "VAT 20%", value: "20" },
//   { label: "VAT 25%", value: "25" },
//   { label: "VAT 30%", value: "30" },
//   { label: "VAT 35%", value: "35" },
//   { label: "VAT 40%", value: "40" },
// ];
// const MyVerticallyCenteredModal = (props) => {
//   const { mainURL, logout, userDetails } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);
//   const [jobStatus, setJobStatus] = useState("");
//   const [partialAmount, setPartialAmount] = useState("");
//   const [advanceJobDetails, setAdvanceJobDetails] = useState({
//     jobDescription: props.jobDescription,
//     jobName:props.jobName,
//     tax: null,
//   });
// console.log("props",props)
//   useEffect(() => {
//     const status = statusOptions.find(({ label }) => label === props?.status);
//     setJobStatus(() => status);
//   }, [props?.job_status]);

//   const updateAdvanceJob = async () => {
//     const vatValue = advanceJobDetails.tax
//       ? Number(advanceJobDetails.tax.value)
//       : null;
//     const body = {
//       current_user: localStorage.getItem("userId") || userDetails?.member_id,
//       invoice_id: props.id,
//       team_id: props.teamId,
//       status_id: jobStatus?.value,
//       job_description: advanceJobDetails.jobDescription,
//       vat_percent: vatValue,
//       partial_paid_amount: jobStatus?.value === "2" ? partialAmount : "",
//     };

//     const url = `${mainURL}update/invoice-status/advance-billing`;

//     setIsLoading(() => true);

//     try {
//       const result = await axios.put(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");
//         props.setIsUpdated((prev) => !prev);
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//       props.onHide();
//     }
//   };

//   const handleUpdateJob = (e) => {
//     e.preventDefault();

//     if (jobStatus?.value) {
//       updateAdvanceJob();
//     } else {
//       if (!jobStatus?.value) {
//         ReactHotToast("Please select status", "error");
//       }
//     }
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Update Advance Billing Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleUpdateJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobName">Job Name:</label>
//             <input
//               id="assignJobName"
//               name="assignJobName"
//               placeholder="jobName"
//               type="text"
//               value={advanceJobDetails.jobName}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobName: e.target.value,
//                 }))
//               }

//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobDescription">Job Description:</label>
//             <textarea
//               id="jobDescription"
//               rows={3}
//               placeholder="Enter job description"
//               value={advanceJobDetails.jobDescription}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobDescription: e.target.value,
//                 }))
//               }
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label>Tax:</label>
//             <Select
//               closeMenuOnSelect={true}
//               components={animatedComponents}
//               value={advanceJobDetails.tax}
//               onChange={(option) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   tax: option || null,
//                 }))
//               }
//               options={taxOptions}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobStatus">Status:</label>
//             <Select
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//               isClearable={false}
//               isSearchable={true}
//               name="assignJobStatus"
//               value={jobStatus}
//               onChange={(option) => setJobStatus(option)}
//               options={statusOptions}
//             />
//           </div>
//           {jobStatus?.value === "2" && (
//             <div className="form-group mt-3 w-100">
//               <label htmlFor="partialPaidAmount">Partial Paid Amount:</label>
//               <input
//                 id="partialPaidAmount"
//                 type="number"
//                 value={partialAmount}
//                 onChange={(e) => setPartialAmount(e.target.value)}
//                 placeholder="Enter amount"
//               />
//             </div>
//           )}
//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Update"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const UpdateAdvanceBillingJobInvoiceModal = ({
//   id,
//   status,
//   teamId,
//   setIsUpdated,
//   jobName,
//   jobDescription,
// }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <div onClick={() => setModalShow(true)}>
//         <EditSVG />
//       </div>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         status={status}
//         id={id}
//         jobName={jobName}
//         jobDescription={jobDescription}
//         teamId={teamId}
//         setIsUpdated={setIsUpdated}
//       />
//     </>
//   );
// };
// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import {
//   EditSVG,
//   projectsIcon,
// } from "../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../utils/utilities/utilityFunctions";
// const animatedComponents = makeAnimated();
// const statusOptions = [
//   { label: "Unpaid", value: "1" },
//   { label: "Partially Paid", value: "2" },
//   { label: "Paid", value: "3" },
// ];
// const taxOptions = [
//   { label: "VAT 5%", value: "5" },
//   { label: "VAT 10%", value: "10" },
//   { label: "VAT 15%", value: "15" },
//   { label: "VAT 20%", value: "20" },
//   { label: "VAT 25%", value: "25" },
//   { label: "VAT 30%", value: "30" },
//   { label: "VAT 35%", value: "35" },
//   { label: "VAT 40%", value: "40" },
// ];
// const MyVerticallyCenteredModal = (props) => {
//   const { mainURL, logout, userDetails,getAllAdvanceBillingJobsPagination } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);
//   const [jobStatus, setJobStatus] = useState("");
//   const [partialAmount, setPartialAmount] = useState("");
//   const [advanceJobDetails, setAdvanceJobDetails] = useState({
//     jobDescription: props.jobDescription,
//     jobName:props.jobName,
//     tax: null,
//   });
// console.log("props",props)
// // useEffect(()=>{
// //   getAllAdvanceBillingJobsPagination()
// // },[])
//   useEffect(() => {
//     const status = statusOptions.find(({ label }) => label === props?.status);
//     setJobStatus(() => status);
//   }, [props?.job_status]);

//   const updateAdvanceJob = async () => {
//     const vatValue = advanceJobDetails.tax
//       ? Number(advanceJobDetails.tax.value)
//       : null;
//     const body = {
//       current_user: localStorage.getItem("userId") || userDetails?.member_id,
//       invoice_id: props.id,
//       team_id: props.teamId,
//       status_id: jobStatus?.value,
//       job_description: advanceJobDetails.jobDescription,
//       vat_percent: vatValue,
//       partial_paid_amount: jobStatus?.value === "2" ? partialAmount : "",
//     };

//     const url = `${mainURL}update/invoice-status/advance-billing`;

//     setIsLoading(() => true);

//     try {
//       const result = await axios.put(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");
//         // props.setIsUpdated((prev) => !prev);
//         getAllAdvanceBillingJobsPagination()
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//       props.onHide();
//     }
//   };

//   const handleUpdateJob = (e) => {
//     e.preventDefault();

//     if (jobStatus?.value) {
//       updateAdvanceJob();
//     } else {
//       if (!jobStatus?.value) {
//         ReactHotToast("Please select status", "error");
//       }
//     }
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Update Advance Billing Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleUpdateJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobName">Job Name:</label>
//             <input
//               id="assignJobName"
//               name="assignJobName"
//               placeholder="jobName"
//               type="text"
//               value={advanceJobDetails.jobName}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobName: e.target.value,
//                 }))
//               }

//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobDescription">Job Description:</label>
//             <textarea
//               id="jobDescription"
//               rows={3}
//               placeholder="Enter job description"
//               value={advanceJobDetails.jobDescription}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobDescription: e.target.value,
//                 }))
//               }
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label>Tax:</label>
//             <Select
//               closeMenuOnSelect={true}
//               components={animatedComponents}
//               value={advanceJobDetails.tax}
//               onChange={(option) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   tax: option || null,
//                 }))
//               }
//               options={taxOptions}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobStatus">Status:</label>
//             <Select
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//               isClearable={false}
//               isSearchable={true}
//               name="assignJobStatus"
//               value={jobStatus}
//               onChange={(option) => setJobStatus(option)}
//               options={statusOptions}
//             />
//           </div>
//           {jobStatus?.value === "2" && (
//             <div className="form-group mt-3 w-100">
//               <label htmlFor="partialPaidAmount">Partial Paid Amount:</label>
//               <input
//                 id="partialPaidAmount"
//                 type="number"
//                 value={partialAmount}
//                 onChange={(e) => setPartialAmount(e.target.value)}
//                 placeholder="Enter amount"
//               />
//             </div>
//           )}
//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Update"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const UpdateAdvanceBillingJobInvoiceModal = ({

//   id,
//   status,
//   teamId,
//   setIsUpdated,
//   jobName,
//   jobDescription,onUpdate,partialPaid,vat
// }) => {
//   const [modalShow, setModalShow] = useState(false);
//   console.log("Received props in modal:", { jobName,vat, jobDescription });
//   return (
//     <>
//       <div onClick={() => setModalShow(true)}>
//         <EditSVG />
//       </div>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         status={status}
//         id={id}
//         jobName={jobName}
//         jobDescription={jobDescription}
//         teamId={teamId}
//         vat={vat}
//         partialPaid={partialPaid}
//         setIsUpdated={setIsUpdated}
//         onUpdate={onUpdate}
//       />
//     </>
//   );
// };
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  EditSVG,
  projectsIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import {
  headerOptions,
  handleAPIError,
} from "../../../utils/utilities/utilityFunctions";

const animatedComponents = makeAnimated();

const statusOptions = [
  { label: "Unpaid", value: "1" },
  { label: "Partially Paid", value: "2" },
  { label: "Paid", value: "3" },
];

const taxOptions = [
  { label: "VAT 5%", value: "5" },
  { label: "VAT 10%", value: "10" },
  { label: "VAT 15%", value: "15" },
  { label: "VAT 20%", value: "20" },
  { label: "VAT 25%", value: "25" },
  { label: "VAT 30%", value: "30" },
  { label: "VAT 35%", value: "35" },
 
];

const MyVerticallyCenteredModal = (props) => {
  const {
    mainURL,
    logout,
    userDetails,
    getAllAdHocClients,
    getSingleAdvanceBillingInvoice,
  } = useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(""); 
  const [partialAmount, setPartialAmount] = useState("");
  const [advanceJobDetails, setAdvanceJobDetails] = useState({
    jobDescription: props.jobDescription,
    jobName: props.jobName,
    editJobDescription: props.editJobDescription,
    editJobName: props.editJobName,
    status: props.status,
    amount: props.amount,
    tax: props.vat,
    partialPaid: props.partialPaid,
  });

  useEffect(() => {
    const status = statusOptions.find(
      ({ value }) => String(value) === String(props.status)
    );
    setStatus(status || null);
  }, [props.status]);
  useEffect(() => {
    setAdvanceJobDetails((prev) => ({
      ...prev,
      jobName: props.jobName,
      jobDescription: props.jobDescription,
      editJobDescription: props.editJobDescription,
      editJobName: props.editJobName,
      amount: props.amount,
      partialPaid: props.partialPaid,
      tax: props.vat
        ? taxOptions.find((option) => option.value === String(props.vat))
        : null,
    }));
  }, [
    props.jobName,
    props.jobDescription,
    props.amount,
    props.vat,
    props.partialPaid,
    props.jobDescription,
    props.editJobDescription,
  ]);

  const handleStatusChange = (option) => {
    setStatus(option);
  };

  const handleTaxChange = (option) => {
    setAdvanceJobDetails((prev) => ({ ...prev, tax: option || null }));
  };

  const updateAdvanceJob = async () => {
    const vatValue = advanceJobDetails.tax
      ? Number(advanceJobDetails.tax.value)
      : null;

    // let paymentStatus = null;

    // if (status?.value === "2") {
    //   paymentStatus = partialAmount || "";
    // } else if (status?.value === "1") {
    //   paymentStatus = null;
    // } else if (status?.value === "3") {
    //   paymentStatus = null;
    // }

    const body = {
      current_user: localStorage.getItem("userId") || userDetails?.member_id,
      invoice_id: props.id,
      status_id: status?.value,
      team_id: props.teamId,
      job_name: advanceJobDetails.jobName,
      job_description: advanceJobDetails.jobDescription,
      edit_job_description: advanceJobDetails.editJobDescription,
      edit_job_name: advanceJobDetails.editJobName,
      amount: advanceJobDetails.amount,
      vat_percent: vatValue,
      payment_status: status?.value,
      partial_paid_amount: advanceJobDetails.partialPaid,
    };

    const url = `${mainURL}update/invoice-status/advance-billing`;

    setIsLoading(true);

    try {
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        getSingleAdvanceBillingInvoice(props.id);
        props.onUpdate();
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(false);
      props.onHide();
    }
  };

  const handleUpdateJob = (e) => {
    e.preventDefault();
    if (status?.value) {
      updateAdvanceJob();
    } else {
      ReactHotToast("Please select status", "error");
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Update Advance Billing Invoice</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdateJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label htmlFor="ServiceName">Job Name:</label>
            <input
              id="jobName"
              name="jobName"
              placeholder="Job Name"
              type="text"
              value={advanceJobDetails.jobName}
              disabled
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  jobName: e.target.value,
                }))
              }
            />
          </div>
          {/* <div className="form-group mt-3 w-100">
            <label htmlFor="EditJobName">New Job Name:</label>
            <input
              id="editJobName"
              name="editJobName"
              placeholder="Job Name"
              type="text"
              value={advanceJobDetails.editJobName}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  editJobName: e.target.value,
                }))
              }
            />
          </div> */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Description:</label>
            <textarea
              id="jobDescription"
              rows={3}
              placeholder="Enter job description"
              value={advanceJobDetails.jobDescription}
              disabled
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  jobDescription: e.target.value,
                }))
              }
            />
          </div>
          {/* <div className="form-group mt-3 w-100">
            <label htmlFor="newJobDescription">New Job Description:</label>
            <textarea
              id="newJobDescription"
              rows={3}
              placeholder="Enter new job description"
              value={advanceJobDetails.editJobDescription}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  editJobDescription: e.target.value,
                }))
              }
            />
          </div> */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="amount">Amount:</label>
            <input
              id="amount"
              name="amount"
              placeholder="Amount"
              type="number"
              value={advanceJobDetails.amount}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label>Tax:</label>
            <Select
              closeMenuOnSelect
              components={animatedComponents}
              value={advanceJobDetails.tax}
              onChange={handleTaxChange}
              options={taxOptions}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label>Payment Status</label>
            <Select
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable={false}
              isSearchable={true}
              name="status"
              value={status}
              onChange={handleStatusChange}
              options={statusOptions}
            />
          </div>
          {status?.value === "2" && (
            <div className="form-group mt-3 w-100">
              <label htmlFor="partialPaidAmount">Partial Paid Amount:</label>
              <input
                id="partialPaidAmount"
                type="number"
                value={advanceJobDetails.partialPaid}
                onChange={(e) =>
                  setAdvanceJobDetails((prev) => ({
                    ...prev,
                    partialPaid: e.target.value,
                  }))
                }
                placeholder="Enter amount"
              />
            </div>
          )}
          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const UpdateAdvanceBillingJobInvoiceModal = ({
  id,
  status,
  teamId,
  jobName,
  amount,
  jobDescription,
  vat,
  partialPaid,
  onUpdate,
  editJobName,
  editJobDescription,
}) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <EditSVG />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        status={status}
        id={id}
        amount={amount}
        jobName={jobName}
        jobDescription={jobDescription}
        editJobDescription={editJobDescription}
        editJobName={editJobName}
        teamId={teamId}
        vat={vat}
        partialPaid={partialPaid}
        onUpdate={onUpdate}
      />
    </>
  );
};
