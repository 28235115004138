import React from "react";

const PaginationComponent = ({
  currentPage,
  totalPages,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  fetchedTimeEntriesList,
  text,
}) => {
  return (
    <div
      className="d-flex justfy-content-center align-items-center gap-3 smallText"
      style={{
        width: "45%",
        justifyContent:
          text === "jobCategory" ||
          // text === "adHocBilling" ||
          text === "billingServices" ||
          text === "membersLogAdmin"
            ? ""
            : "space-between",
        position: "absolute",
        marginLeft: "90px",
        bottom: "48px",
      }}
    >
      <div className="d-flex gap-1 align-items-center">
        <button
          className="cursor-pointer"
          style={{ border: "none", background: "transparent" }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
            />
          </svg>
        </button>
        <input
          className="gotoPage-input-field"
          type="number"
          value={currentPage}
          readOnly
        />
        <span className="px-2">/</span>
        <span>{totalPages}</span>
        <button
          style={{ border: "none", background: "transparent" }}
          className="cursor-pointer"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            fetchedTimeEntriesList?.length < pageSize ||
            totalPages === 1 ||
            currentPage === totalPages
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
            />
          </svg>
        </button>
      </div>
      <div
        className="rows-per-page d-flex align-items-center gap-3"
        style={{
          fontSize: "14px",
          color: "#555",
          marginLeft:
            text === "jobCategory" ||
            // text === "adHocBilling" ||
            text === "billingServices" ||
            text === "membersLogAdmin"
              ? "175px"
              : "",
        }}
      >
        <span className="primary-font-color" style={{ fontWeight: "500" }}>
          Rows per page:
        </span>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "60px",
          }}
        >
          <select
            className="primary-font-color"
            value={pageSize}
            onChange={handlePageSizeChange}
            style={{
              width: "100%",
              border: "1px solid#50788d",
              borderRadius: "8px",
              padding: "6px 10px",
              background: "#fff",
              appearance: "none",
              cursor: "pointer",
              outline: "none",
              transition: "border-color 0.2s, box-shadow 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.borderColor = "#999")}
            onMouseLeave={(e) => (e.target.style.borderColor = "#ccc")}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              color: "#50788d",
              fontSize: "12px",
            }}
          >
            ▼
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;






// const PaginationComponent = ({
//   currentPage,
//   totalPages,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   fetchedTimeEntriesList,
//   text,
// }) => {

//   const createPageNumbers = () => {
//     const pages = [];
//     let startPage = Math.max(1, currentPage - 2); // Calculate the start of the page range
//     let endPage = Math.min(totalPages, currentPage + 2); // Calculate the end of the page range
  
//     // First page conditionally displayed
//     if (currentPage > 3) pages.push(1); // If the current page is far from the first page, show it
//     if (startPage > 1) pages.push('...'); // If there's a gap before the start page, show an ellipsis
  
//     // Add pages from startPage to endPage
//     for (let i = startPage; i <= endPage; i++) {
//       if (!pages.includes(i)) { // Ensure no duplicate page numbers
//         pages.push(i);
//       }
//     }
  
//     // Ellipsis for skipped pages at the end
//     if (currentPage < totalPages - 2) pages.push('...'); // If there's a gap after the last page, show ellipsis
  
//     // Last page conditionally displayed
//     if (currentPage < totalPages - 1 && !pages.includes(totalPages)) {
//       pages.push(totalPages); 
//     }
  
//     return pages;
//   };
  
  
  
//     return (
//     <div
//       className="d-flex justfy-content-center align-items-center gap-3 smallText"
//       style={{
//         width: "45%",
//         justifyContent:
//           text === "jobCategory" ||
//           // text === "adHocBilling" ||
//           text === "billingServices" ||
//           text === "membersLogAdmin"
//             ? ""
//             : "space-between",
//         position: "absolute",
//         marginLeft: "90px",
//         bottom: "48px",
//       }}
//     >
//       <div className="d-flex gap-1 align-items-center">
        
//         <button
//         onClick={() => handlePageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         style={{
//           border: "none",
//           background: "transparent",
//           cursor: currentPage === 1 ? "not-allowed" : "pointer",
//         }}
//       >
//         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
//           <path fill="currentColor" d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z" />
//         </svg>
//       </button>
//         {createPageNumbers().map((page, index) => (
//         <button
//           key={index}
//           onClick={() => handlePageChange(page)}
//           disabled={page === '...' || page === currentPage} 
//           style={{
//             backgroundColor: page === currentPage ? "#319cd1" : "transparent",
//             // border: `1px solid ${page === currentPage ? "#319cd1" : "#00263d"}`,
//             border:'none',
//             color: page === currentPage ? "#fff" : "#00263d",
//             fontSize: "14px",
//             cursor: page === '...' || page === currentPage ? "not-allowed" : "pointer",
//             borderRadius: "5px",
//             opacity: page === '...' || page === currentPage ? 0.5 : 1,
//             fontWeight: "400",
//             textAlign: "center",
//             minWidth: "32px",
//             height: "32px",
//             lineHeight: "32px",
//             display: "inline-block",
//             transition: "all 0.3s ease",
//           }}
         
//         >
//           {page}
//         </button>
//       ))}
//         {/* <input
//           className="gotoPage-input-field"
//           type="number"
//           value={currentPage}
//           readOnly
//         />
//         <span className="px-2">/</span>
//         <span>{totalPages}</span> */}
//         {/* <button
//           style={{ border: "none", background: "transparent" }}
//           className="cursor-pointer"
//           onClick={() => handlePageChange(currentPage + 1)}
//           disabled={
//             fetchedTimeEntriesList?.length < pageSize ||
//             totalPages === 1 ||
//             currentPage === totalPages
//           }
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//           >
//             <path
//               fill="currentColor"
//               d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
//             />
//           </svg>
//         </button> */}
//         <button
//         onClick={() => handlePageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         style={{
         
//           border: "none",
//           background: "transparent",
//           cursor: currentPage === totalPages ? "not-allowed" : "pointer",
//         }}
//       >
//         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
//           <path fill="currentColor" d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z" />
//         </svg>
//       </button>
//       </div>
//       <div
//         className="rows-per-page d-flex align-items-center gap-3"
//         style={{
//           fontSize: "14px",
//           color: "#555",
//           marginLeft:
//             text === "jobCategory" ||
//             // text === "adHocBilling" ||
//             text === "billingServices" ||
//             text === "membersLogAdmin"
//               ? "175px"
//               : "",
//         }}
//       >
//         <span className="primary-font-color" style={{ fontWeight: "500" }}>
//           Rows per page:
//         </span>
//         <div
//           style={{
//             position: "relative",
//             display: "inline-block",
//             width: "60px",
//           }}
//         >
//           <select
//             className="primary-font-color"
//             value={pageSize}
//             onChange={handlePageSizeChange}
//             style={{
//               width: "100%",
//               border: "1px solid#50788d",
//               borderRadius: "8px",
//               padding: "6px 10px",
//               background: "#fff",
//               appearance: "none",
//               cursor: "pointer",
//               outline: "none",
//               transition: "border-color 0.2s, box-shadow 0.2s",
//             }}
//             onMouseEnter={(e) => (e.target.style.borderColor = "#999")}
//             onMouseLeave={(e) => (e.target.style.borderColor = "#ccc")}
//           >
//             <option value={10}>10</option>
//             <option value={25}>25</option>
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//             <option value={150}>150</option>
     
//           </select>
//           <span
//             style={{
//               position: "absolute",
//               top: "50%",
//               right: "10px",
//               transform: "translateY(-50%)",
//               pointerEvents: "none",
//               color: "#50788d",
//               fontSize: "12px",
//             }}
//           >
//             ▼
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaginationComponent;