import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { json, useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import {
  searchIcon,
  calendarIcon,
  reportsIcon,
  projectsIcon,
  InvoiceIcon,
  employeeIcon,
  usersIcon,
  teamsIcon,
  DownSVG,
  DownArrow,
  DownloadSVG,
} from "../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../templates/PageHeader";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { formatDate } from "../../../utils/utilities/utilityFunctions";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import MemberListTable from "./MemberListTable";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import axios from "axios";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { MemberTimeEntriesModal } from "./MemberTimeEntriesModal";
import { debounce } from "lodash";
import PaginationComponent from "../../../Components/Pagination/PaginationComponent";
import { AsyncPaginate } from "react-select-async-paginate";

const MemberList = ({
  setIsUpdated,
  isLoading,
  allMemberReports,
  currentPage,
  setCurrentPage,
  pageSize,
  isUpdated,
  setPageSize,
  memberReportData,
  handleRangeChange,
  selectedEndDate,
  formattedStartDate,
  formattedEndDate,
  setSelectedEndDate,
  setSelectedStartDate,
  setDates,
  selectedStartDate,
  totalPages,
  handleInputChange,
  searchInput,
  onFilterChange,
  TeamLoadOptions,
  filters,
  handleTabChange,
  activeTab,
}) => {
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState([]);
  const { mainURL, userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const userRole = localStorage.getItem("userRole");
  const startDateRef = useRef(formattedStartDate);
  const endDateRef = useRef(formattedEndDate);
  const [selectedIds, setSelectedIds] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectAllForPage = (page) => {
    const isAllSelected = selectedIds[page]?.length === memberReportData.length;

    if (isAllSelected) {
      setSelectedIds((prevSelectedIds) => ({
        ...prevSelectedIds,
        [page]: [],
      }));
      setCheckAll(false);
    } else {
      setSelectedIds((prevSelectedIds) => ({
        ...prevSelectedIds,
        [page]: memberReportData.map((member) => member.user_id),
      }));
      setCheckAll(true);
    }
  };

  const handleCheckboxChange = (userId, page) => {
    setSelectedIds((prevSelectedIds) => {
      const newSelectedIds = prevSelectedIds[page]?.includes(userId)
        ? prevSelectedIds[page].filter((id) => id !== userId)
        : [...(prevSelectedIds[page] || []), userId];

      const allSelected = newSelectedIds.length === memberReportData.length;
      setCheckAll(allSelected);

      return {
        ...prevSelectedIds,
        [page]: newSelectedIds,
      };
    });
  };

  const handleDownloadWithSelectedIds = async () => {
    const selectedUserIds = Object.values(selectedIds).flat();
    const selectedStartDate = startDateRef.current;
    const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");

    const memberName =
      memberReportData.find((member) =>
        selectedUserIds.includes(member.user_id)
      )?.name || "Report";

    const userIds = selectedUserIds.join(",");
    const payload = {
      user_ids: userIds,
      date: formattedStartDate,
    };

    const url = `${mainURL}export/member-time-entries`;

    try {
      setLoading(true);
      const responseType = selectedUserIds.length > 1 ? "blob" : "arraybuffer";

      const response = await axios.post(url, payload, {
        headers: headerOptions(),
        responseType: responseType,
      });

      if (response?.data?.error) {
        ReactHotToast(response?.data?.message, "error");
        return;
      }

      if (selectedUserIds.length > 1) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `members_reports_${formattedStartDate}.pdf`;
        link.click();
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${memberName}_${formattedStartDate}report.pdf`;
        link.click();
        window.URL.revokeObjectURL(pdfUrl);
      }

      setSelectedIds({});
      setCheckAll(false);
      onFilterChange("showAvailableTimeEntries", false);
      setCurrentPage(1);
    } catch (error) {
      ReactHotToast("An error occurred while fetching the file.", "error");
      console.error("Download error:", error);
    } finally {
      setLoading(false);
    }
  };

  const hasSelectedIds = Object.values(selectedIds).some(
    (ids) => ids.length > 0
  );
  useEffect(() => {
    setCheckAll(selectedIds[currentPage]?.length === memberReportData.length);
  }, [currentPage, selectedIds, memberReportData]);

  useEffect(() => {
    startDateRef.current = formattedStartDate;
    endDateRef.current = formattedEndDate;
  }, [formattedStartDate, formattedEndDate]);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    // {
    //   pageName: "Reports",
    //   pageURL: "/reports",
    // },
    {
      pageName: "Member List",
      //   pageURL: "/assign-job",
    },
  ];

  const [activeMember, setActiveMember] = useState([]);
  const [inactiveMember, setInactiveMember] = useState([]);
  // const filteredJobs = useMemo(() => {
  //   if (activeTab === "active") {
  //     return memberReportData.filter(job => filters?.status?.value
  //       ? job.job_status === filters.status.value
  //       : true);
  //   } else if (activeTab === "inactive") {
  //     return memberReportData.filter(job => filters?.status?.value
  //       ? job.job_status === filters.status.value
  //       : true);
  //   }
  //   return [];
  // }, [activeTab, filters, memberReportData]);

  const filteredMember = useMemo(() => {
    if (Array.isArray(memberReportData)) {
      if (activeTab === "member") {
        return filters?.status?.value
          ? memberReportData.filter(
              (job) => job.job_status === filters.status.value
            )
          : memberReportData;
      } else if (activeTab === "inactive") {
        return filters?.status?.value
          ? memberReportData.filter(
              (job) => job.job_status === filters.status.value
            )
          : memberReportData;
      }
    }
    return [];
  }, [activeTab, filters, memberReportData]);

  useEffect(() => {
    if (Array.isArray(memberReportData)) {
      if (activeTab === "member") {
        setInactiveMember([]);
        setActiveMember(filteredMember);
      } else if (activeTab === "inactive") {
        setActiveMember([]);
        setInactiveMember(filteredMember);
      }
    }
  }, [activeTab, filteredMember, memberReportData]);

  const tableColumns = [
    {
      Header: () => (
        <input
          type="checkbox"
          checked={checkAll}
          onChange={() => handleSelectAllForPage(currentPage)}
          className="cursor-pointer checkbox-input"
        />
      ),
      id: "select",
      accessor: "select",
      Cell: ({ row }) => {
        const userId = row.original?.user_id;
        return (
          <input
            type="checkbox"
            checked={selectedIds[currentPage]?.includes(userId)}
            onChange={() => handleCheckboxChange(userId, currentPage)}
            name={userId}
            className="cursor-pointer checkbox-input"
          />
        );
      },
    },

    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "User role",
      accessor: "user_role",
      Cell: ({ row }) => {
        const rolesMap = {
          "team_leaders,members": "Team Leader, Member",
          members: "Member",
          "members,team_sub_leader": "Member, Team Sub Leader",
          team_leaders: "Team Leader",
        };
        const userRole = row.original.user_role;
        return userRole ? rolesMap[userRole] || userRole : null;
      },
    },
    {
      Header: "Team Names",
      accessor: "team_names",
    },

    {
      Header: "Billable Hours",
      accessor: "total_billable_hours",
      Cell: ({ row }) => (
        <span
          style={{ color: row.original.total_billable_hours ? "red" : "black" }}
        >
          {row.original.total_billable_hours || (
            <span style={{ color: "grey" }}> {"0"} </span>
          )}
        </span>
      ),
    },
    {
      Header: "Side Work Hours",
      accessor: "total_side_works_hours",
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original.total_side_works_hours ? "red" : "black",
          }}
        >
          {row.original.total_side_works_hours || (
            <span style={{ color: "grey" }}> {"0"} </span>
          )}
        </span>
      ),
    },
    {
      Header: "Advance Work Hours",
      accessor: "total_advance_hours",
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original.total_advance_hours ? "red" : "black",
          }}
        >
          {row.original.total_advance_hours || (
            <span style={{ color: "grey" }}> {"0"} </span>
          )}
        </span>
      ),
    },
    // ...(userRole !== "it_member" && userRole !== "operation_member"
    //   ? [
    {
      id: "view",
      Header: "View",

      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <Tooltip
            id="time-entries-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="time-entries-tooltip"
            data-tooltip-content="Check Time Entries"
            data-tooltip-place="top"
          >
            <MemberTimeEntriesModal
              memberUserId={row?.original?.user_id}
              memberName={row?.original?.name}
              teamName={row?.original?.team_names}
            />
          </div>
        </div>
      ),
    },
    {
      id: "pdf",
      Header: "PDF",

      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex  align-items-center">
          <Tooltip
            id="pdf-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="pdf-tooltip"
            data-tooltip-content="Download PDF"
            data-tooltip-place="top"
            onClick={() => {
              // const selectedStartDate = startDateRef.current;
              // const selectedEndDate = endDateRef.current;

              handleDownload(
                row,
                tableInstance.state.pageIndex + 1,
                tableInstance.state.pageSize

                // selectedStartDate,
                // selectedEndDate
              );
            }}
          >
            <DownloadSVG />
          </div>
        </div>
      ),
    },
    //   ]
    // : []),
  ];

  const columnHeaders = [
    "Sr no",
    "Name",
    "Email",
    "User role",
    "Team Names",
    "Billable Hours",
    "Side Work Hours",
    "Advance Work Hours",
    "View",
    "PDF",
  ];
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const columns = useMemo(() => tableColumns, [selectedIds, memberReportData]);

  const data = useMemo(() => memberReportData, [memberReportData]);
  const tableInstance = useTable(
    {
      columns,
      data: filteredMember,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const headers = {
    headings: [
      { label: "Name", key: "name" },
      // { label: "Email", key: "email" },
      { label: "User Role", key: "user_role" },
      { label: "Team Name", key: "team_names" },
      { label: "Billable hours", key: "total_billable_hours" },
      { label: "Side Work hours", key: "total_side_works_hours" },
      { label: "Advance Work hours", key: "total_advance_hours" },
      { label: "Total Working hours", key: "total_working_hours" },
      { label: "Leaves", key: "Leaves" },
      { label: "Remark", key: "remark" },
    ],
    fileName: "Member List",
  };
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    const startDate = dayjs(formattedStartDate);
    const endDate = dayjs(formattedEndDate);

    // Check if dates are valid
    if (!startDate.isValid() || !endDate.isValid()) {
      console.error("Invalid start or end date");
      return;
    }

    const daysDifference = endDate.diff(startDate, "day");
    const daysIncludingBoth = daysDifference + 1;
    setTotalDays(daysIncludingBoth);

    // console.log("Total Days:", totalDays);
  }, [formattedStartDate, formattedEndDate]);

  const hoursPerDay = 7.5;

  // Function to convert time from "hr min" to total minutes
  const convertToMinutes = (timeString) => {
    let hours = 0;
    let minutes = 0;

    if (timeString && typeof timeString === "string") {
      // Regular expressions to match hours and minutes
      const hourMatch = timeString.match(/(\d+)\s*hr/);
      const minuteMatch = timeString.match(/(\d+)\s*min/);

      if (hourMatch) {
        hours = parseInt(hourMatch[1], 10);
      }

      if (minuteMatch) {
        minutes = parseInt(minuteMatch[1], 10);
      }
    }

    return hours * 60 + minutes; // Convert to total minutes
  };

  // Function to convert total minutes back to hours and minutes format
  const convertToHourMinuteFormat = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hr ${minutes} min`; // Return formatted string
  };

  // Function to calculate the difference between total working minutes and expected minutes
  const calculateDifference = (totalCombinedMinutes, totalDays) => {
    // Calculate expected minutes for the given days (7.5 hours per day)
    const expectedTotalMinutes = hoursPerDay * 60 * totalDays;

    // Check if totalDays and hoursPerDay are valid
    if (isNaN(expectedTotalMinutes) || isNaN(totalCombinedMinutes)) {
      console.error("Invalid values for totalCombinedMinutes or totalDays");
      return { difference: NaN, differenceFormatted: "Invalid data" };
    }

    // Calculate the difference
    const difference = totalCombinedMinutes - expectedTotalMinutes;
    // console.log("expectedTotalMinutes:", expectedTotalMinutes);
    // Convert the difference back to hours and minutes format
    const differenceFormatted = convertToHourMinuteFormat(Math.abs(difference));

    return {
      difference,
      differenceFormatted,
    };
  };

  // Main function to transform data and calculate the total hours and the difference
  const transformDataForCSV = (memberData, totalDays) => {
    let totalBillableMinutes = 0;
    let totalSideWorkMinutes = 0;
    let totalAdvanceWorkMinutes = 0;
    const memberCount = memberData.length;

    const transformedData = memberData.map((member) => {
      // Convert the work hours to minutes for each member
      const billableMinutes = convertToMinutes(
        member?.total_billable_hours || "0"
      );
      const sideWorkMinutes = convertToMinutes(
        member?.total_side_works_hours || "0"
      );
      const advanceWorkMinutes = convertToMinutes(
        member?.total_advance_hours || "0"
      );

      // console.log(`Billable Minutes: ${billableMinutes}`);
      // console.log(`Side Work Minutes: ${sideWorkMinutes}`);
      // console.log(`Advance Work Minutes: ${advanceWorkMinutes}`);

      // Add the minutes to the totals
      totalBillableMinutes += billableMinutes;
      totalSideWorkMinutes += sideWorkMinutes;
      totalAdvanceWorkMinutes += advanceWorkMinutes;

      // Check if all hours are zero and set the remark accordingly
      const remark =
        billableMinutes === 0 &&
        sideWorkMinutes === 0 &&
        advanceWorkMinutes === 0
          ? "Time sheet not filed"
          : "";

      return {
        name: member?.name,
        user_role: member?.user_role,
        team_names: `${(member?.team_names || "").replace(/['"]/g, " ")}`,
        total_billable_hours: member?.total_billable_hours || "0",
        total_side_works_hours: member?.total_side_works_hours || "0",
        total_advance_hours: member?.total_advance_hours || "0",
        total_working_hours: member?.total_working_hours || "0",
        remark: remark,
      };
    });

    // Calculate the total combined minutes from all members
    const totalCombinedMinutes =
      totalBillableMinutes + totalSideWorkMinutes + totalAdvanceWorkMinutes;
    // console.log("Total Combined Minutes:", totalCombinedMinutes);

    // if (isNaN(totalCombinedMinutes)) {
    //   console.error("Error: Total combined minutes is NaN");
    //   return transformedData; // If there's an error, return the transformed data without further processing
    // }

    // Calculate the total difference for the given totalDays
    // const { difference, differenceFormatted } = calculateDifference(totalCombinedMinutes, totalDays);
    // console.log("Difference Formatted:", differenceFormatted);

    //     console.log("hoursPerDay:", hoursPerDay);
    // console.log("totalDays:", totalDays);

    // console.log("totalCombinedMinutes:", totalCombinedMinutes);

    // Add the difference row (showing the actual difference in hours and minutes)
    // transformedData.push({
    //   name: "Total Different Hours",
    //   // user_role: differenceFormatted, // Show the formatted difference here
    //   team_names: "",
    //   total_billable_hours: "",
    //   total_side_works_hours: "",
    //   total_advance_hours: "",
    //   total_working_hours: "",
    //   remark: "" // No remark for the difference row
    // });

    // Create summary data for Total Hours
    const totalData = {
      name: "Total Hours",
      team_names: "",
      total_billable_hours: convertToHourMinuteFormat(totalBillableMinutes),
      total_side_works_hours: convertToHourMinuteFormat(totalSideWorkMinutes),
      total_advance_hours: convertToHourMinuteFormat(totalAdvanceWorkMinutes),
      total_working_hours: convertToHourMinuteFormat(totalCombinedMinutes),
      remark: "",
    };

    transformedData.push(totalData);

    transformedData.push({
      name: "",
      user_role: "",
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    transformedData.push({
      name: "",
      user_role: "",
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    transformedData.push({
      name: "No of Members",
      user_role: memberCount.toString(),
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    transformedData.push({
      name: "Total Billable Hours",
      user_role: convertToHourMinuteFormat(totalBillableMinutes),
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    transformedData.push({
      name: "Total Side Work",
      user_role: convertToHourMinuteFormat(totalSideWorkMinutes),
      team_names: "",
      total_billable_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    transformedData.push({
      name: "Total Advance",
      user_role: convertToHourMinuteFormat(totalAdvanceWorkMinutes),
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_working_hours: "",
      remark: "",
    });
    transformedData.push({
      name: "Total Hours",
      user_role: convertToHourMinuteFormat(totalCombinedMinutes),
      team_names: "",
      total_billable_hours: "",
      total_side_works_hours: "",
      total_advance_hours: "",
      total_working_hours: "",
      remark: "",
    });

    return transformedData;
  };

  const csvMemberData = transformDataForCSV(data);

  const { RangePicker } = DatePicker;

  const disabledFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    if (!filters.date) {
      setSelectedStartDate(dayjs());
      setSelectedEndDate(dayjs());
      setDates({ rangeStartDate: null, rangeEndDate: null });
      setCurrentPage(1);
    }
  }, [filters?.date]);
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setIsUpdated((prev) => !prev);
    }
  };
  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    setCheckAll(false);
    setIsUpdated((prev) => !prev);
  };

  // useEffect(() => {
  //   tableInstance.setPageSize(pageSize);
  // }, [pageSize, tableInstance, isUpdated]);

  useEffect(() => {
    let filtered = [...memberReportData];

    if (filters.team) {
      filtered = filtered.filter((job) => job.team_id === filters.team.value);
    }

    const entriesData = filters.showAvailableTimeEntries ? filtered : filtered;

    setMemberList(entriesData);
  }, [filters, memberReportData]);

  const handleDownload = async (row) => {
    const selectedStartDate = startDateRef.current;

    const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");

    const userId = row?.original?.user_id;
    const name = row?.original?.name;

    const payload = {
      user_ids: userId,
      date: formattedStartDate,
    };

    const url = `${mainURL}export/member-time-entries`;

    try {
      const response = await axios.post(url, payload, {
        headers: headerOptions(),
        responseType: "arraybuffer",
      });

      if (response?.data?.error) {
        ReactHotToast(response?.data?.message, "error");
        return;
      }

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${name}_${formattedStartDate}.report.pdf`;
      link.click();

      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      if (error?.response?.data) {
        ReactHotToast(
          error?.response?.data?.message || "Error fetching PDF",
          "error"
        );
      } else {
        ReactHotToast("An error occurred while fetching the PDF.", "error");
      }
      console.error("Download error:", error);
    }
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={usersIcon} alt="members" />
          <p className="m-0 fs-4">Member List</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>
      <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-2">
        <button
          className={`btn ${
            activeTab === "member" ? "btn-light" : "btn-secondary"
          }`}
          style={{
            backgroundColor: activeTab === "member" ? "#00263d" : "",
            color: activeTab === "member" ? "white" : "",
            fontWeight: "500",
          }}
          onClick={() => handleTabChange("member")}
        >
          Active
        </button>
        <button
          className={`btn ${
            activeTab === "inactive" ? "btn-light" : "btn-secondary"
          }`}
          style={{
            backgroundColor: activeTab === "inactive" ? "#00263d" : "",
            color: activeTab === "inactive" ? "white" : "",
            fontWeight: "500",
          }}
          onClick={() => handleTabChange("inactive")}
        >
          Suspend
        </button>
      </div>

      <div className="mr-40 ml-30 mt-4 mb-15 d-flex gap-4">
        <div className="relative-wrapper">
          <img className="search-icon" src={calendarIcon} alt="search-icon" />
          <Space direction="vertical">
            <RangePicker
              disabledDate={disabledFutureDates}
              onChange={handleRangeChange}
              format="DD-MM-YYYY"
              className="react-select-custom-styling__control"
            />
          </Space>
        </div>
        {userRole === "team_leaders,members" || userRole === "members,team_sub_leader"  ? null : (
          <div className="relative-wrapper w-25">
            <img className="search-icon" src={teamsIcon} alt="search-icon" />
            <AsyncPaginate
              isClearable={true}
              isMulti={true}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={filters.team}
              loadOptions={TeamLoadOptions}
              onChange={(option) => onFilterChange("team", option)}
              additional={{
                page: 1,
              }}
              placeholder={`Select Team`}
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Team found for "${inputValue}"`
                  : "No Team found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Teams", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#007bff"
                    : state.isFocused
                    ? "#e0e0e0"
                    : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>
        )}

        <div className="form-group flex-row align-items-center justify-content-start">
          <label
            style={{ width: "max-content" }}
            htmlFor="showAvailableTimeEntries"
          >
            Show Available Time Entries:
          </label>
          <input
            id="showAvailableTimeEntries"
            name="showAvailableTimeEntries"
            type="checkbox"
            style={{ marginLeft: "10px" }}
            className="cursor-pointer checkbox-input"
            checked={filters.showAvailableTimeEntries}
            onChange={(e) =>
              onFilterChange("showAvailableTimeEntries", e.target.checked)
            }
          />
        </div>
        <div className="d-flex justify-content-end">
          <div
            style={{ margin: "2px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              {hasSelectedIds && (
                <button
                  className="custom-btn"
                  onClick={handleDownloadWithSelectedIds}
                >
                  {loading ? <SpinningLoader /> : "Export as PDF"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <MemberListTable
            tableInstance={tableInstance}
            headers={headers}
            memberList={csvMemberData}
            // memberList={
            //   activeTab === "member" ? activeMember : inactiveMember || []
            // }
          />

          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={allMemberReports}
          />
        </>
      )}
    </div>
  );
};

export default MemberList;
