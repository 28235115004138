import React, { useCallback, useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdvanceBillingReportContent from "./components/AdvanceBillingReportContent";
import { debounce } from "lodash";
import axios from "axios";
import { headerOptions } from "../../../utils/utilities/utilityFunctions";

const AdvanceBillingReport = () => {
  const { initialState, getAdvanceBillingReportData ,userId,mainURL} =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
    client:null,
    paymentStatus:null
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // useEffect(() => {
  //   getAdvanceBillingReportData();
  // }, [isUpdated]);


  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAdvanceBillingReportData(currentPage, pageSize, searchQuery, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAdvanceBillingReportData(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);
  const onFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  
  const [valueForClient, setValueForClient] = useState(null);
  async function LoadClientsOptions(search, loadedOptions, { page }) {

    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}/list/all-client/${userId}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });
  
      // Check for the response structure and handle potential issues
      const transformedData = response.data?.client_list?.client_data?.map((item) => {
        try {
          // Parse the additional client data
          const { client_name, additional_data, client_id } = item;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${bpoNo ? ` - ${bpoNo}` : ""})`;
  
          return {
            value: client_id,
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.client_id,
            label: "Parsing Error - Invalid Client Data",
          };
        }
      }) || [];
  
      const combinedOptions = page === 1
        ? transformedData
        : [...(loadedOptions.options || []), ...transformedData];
        setValueForClient(combinedOptions)
      return {
        options: combinedOptions,
        hasMore: response.data?.client_list?.hasMore || false,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading client options:", error);

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  return (
    <AdvanceBillingReportContent
      advancebillingReportData={initialState?.advanceBillingReportList}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      isLoading={initialState?.isLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
      setFilters={setFilters}
      filters={filters}
      LoadClientsOptions={LoadClientsOptions}
      valueForClient={valueForClient}
      onFilterChange={onFilterChange} 
    />
  );
};

export default AdvanceBillingReport;
