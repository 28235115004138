// import React, { useContext, useState, useEffect } from "react";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import AdvancBillingContent from "./AdvanceBillingContent";
// import AdvancBillingJobListContent from "../AdvanceBillingJobList.js/components/AdvanceBillingJobListContent";

// export const AdvancBillingJobs = () => {
//   const { getAllAdvanceBillingJobs, getJobsDetailsByMemberId, initialState } =
//     useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   useEffect(() => {
//     // getJobsDetailsByMemberId()
//     getAllAdvanceBillingJobs();
//   }, [isUpdated]);

//   return (
//     <>
//       <AdvancBillingContent
//         advancBillingJobsData={initialState?.advanceBillingJobList}
//         setIsUpdated={setIsUpdated}
//         isLoading={initialState?.isLoading}
//       />
//       {/* <AdvancBillingJobListContent
//     advancBillingJobsData={initialState?.advanceBillingJobList}
//     setIsUpdated={setIsUpdated}
//     isLoading={initialState?.isLoading}
//   /> */}
//     </>
//   );
// };

// import React, { useContext, useState, useEffect } from "react";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import AdvancBillingContent from "./AdvanceBillingContent";
// import AdvancBillingJobListContent from "../AdvanceBillingJobList.js/components/AdvanceBillingJobListContent";

// export const AdvancBillingJobs = () => {
//   const { getAllAdvanceBillingJobs, getJobsDetailsByMemberId, initialState } =
//     useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // Retrieve page number from localStorage, default to 1 if not found
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(
//     storedPage ? Number(storedPage) : 1
//   );

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(
//     storedPageSize ? Number(storedPageSize) : 10
//   );

//   useEffect(() => {
//     const handleRefresh = (event) => {
//       setCurrentPage(1);
//       setPageSize(10);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 10);
//     };
//     // Reset pagination on component mount
//     setCurrentPage(1);
//     setPageSize(10);
//     localStorage.setItem("currentPage", 1);
//     localStorage.setItem("pageSize", 10);
//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       // Clear localStorage values when component unmounts
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.addEventListener("beforeunload", handleRefresh);
//     };
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       getAllAdvanceBillingJobs(currentPage, pageSize);
//     }, 100);

//     // Cleanup function to clear the timer
//     return () => clearTimeout(timer);
//   }, [isUpdated, currentPage, pageSize]);

//   return (
//     <>
//       <AdvancBillingContent
//         advancBillingJobsData={initialState?.advanceBillingJobList}
//         setIsUpdated={setIsUpdated}
//         isLoading={initialState?.isLoading}
//         totalPages={initialState?.totalPages}
//         currentPage={currentPage}
//         setCurrentPage={setCurrentPage}
//         pageSize={pageSize}
//         setPageSize={setPageSize}
//       />
//     </>
//   );
// };

// import React, { useContext, useState, useEffect, useCallback } from "react";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import AdvancBillingContent from "./AdvanceBillingContent";
// import { debounce } from "lodash"; // Assuming lodash is being used for debounce

// export const AdvancBillingJobs = () => {
//   const {
//     getAllAdvanceBillingJobs,
//     getAllAdvanceBillingJobsPagination,
//     initialState,
//     initialStateLoading,
//   } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filters, setFilters] = useState({
//     status: null,
//     assignedTo: null,
//   });

//   // Pagination state
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((query, currentFilters, page, limit) => {
//       getAllAdvanceBillingJobsPagination(page, limit, query, currentFilters);
//     }, 500),
//     [filters, pageSize]
//   );

//   // Reset pagination when filters or search changes
//   useEffect(() => {
//     setCurrentPage(1);
//   }, [searchQuery, filters]);

//   // Effect for data fetching
//   useEffect(() => {
//     debouncedSearch(searchQuery, filters, currentPage, pageSize);

//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [searchQuery, filters, currentPage, pageSize, isUpdated]);

//   // Handle filter changes
//   const handleFilterChange = (type, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [type]: value,
//     }));
//   };

//   // Handle search changes
//   const handleSearchChange = (value) => {
//     setSearchQuery(value);
//   };

//   console.log(
//     "initialState?.advanceBillingJobList",
//     initialState?.advanceBillingJobList
//   );

//   console.log(
//     "initialStateLoading?.getAllAdvanceBillingJobsPaginationIsLoading",
//     initialStateLoading?.getAllAdvanceBillingJobsPaginationIsLoading
//   );

//   return (
//     <AdvancBillingContent
//       advancBillingJobsData={initialState?.advanceBillingJobList}
//       setIsUpdated={setIsUpdated}
//       isLoading={
//         initialStateLoading?.getAllAdvanceBillingJobsPaginationIsLoading
//       }
//       totalPages={initialState?.totalPages}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//       searchQuery={searchQuery}
//       onSearchChange={handleSearchChange}
//       filters={filters}
//       onFilterChange={handleFilterChange}
//     />
//   );
// };

// export default AdvancBillingJobs;

import React, { useContext, useState, useEffect, useCallback } from "react";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdvancBillingContent from "./AdvanceBillingContent";
import { debounce } from "lodash"; // Assuming lodash is being used for debounce
import axios from "axios";
import { headerOptions } from "../../../utils/utilities/utilityFunctions";

export const AdvancBillingJobs = () => {
  const {
    getAllAdvanceBillingJobsPagination,
    initialState,
    initialStateLoading,mainURL,userId
  } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    status: null,
    client: null,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      getAllAdvanceBillingJobsPagination(currentPage, pageSize, query, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchQuery !== "") {
      debouncedSearch(searchQuery);
    } else {
      getAllAdvanceBillingJobsPagination(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, currentPage, pageSize, filters, debouncedSearch, isUpdated]);

  // Handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
    setCurrentPage(1);
  };

  // Handle search changes
  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };
  // Reset pagination when filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);
  const [valueForClient, setValueForClient] = useState(null);
  async function LoadClientsOptions(search, loadedOptions, { page }) {
    
    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}/list/all-client/${userId}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });
  
      // Check for the response structure and handle potential issues
      const transformedData = response.data?.client_list?.client_data?.map((item) => {
        try {
          // Parse the additional client data
          const { client_name, additional_data, client_id } = item;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${bpoNo ? `${bpoNo}` : ""})`;
  
          return {
            value: client_id,
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.client_id,
            label: "Parsing Error - Invalid Client Data",
          };
        }
      }) || [];
  
      const combinedOptions = page === 1
        ? transformedData
        : [...(loadedOptions.options || []), ...transformedData];
        setValueForClient(combinedOptions)
      return {
        options: combinedOptions,
        hasMore: response.data?.client_list?.hasMore || false,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading client options:", error);

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  
  return (
    <AdvancBillingContent
      advancBillingJobsData={initialState?.advanceBillingJobList}
      setIsUpdated={setIsUpdated}
      isLoading={
        initialStateLoading?.getAllAdvanceBillingJobsPaginationIsLoading
      }
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      filters={filters}
      onFilterChange={handleFilterChange}
      LoadClientsOptions={LoadClientsOptions}
      valueForClient={valueForClient}
    />
  );
};

export default AdvancBillingJobs;
