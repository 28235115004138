// import React, { useCallback, useContext, useEffect, useState } from "react";

// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import AdHocContent from "./AdHocContent";
// import { debounce } from "lodash";

// const AdHoc = () => {
//   const {
//     getAllBillingServices,
//     getAllAdHocClients,
//     initialState,
//     initialStateLoading,
//   } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   const [searchInput, setSearchInput] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);

//   const [pageSize, setPageSize] = useState(10);
//   const [searchTriggered, setSearchTriggered] = useState(false);

//  useEffect(() => {
//     if (searchInput === "") {
//       setSearchTriggered(false);
//       setCurrentPage(1);
//     }
//   }, [searchInput]);

//   const handleInputChange = (e) => {
//     const trimmedInput = e.target.value.replace(/^\s+/, '');
//     console.log("trimmedInput",trimmedInput)
//     setSearchInput(trimmedInput);
//     setSearchTriggered(true);
//     setCurrentPage(1);
//   };

//   const debouncedSearch = useCallback(
//     debounce((searchQuery) => {
//       getAllAdHocClients(currentPage, pageSize, searchQuery);
//     }, 500),
//     [currentPage, pageSize]
//   );

//   // Trigger the debounced search whenever the search input changes
//   useEffect(() => {
//     if (searchTriggered && searchInput !== "") {
//       debouncedSearch(searchInput);
//     } else {
//       getAllAdHocClients(currentPage, pageSize, "");
//     }

//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [
//     searchInput,
//     searchTriggered,
//     debouncedSearch,
//     currentPage,
//     pageSize,
//     isUpdated,
//   ]);

//   return (
//     <AdHocContent
//       adHocClientsList={initialState.adHocClientsList}
//       isLoading={initialStateLoading?.getAllAdHocClientsIsLoading}
//       setIsUpdated={setIsUpdated}
//       totalPages={initialState?.totalPages}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//       searchInput={searchInput}
//       setSearchInput={setSearchInput}
//       handleInputChange={handleInputChange}
//     />
//   );
// };

// export default AdHoc;

import React, { useCallback, useContext, useState, useEffect } from "react";
import { debounce } from "lodash";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdHocContent from "./AdHocContent";

const AdHoc = () => {
  const { getAllAdHocClients, initialState, initialStateLoading } =
    useContext(ContextAPI);

  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (searchInput !== "") {
      setCurrentPage(1);
    }
  }, [searchInput]);

  // Debounced search API call
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllAdHocClients(currentPage, pageSize, searchQuery);
    }, 500),
    [currentPage, pageSize]
  );

  // Handle input change
  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchInput(trimmedInput);

    if (trimmedInput === "") {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllAdHocClients(currentPage, pageSize, "");
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, debouncedSearch, currentPage, pageSize, isUpdated]);

  return (
    <AdHocContent
      adHocClientsList={initialState.adHocClientsList}
      isLoading={initialStateLoading?.getAllAdHocClientsIsLoading}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
    />
  );
};

export default AdHoc;
