import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { projectsIcon } from "../../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import {
  handleAPIError,
  headerOptions,
  presentDate,
  timeOptions,
} from "../../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
const MyVerticallyCenteredModal = (props) => {
  const { logout, mainURL, userId, initialState, userDetails } =
    useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [showBillableForm, setShowBillableForm] = useState(false);
  const [showsideWorkForm, setShowsideWorkForm] = useState(false);
  const [showPostDraftChangesForm, setShowPostDraftChangesForm] =
    useState(false);
  const [showAdvanceBillingForm, setShowAdvanceBillingForm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [value, setValue] = useState(null);
  const [valueForIndividual, setValueForIndividual] = useState(null);
  const [valueForPostDraftIndividual, setValueForPostDraftIndividual] =
    useState(null);
  const [valueForPostDraftJob, setValueForPostDraftJob] = useState(null);
  const [valueForAdvanceBilling, setValueForAdvanceBilling] = useState(null);
  const [valueForEmployee, setValueForEmployee] = useState(null);
  const [teamId, setTeamId] = useState();
  const [entryDetails, setEntryDetails] = useState({
    startTime: "",
    endTime: "",
    entryDescription: "",
    entryDuration: "",
    job: null,
    entryAs: "",
    teamId: "" || teamId,
    entryDate: "",
    employee: "",
  });

  //**************************** */
  const formatTime = (hours, minutes) => {
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  };
  const handleStartTimeChange = (newTime) => {
    if (!newTime) {
      newTime = "00:00";
    }
    const [hours, minutes] = newTime.split(":");
    setEntryDetails((prev) => ({
      ...prev,
      startTime: { value: `${hours}:${minutes}:00` },
    }));
  };
  const handleEndTimeChange = (newTime) => {
    if (!newTime) {
      newTime = "00:00";
    }
    const [hours, minutes] = newTime.split(":");
    setEntryDetails((prev) => ({
      ...prev,
      endTime: { value: `${hours}:${minutes}:00` },
    }));
  };
  //********************************  */
  useEffect(() => {
    if (entryDetails?.entryAs === "Member") {
      setValue(null);
      setValueForAdvanceBilling(null);
      setValueForPostDraftJob(null);
      setValueForAdvanceBilling(null);
    }
    if (entryDetails?.entryAs === "Team") {
      setValueForIndividual(null);
      setValueForPostDraftIndividual(null);
      setValueForAdvanceBilling(null);
    }
  }, [entryDetails.entryAs]);

  useEffect(() => {
    if (showAdvanceBillingForm) {
      setValue(null);
      setValueForIndividual(null);
      setValueForPostDraftIndividual(null);
      setValueForPostDraftJob(null);
    }
    if (showBillableForm) {
      setValueForAdvanceBilling(null);
      setValueForPostDraftJob(null);
      setValueForPostDraftIndividual(null);
    }
    if (showsideWorkForm) {
      setValueForAdvanceBilling(null);
      setValueForPostDraftJob(null);
      setValueForPostDraftIndividual(null);
    }
    if (showPostDraftChangesForm) {
      setValueForAdvanceBilling(null);
      setValueForIndividual(null);
    }
  }, [
    showPostDraftChangesForm,
    showsideWorkForm,
    showBillableForm,
    showAdvanceBillingForm,
  ]);

  const handleClear = () => {
    setEntryDetails((prev) => ({
      ...prev,
      entryDescription: "",
      job: "",
      entryAs: "",
      // startTime: "",
      // endTime: "",
    }));
  };
  const onChangeDate = (element, date, dateString) => {
    setEntryDetails((prev) => ({
      ...prev,
      [element]: { date, dateString },
    }));
  };
  const handleEntryAs = (e) => {
    setEntryDetails((prev) => ({ ...prev, entryAs: e.target.value, job: "" }));
  };
  const handleChange = (date, dateString, element) => {
    onChangeDate(element, date, dateString);
  };
  const addNewEntry = async () => {
    try {
      const formattedDate = moment(
        entryDetails.entryDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      setIsLoading(true);
      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        entries_as: entryDetails?.entryAs,
       entry_for_user: entryDetails.employee.value,
        work_start_time: entryDetails?.startTime?.value,
        work_end_time: entryDetails?.endTime?.value,
        working_date: await formattedDate,
        work_description: entryDetails?.entryDescription,
        entry_for_user: entryDetails?.employee?.value,
      };

      if (
        showBillableForm ||
        showPostDraftChangesForm ||
        showAdvanceBillingForm
      ) {
        body.task_id = +entryDetails?.job?.value;
      }

      if (showPostDraftChangesForm) {
        body.post_draft = "yes";
      }
      // if (entryDetails?.entryAs === "Team") {
      //   body.team_id = initialState?.myTeamID;
      // } else {
      //   body.team_id = initialState?.myTeamID;
      // }
      if (entryDetails?.entryAs === "Team") {
        body.team_id = teamId;
      } else {
        body.team_id = teamId;
      }
      const url = showAdvanceBillingForm
        ? `${mainURL}add/advanced-billing/time-entries-admin`
        : showBillableForm || showPostDraftChangesForm
        ? `${mainURL}add/billable_hours/time-entries-admin`
        : `${mainURL}add/side_works/time-entries-admin`;

      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result.data.message, "success");
        // window.location.reload(true);
        handleClear();
        setIsUpdated((prev) => !prev);
        props.onHide();
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      setIsUpdated((prev) => !prev);
    }
  };
  const handleClickForm = async (formType) => {
    setShowsideWorkForm(formType === "sidework");
    setShowBillableForm(formType === "billable");
    setShowPostDraftChangesForm(formType === "postDraftChanges");
    setShowAdvanceBillingForm(formType === "advanceBilling");
    if (formType === "advanceBilling") {
      try {
        // console.log("Advance Billing Welocome ");
        // await advanceBillingloadOptions("", { options: [] }, { page: 1 });
      } catch (error) {
        console.error("Error loading advance billing options:", error);
      }
    }
    if (formType === "billable") {
      try {
        // console.log(" Billing Welocome ");
        // await advanceBillingloadOptions("", { options: [] }, { page: 1 });
      } catch (error) {
        console.error("Error loading advance billing options:", error);
      }
    }
    if (formType === "sidework") {
      try {
        // console.log("SideWork Welocome ");
        // await advanceBillingloadOptions("", { options: [] }, { page: 1 });
      } catch (error) {
        console.error("Error loading advance billing options:", error);
      }
    }
    if (formType === "postDraftChanges") {
      try {
        // console.log("postDraftChanges Welocome ");
        // await advanceBillingloadOptions("", { options: [] }, { page: 1 });
      } catch (error) {
        console.error("Error loading advance billing options:", error);
      }
    }
    handleClear();
  };
  const handleAddEntry = (e) => {
    e.preventDefault();

    const { entryDescription, job, entryAs, startTime, endTime, employee } =
      entryDetails;

    // Ensure all required fields are filled
    const isDescriptionValid = Boolean(entryDescription);
    const isStartTimeValid = Boolean(startTime?.value);
    const isEndTimeValid = Boolean(endTime?.value);
    const isJobValid = showBillableForm ? Boolean(job?.value) : true;
    const isJobValid1 = showPostDraftChangesForm ? Boolean(job?.value) : true;
    const isJobValid2 = showAdvanceBillingForm ? Boolean(job?.value) : true;
    const isEntryAsValid = showAdvanceBillingForm ? true : Boolean(entryAs);

    // Check if all validations pass
    if (
      isDescriptionValid &&
      isStartTimeValid &&
      isEndTimeValid &&
      isJobValid &&
      isEntryAsValid &&
      isJobValid1 &&
      isJobValid2
    ) {
      addNewEntry();
    } else {
      const conditions = {
        "Please input time entry description!": !isDescriptionValid,
        "Please select Entry Start Time!": !isStartTimeValid,
        "Please select Entry End Time!": !isEndTimeValid,
        "Please select a job!": showBillableForm && !isJobValid,
        "Please select a job!! ": showPostDraftChangesForm && !isJobValid1,
        "Please select a job !": showAdvanceBillingForm && !isJobValid2,
        "Please select entry as either team or individual!": !isEntryAsValid,
      };

      // Find the first error message that is true
      const errorMessage = Object.keys(conditions).find(
        (key) => conditions[key]
      );

      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };
  async function loadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${entryDetails?.employee?.value}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            }) Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function MemberloadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}my-jobs/${entryDetails?.employee?.value}`,
        {
          params: {
            job_status: "In Progress",
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data.job_data.my_single_jobs.map(
        (item) => {
          try {
            // Parse the additional client data
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};

            // Create the label for the job
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.job_data?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${entryDetails?.employee?.value}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function postDraftChangesMemberloadOptions(
    search,
    loadedOptions,
    { page }
  ) {
    try {
      const url = `${mainURL}generated_invoices/${entryDetails?.employee?.value}`;
      const response = await axios.get(url, {
        params: {
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData =
        response?.data?.post_draft_jobs?.my_single_jobs.map((item) => {
          try {
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            }  ${item.assigned_on || "N/A"}`;
            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.post_draft_jobs?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function postDraftChangesJobloadOptions(
    search,
    loadedOptions,
    { page }
  ) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${entryDetails?.employee?.value}/${teamId}`,
        {
          params: {
            page,
            limit: 20,
            task_type: "post_draft",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"}  -  ${
              item.task_name || "N/A"
            }  -  ${item.assigned_on || "N/A"}`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);
      // BP335 - hiKari Moving Media Ltd - 31-05-2024
      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  //************For Selected Option   End  ******************/
  useEffect(() => {
    if (!props.show) {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const formattedTime = formatTime(hours, minutes);

      // setEntryDetails((prev) => ({
      //   ...prev,
      //   startTime: { value: `${formattedTime}:00` },
      //   endTime: { value: `${formattedTime}:00` },
      // }));
      setEntryDetails({
        startTime: { value: `${formattedTime}:00` },
        endTime: { value: `${formattedTime}:00` },
        entryDescription: "",
        job: "",
        entryAs: "",
        teamId: "",
        jobOptions: [],
        advancedBillingJobOptions: [],
        postDraftJobListOptions: [],
        entryDate: {
          date: dayjs(),
          dateString: dayjs().format("DD-MM-YYYY"),
        },
      });
      setShowBillableForm(false);
      setShowsideWorkForm(false);
      setShowPostDraftChangesForm(false);
      setShowAdvanceBillingForm(false);
      setValue(null);
      setValueForAdvanceBilling(null);
      setValueForIndividual(null);
      setShowPostDraftChangesForm(null);
      setValueForEmployee(null);
      setValueForPostDraftIndividual(null);
      setValueForPostDraftJob(null);
      setValueForAdvanceBilling(null);
      setTeamId(null);
      setIsUpdated(false);
    }
  }, [props.show]);

  async function UsersLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}list/all-member/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );
      // console.log("response", response);

      const transformedData = response.data?.members_list?.users
        ?.filter(
          (item) =>
            item.member_role !== "it_member" &&
            item.member_role !== "operation_member"
        )
        .map((item) => {
          try {
            const label = `${item.member_name || "N/A"}`;
            return {
              value: item.member_id,
              label: label,
              teamId: item.team_id,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.member_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
      const hasMore =
        Array.isArray(response.data?.members_list?.users) &&
        response.data.members_list.users.length > 0;

      return {
        options: combinedOptions,
        hasMore: response.data?.members_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Add Job Entry</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="form-group w-100 mt-1">
          <label htmlFor="">Entry Type:</label>
          <div
            name="assignee-radio-group"
            className="radio-group mt-2 d-flex justify-content-start"
          >
            <label htmlFor="billable">
              <input
                type="radio"
                id="billable"
                value="billable"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("billable")}
              />
              <span>Billable Hours</span>
            </label>
            <label htmlFor="sideWork">
              <input
                type="radio"
                id="sideWork"
                value="sideWork"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("sidework")}
              />
              <span>Side Work</span>
            </label>
            <label htmlFor="postDraftChanges">
              <input
                type="radio"
                id="postDraftChanges"
                value="postDraftChanges"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("postDraftChanges")}
              />
              <span>Post Draft Changes</span>
            </label>
            <label htmlFor="advanceBilling ">
              <input
                type="radio"
                id="advanceBilling"
                value="advanceBilling"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("advanceBilling")}
              />
              <span>Advance Billing </span>
            </label>
          </div>
        </div>

        {showBillableForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobType">Select Employee:</label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForEmployee}
                  loadOptions={UsersLoadOptions}
                  onChange={(selectedOption) => {
                    setValueForEmployee(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      employee: selectedOption,
                    }));
                    const selectedTeamId = selectedOption?.teamId;
                    setTeamId(selectedTeamId);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: null,
                    }));
                    setValue(null);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={`Select Employee`}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Employee found for "${inputValue}"`
                      : "No Employee found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#007bff"
                        : state.isFocused
                        ? "#e0e0e0"
                        : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#0056b3"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>
                {showBillableForm && !entryDetails?.entryAs && (
                  <div className="form-group  w-100">
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      placeholder={`Select Individual or Team `}
                    />
                  </div>
                )}
                {entryDetails?.entryAs === "Member" && (
                  <AsyncPaginate
                    // key={teamId}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={value}
                    loadOptions={(search, loadedOptions, { page }) =>
                      MemberloadOptions(search, loadedOptions, {
                        page,
                        teamId,
                      })
                    }
                    onChange={(selectedOption) => {
                      setValue(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                    }}
                    placeholder="Select Individual Job"
                    debounceTimeout={300}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No Job found for "${inputValue}"`
                        : "No Job found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                      console.error("Async Paginate Error:", error);
                    }}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : state.isFocused
                          ? "#e0e0e0"
                          : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#0056b3"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
                {entryDetails?.entryAs === "Team" && (
                  <AsyncPaginate
                    key={teamId}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForIndividual}
                    loadOptions={(search, loadedOptions, { page }) =>
                      loadOptions(search, loadedOptions, { page, teamId })
                    }
                    onChange={(selectedOption) => {
                      setValueForIndividual(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                      teamId: teamId,
                    }}
                    placeholder="Select Team Job"
                    debounceTimeout={300}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No Job found for "${inputValue}"`
                        : "No Job found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                      console.error("Async Paginate Error:", error);
                    }}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : state.isFocused
                          ? "#e0e0e0"
                          : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#0056b3"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>
                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>
              <div className="d-flex  flex-column w-100">
                <div className="d-flex gap-5 mt-4">
                  <div className="mb-0">
                    <label
                      htmlFor="startTime"
                      className="primary-font-color fw-semibold"
                    >
                      Start Time (hh:mm):
                    </label>
                    <div className="input-group">
                      <TimePicker
                        value={entryDetails.startTime.value}
                        onChange={handleStartTimeChange}
                        disableClock={true}
                        clearIcon={null}
                        format="hh:mm a"
                        className="form-control rounded"
                      />
                    </div>
                  </div>

                  <div className="mb-0">
                    <label
                      htmlFor="endTime"
                      className="primary-font-color fw-semibold"
                    >
                      End Time (hh:mm):
                    </label>
                    <div className="input-group">
                      <TimePicker
                        value={entryDetails.endTime.value}
                        onChange={handleEndTimeChange}
                        disableClock={true}
                        clearIcon={null}
                        format="hh:mm a"
                        className="form-control rounded"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4 w-100">
                  <label htmlFor="jobDescription">Job Description:</label>
                  <textarea
                    id="jobDescription"
                    name="jobDescription"
                    className="w-100"
                    rows={3}
                    placeholder="Enter job description"
                    value={entryDetails?.entryDescription}
                    onChange={(e) =>
                      setEntryDetails((prev) => ({
                        ...prev,
                        entryDescription: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {showsideWorkForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              {/* Select Employee */}
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobType">Select Employee:</label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForEmployee}
                  loadOptions={UsersLoadOptions}
                  onChange={(selectedOption) => {
                    setValueForEmployee(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      employee: selectedOption,
                    }));
                    const selectedTeamId = selectedOption?.teamId;
                    setTeamId(selectedTeamId);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: null,
                    }));
                    setValue(null);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={`Select Employee`}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Employee found for "${inputValue}"`
                      : "No Employee found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#007bff"
                        : state.isFocused
                        ? "#e0e0e0"
                        : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#0056b3"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>
                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>
              <div className="d-flex gap-5 mt-4">
                <div className="mb-0">
                  <label
                    htmlFor="startTime"
                    className="primary-font-color fw-semibold"
                  >
                    Start Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.startTime.value}
                      onChange={handleStartTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>

                <div className="mb-0">
                  <label
                    htmlFor="endTime"
                    className="primary-font-color fw-semibold"
                  >
                    End Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.endTime.value}
                      onChange={handleEndTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {/*Post Draft Changes  */}
        {showPostDraftChangesForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              {/* Select Employee */}
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobType">Select Employee:</label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForEmployee}
                  loadOptions={UsersLoadOptions}
                  onChange={(selectedOption) => {
                    setValueForEmployee(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      employee: selectedOption,
                    }));
                    const selectedTeamId = selectedOption?.teamId;
                    setTeamId(selectedTeamId);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: null,
                    }));
                    setValue(null);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={`Select Employee`}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Employee found for "${inputValue}"`
                      : "No Employee found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#007bff"
                        : state.isFocused
                        ? "#e0e0e0"
                        : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#0056b3"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              {/*Radio Button section for select Entry ( Individual or Team ) */}
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>
                {showPostDraftChangesForm && !entryDetails?.entryAs && (
                  <div className="form-group  w-100">
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      placeholder={`Select Individual or Team `}
                    />
                  </div>
                )}
                {entryDetails?.entryAs === "Member" && (
                  <AsyncPaginate
                    // key={teamId}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForPostDraftIndividual}
                    loadOptions={postDraftChangesMemberloadOptions}
                    onChange={(selectedOption) => {
                      setValueForPostDraftIndividual(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                      teamId: teamId,
                    }}
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    debounceTimeout={300}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                      console.error("Async Paginate Error:", error);
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
                {entryDetails?.entryAs === "Team" && (
                  <AsyncPaginate
                    key={teamId}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForPostDraftJob}
                    loadOptions={postDraftChangesJobloadOptions}
                    onChange={(selectedOption) => {
                      setValueForPostDraftJob(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                      teamId: teamId,
                    }}
                    debounceTimeout={300} // Prevent excessive API calls
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    // Enhanced User Experience Features
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>

                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>
              <div className="d-flex gap-5 mt-4">
                <div className="mb-0">
                  <label
                    htmlFor="startTime"
                    className="primary-font-color fw-semibold"
                  >
                    Start Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.startTime.value}
                      onChange={handleStartTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>

                <div className="mb-0">
                  <label
                    htmlFor="endTime"
                    className="primary-font-color fw-semibold"
                  >
                    End Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.endTime.value}
                      onChange={handleEndTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {showAdvanceBillingForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              {/* Select Employee */}
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobType">Select Employee:</label>
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForEmployee}
                  loadOptions={UsersLoadOptions}
                  onChange={(selectedOption) => {
                    setValueForEmployee(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      employee: selectedOption,
                    }));
                    const selectedTeamId = selectedOption?.teamId;
                    setTeamId(selectedTeamId);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: null,
                    }));
                    setValue(null);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={`Select Employee`}
                  debounceTimeout={300}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Employee found for "${inputValue}"`
                      : "No Employee found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#007bff"
                        : state.isFocused
                        ? "#e0e0e0"
                        : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#0056b3"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>

                <AsyncPaginate
                  key={teamId}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForAdvanceBilling}
                  loadOptions={advanceBillingloadOptions}
                  onChange={(selectedOption) => {
                    setValueForAdvanceBilling(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: selectedOption,
                    }));
                  }}
                  additional={{
                    page: 1,
                    teamId: teamId,
                  }}
                  placeholder={"Select job...."}
                  debounceTimeout={300} // Prevent excessive API calls
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No jobs found for "${inputValue}"`
                      : "No jobs found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>

                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>
              <div className="d-flex gap-5 mt-4">
                <div className="mb-0">
                  <label
                    htmlFor="startTime"
                    className="primary-font-color fw-semibold"
                  >
                    Start Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.startTime.value}
                      onChange={handleStartTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>

                <div className="mb-0">
                  <label
                    htmlFor="endTime"
                    className="primary-font-color fw-semibold"
                  >
                    End Time (hh:mm):
                  </label>
                  <div className="input-group">
                    <TimePicker
                      value={entryDetails.endTime.value}
                      onChange={handleEndTimeChange}
                      disableClock={true}
                      clearIcon={null}
                      format="hh:mm a"
                      className="form-control rounded"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const AddTimeEntryModal = ({ setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <button className="custom-btn d-flex justify-content-center align-items-center gap-2">
          Add Time Entry <span className="fw-light fs-4">+</span>
        </button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
