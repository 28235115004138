// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllMembers();
//   }, [isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };
import React, { useCallback, useContext, useEffect, useState } from "react";

import MembersContent from "./components/MembersContent";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";
import { headerOptions } from "../../utils/utilities/utilityFunctions";
import axios from "axios";

export const Members = () => {
  const { getAllMembers, initialState, initialStateLoading,mainURL,userId } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
 
  const [filters, setFilters] = useState({ role: null,team:null });

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);



  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllMembers(currentPage, pageSize, searchQuery,filters);
    }, 500),
    [currentPage, pageSize,filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllMembers(currentPage, pageSize, "",filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    filters,
    isUpdated,
  ]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
    setCurrentPage(1); 
  };
 async function TeamLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}get/team/${userId}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
     
      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  return (
    <MembersContent
      membersData={initialState.membersList}
      totalPages={initialState?.totalPages}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      isLoading={initialStateLoading?.getAllMembersIsLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      // handleSearch={handleSearch}
      handleInputChange={handleInputChange}
      onFilterChange={handleFilterChange}  
      setFilters={setFilters}
      filters={filters}
      TeamLoadOptions={TeamLoadOptions}
    />
  );
};
// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllMembers();
//   }, [isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };
// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // Retrieve page number from localStorage, default to 1 if not found
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(
//     storedPage ? Number(storedPage) : 1
//   );

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(
//     storedPageSize ? Number(storedPageSize) : 10
//   );

//   useEffect(() => {
//     const handleRefresh = (event) => {
//       setCurrentPage(1);
//       setPageSize(10);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 10);
//     };
//     // Reset pagination on component mount
//     setCurrentPage(1);
//     setPageSize(10);
//     localStorage.setItem("currentPage", 1);
//     localStorage.setItem("pageSize", 10);
//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       // Clear localStorage values when component unmounts
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.addEventListener("beforeunload", handleRefresh);
//     };
//   }, []);

//   useEffect(() => {
//     // const timer = setTimeout(() => {
//       getAllMembers(currentPage, pageSize);
//     // }, 100);

//     // Cleanup function to clear the timer
//     // return () => clearTimeout(timer);
//   }, [ currentPage, pageSize,isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       totalPages={initialState?.totalPages}
//       setIsUpdated={setIsUpdated}
//       isUpdated={isUpdated}
//       isLoading={initialState.isLoading}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//     />
//   );
// };
