import React, { useContext, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { json, useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import {
  InvoiceIcon,
  MailIconSVG,
  clientsIcon,
  fileIcon,
  reportsIcon,
  searchIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../templates/PageHeader";
import { SendInvoiceModal } from "../../../Invoices/Components/SendInvoiceModal";

import Breadcrumbs from "../../../../templates/Breadcrumbs";

import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";

import { formatDate } from "../../../../utils/utilities/utilityFunctions";

import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import InvoiceListTable from "./InvoiceListTable";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";

import { MarkInvoiceModel } from "./MarkInvoiceModel";
import { Header } from "antd/es/layout/layout";
import PaginationComponent from "../../../../Components/Pagination/PaginationComponent";
import { AsyncPaginate } from "react-select-async-paginate";

const InvoiceList = ({
  isLoading,
  loading1,
  isUpdated,
  setIsUpdated,
  searchInput,
  handleInputChange,
  handleSearch,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  filters,
  setFilters,
  invoiceData,
  valueForClient,
  LoadClientsOptions,
  onFilterChange,
}) => {
  const navigate = useNavigate();

  const { initialState, getAllInvoice, emailOptions, invoiceMeta } =
    useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [invoice, setInvoice] = useState([]);

  const statusOptions = [
    { label: "Unpaid", value: "1" },
    { label: "Partially Paid", value: "2" },
    { label: "Paid", value: "3" },
  ];

  useEffect(() => {
    setInvoice(invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    const filteredData = invoiceData?.filter((invoice) => {
      return filters.status
        ? JSON.parse(invoice.invoice_containt).payment_status ===
            filters.status.value
        : true;
    });
    setInvoice(filteredData || []);
  }, [filters, invoiceData, pageSize]);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Invoice",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
      enableHiding: false,
      // Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Invoice code",
      accessor: "invoice_code",
    },
    {
      Header: "Client Name",
      accessor: "name",
    },
    {
      Header: "Status",
      accessor: "payment_status",
      Cell: ({ row }) => {
        const paymentStatus = JSON.parse(
          row.original.invoice_containt
        ).payment_status;

        return (
          <div className="d-flex justify-content-center">
            <Stack direction="horizontal" style={{ alignItems: "center" }}>
              {paymentStatus === "1" ? (
                <Badge bg="danger">Unpaid</Badge>
              ) : paymentStatus === "2" ? (
                <Badge bg="warning">Partially Paid</Badge>
              ) : paymentStatus === "3" ? (
                <Badge bg="success">Paid</Badge>
              ) : null}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Generated by",
      accessor: "generated_by",
    },
    {
      Header: "Issued on",
      accessor: "issued_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.issued_on);

        return (
          <div className="">
            <p className="m-0">{startDate}</p>
          </div>
        );
      },
    },
    {
      Header: "Invoice sendcount",
      accessor: "invoice_send_count",
    },
    {
      Header: "Is Minimum Invoice",
      accessor: "is_minimum_balance",
      Cell: ({ value }) => {
        return (
          <div className="d-flex justify-content-start">
            <Stack direction="horizontal">
              {value === "1" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Action",
      accessor: "invoice_containt",
      Cell: ({ row }) => {
        const paymentStatus = JSON.parse(
          row.original.invoice_containt
        ).payment_status;

        return (
          <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
            {/* Preview Invoice Action */}
            <Tooltip
              id="preview-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="preview-invoice-tooltip"
              data-tooltip-content="Preview Invoice"
              data-tooltip-place="top"
            >
              <div
                onClick={() => {
                  navigate(
                    row.original.is_minimum_balance === "1"
                      ? "/genrate/invoice/minimum-balance"
                      : "/invoice",
                    {
                      state: {
                        invoiceMeta: {
                          invoice_id: row.original?.invoice_id,
                        },
                        assignId:
                          (row.original?.invoice_containt &&
                            JSON.parse(row.original.invoice_containt)
                              ?.assign_id) ??
                          null,
                        isInvoicePreview: true,
                      },
                    }
                  );
                }}
              >
                <InvoiceIcon />
              </div>
            </div>

            {/* Send Invoice Action */}
            <Tooltip
              id="send-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              style={{ cursor: "pointer" }}
              data-tooltip-id="send-invoice-tooltip"
              data-tooltip-content="Mail Invoice"
              data-tooltip-place="top"
            >
              <SendInvoiceModal
                minimumBalanceEmail={row.original.is_minimum_balance}
                setIsUpdated={setIsUpdated}
                invoice={invoice}
                mailIcon={true}
                invoiceId={row.original?.invoice_id}
                listOfEmails={row.original?.email
                  ?.split(",")
                  ?.map((email) => ({ label: email, value: email }))}
                assignId={JSON.parse(row.original.invoice_containt)?.assign_id}
                data={row.original}
              />
            </div>

            {/* Mark Invoice Action (Conditional) */}
            {paymentStatus === "1" || paymentStatus === "2" ? (
              <div className="d-flex justify-content-center cursor-pointer">
                <MarkInvoiceModel
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  invoiceData={row.original}
                  setIsUpdated={setIsUpdated}
                  getAllInvoice={getAllInvoice}
                />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const columnHeaders = [
    "Sr no",
    "Invoice Code",
    "Client Name",
    "Status",
    "Generated by",
    "Issued on",
    "Invoice sendcount",
    "Is Minimum Invoice",
    "Action",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => invoice, [invoice]);

  const tableInstance = useTable(
    {
      initialState: {
        hiddenColumns: ["Action"],
      },
      initialState: { pageSize: pageSize },
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance, isUpdated]);

  const headers = {
    headings: [
      { label: "Invoice Code", key: "invoice_code" },
      { label: "Status", key: "payment_status" },
      { label: "Generated by", key: "generated_by" },
      { label: "Issued on", key: "issued_on" },
      { label: "Invoice sendcount", key: "invoice_send_count" },
      { label: "Is Minimum Balance", key: "is_minimum_balance" },
    ],
    fileName: "Invoices",
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };

  // Function to get status label based on value
  const getStatusLabel = (value) => {
    const status = statusOptions.find((option) => option.value === value);
    return status ? status.label : "Unknown";
  };

  // Function to transform data for CSV export
  const transformDataForCSV = (invoices) => {
    return invoices.map((invoice) => {
      const invoiceContent = JSON.parse(invoice.invoice_containt);
      return {
        invoice_code: invoice.invoice_code,
        payment_status: getStatusLabel(invoiceContent.payment_status),
        generated_by: invoice.generated_by,
        issued_on: invoice.issued_on,
        invoice_send_count: invoice.invoice_send_count
          ? invoice.invoice_send_count
          : "0",
        is_minimum_balance:
          invoice.is_minimum_balance === "1"
            ? "Yes"
            : invoice.is_minimum_balance === "0"
            ? "No"
            : "-",
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(invoice);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      {/* <PageHeader
        invoice={invoice}
        tableInstance={tableInstance}
        icon={fileIcon}
        headerTitle={"Invoice"}
      ></PageHeader> */}

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={fileIcon} alt="Invoice" />
          <p className="m-0 fs-4">Invoice</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>
      {/* filters */}
      <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) => {
              setFilters((prev) => ({
                ...prev,
                status: option,
              }));
            }}
            value={filters.status}
            placeholder="Select status"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
        {/* <div className="relative-wrapper w-25">
          <img className="search-icon" src={clientsIcon} alt="search-icon" />
          <AsyncPaginate
            isClearable={true}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
            value={filters.client}
            loadOptions={LoadClientsOptions}
            onChange={(option) => onFilterChange("client", option)}
            additional={{
              page: 1,
            }}
            placeholder={`Select Client`}
            debounceTimeout={300}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? `No Client found for "${inputValue}"`
                : "No Client found"
            }
            onError={(error) => {
              ReactHotToast("Error loading clients", "error");
              console.error("Async Paginate Error:", error);
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e0e0e0"
                  : "white",
                cursor: "pointer",
                color: state.isSelected ? "white" : "black",
                ":hover": {
                  backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div> */}
      </div>

     
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <InvoiceListTable
            columnHeaders={columnHeaders}
            tableInstance={tableInstance}
            headers={headers}
            invoice={csvInvoiceData}
          />
          
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={invoiceData}
          />
        </>
      )}
    </div>
  );
};

export default InvoiceList;
