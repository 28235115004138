import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

const AdHocBillingClientDetails = ({ invoice }) => {
  const { clientId } = useParams();
  const { getAllClients, initialState } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    getAllClients();
  }, [isUpdated]);
  // setting initial client details
  const [clientDetails, setClientDetails] = useState({
    companyName: "",
    primaryEmail: "",
    primaryContact: "",
    contactNo: "",
    address: "",
    bpoNo: "",
  });

  // Setting initial client details
  useEffect(() => {
    const clientInfo = initialState?.clientsList.find(
      (client) => client.client_id === clientId
    );

    if (clientInfo) {
      setClientDetails({
        companyName: clientInfo.additional_data?.company_name,
        primaryEmail: clientInfo.additional_data?.primary_email,
        primaryContact: clientInfo.additional_data?.primary_contact_no,
        contactNo: clientInfo.additional_data?.contact_no,
        address: clientInfo?.additional_data?.billing_address,
        bpoNo: clientInfo?.additional_data?.bpo_no,
      });
    }
  }, [initialState.clientsList, clientId]);

  return (
    <div className="row mb-4">
      {/* from */}
      <div className="col-sm-6">
        <h6 className="mb-3 font-outfit primary-font-color">From:</h6>
        <h5 className="mb-1 font-outfit primary-font-color">
          Doshi (London) Ltd. T/A Doshi & Co.
        </h5>
        <p className="mb-0 font-outfit primary-font-color">
          6th Floor AMP House
        </p>
        <p className="mb-0 font-outfit primary-font-color">
          Dingwall Road, Croydon, CR0 2LX
        </p>
        <p className="mb-0 font-outfit primary-font-color">
          Tel: 0208-239 4999
        </p>
        <div className="mb-0 font-outfit primary-font-color mt-4">
          Our Ref: <b>{clientDetails.bpoNo}</b>
        </div>
      </div>
      {/* to */}
      <div className="col-sm-6" style={{ float: "left" }}>
        <h6 className="mb-3 font-outfit primary-font-color">To:</h6>
        <h5 className="mb-1 font-outfit primary-font-color">
          {clientDetails.companyName}
        </h5>
        <div className="mb-0 font-outfit primary-font-color">
          {clientDetails.address}
        </div>
        <div className="mb-0 font-outfit primary-font-color">
          Email: {clientDetails.primaryEmail}
        </div>
        <div className="mb-0 font-outfit primary-font-color">
          Phone: {clientDetails.contactNo}
        </div>
      </div>
    </div>
  );
};

export default AdHocBillingClientDetails;
