import React, { useContext, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import {
  InvoiceIcon,
  clientsIcon,
  fileIcon,
  reportsIcon,
  searchIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import "../../../../../App.css";
import PageHeader from "../../../../../templates/PageHeader";
import AssignJobListTable from "./AssignJobListTable";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import { AssignJobModal } from "./AssignJobModal";
import { GenerateInvoiceModal } from "../GenerateInvoice/GenerateInvoiceModal";
import { EditAssignJobModal } from "./EditAssignJobModal";
import { formatDate } from "../../../../../utils/utilities/utilityFunctions";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import { DeleteAssignJobModal } from "./DeleteAssignJobModal";
import { format, parse, parseISO, isValid } from "date-fns";
import { AssignJobModalTL } from "./AssignJobModelTL";
import PaginationComponent from "../../../../../Components/Pagination/PaginationComponent";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import { AsyncPaginate } from "react-select-async-paginate";

const AssignJobsContent = ({
  setIsUpdated,
  isLoading,
  fetchedAssignJobList,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
  filters,
  onFilterChange,LoadClientsOptions,TeamLoadOptions
}) => {
  const { userDetails } = useContext(ContextAPI);
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [assignedJobs, setAssignedJobs] = useState([]);

  const filteredData = useMemo(() => {
    let filtered = fetchedAssignJobList || [];

    // Apply local filters if needed (in case server-side filtering isn't available)
    if (filters.status) {
      filtered = filtered.filter(
        (job) => job.job_status === filters.status.value
      );
    }
    if (filters.assignedTo) {
      filtered = filtered.filter(
        (job) => job.assign_to === filters.assignedTo.value
      );
    }
    if (filters.client) {
      filtered = filtered.filter(
        (job) => job.client_id === filters.client.value
      );
    }


    return filtered;
  }, [fetchedAssignJobList, filters]);

  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];
  const assignedToOptions = [
    { label: "Individual", value: "Individual" },
    { label: "Team", value: "Team" },
  ];
  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Jobs",
      pageURL: "/jobs",
    },
    {
      pageName: "Assign Job",
      pageURL: "/assign-job",
    },
  ];
  const detectAndParseDate = (dateStr) => {
    // Check if the date string is in 'yyyy-MM-dd' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    }
    // Check if the date string is in 'dd-MM-yyyy' format
    else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }
    // Invalid date format
    return null;
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "N.A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N.A";

    const day = format(dateObj, "d"); 
    const month = format(dateObj, "MMM");
    const year = format(dateObj, "yyyy"); 

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };
  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Job Name",
      accessor: "job_name",
      Cell: ({ value }) => {
        const [jobName, date] = value.split(" - ");
        return (
          <div>
            <p className="m-0 ">{jobName}</p>
            <p className="m-0">{date}</p>
          </div>
        );
      },
    },

    {
      Header: "Client Name",
      accessor: "client_name",
    },

    {
      Header: "Assigned to",
      accessor: "assign_to",
      Cell: ({ row }) => {
        const { assign_to, team_details } = row?.original;
        if (assign_to === "Team") {
          return team_details[0]?.team_name
            ? `Team - ${team_details[0]?.team_name}`
            : "---";
        }
        if (assign_to === "Individual") {
          return row?.original?.member_name[0]?.name ?? "---";
        }
        // return member_name[0] ?? "---";
      },
    },

    {
      Header: "Duration",
      accessor: "due_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.assigned_on);
        const endDate = formatDate(row.original.due_on);
        return (
          <div className="gap-1">
            <p className="m-0">From: {startDate}</p>
            <p className="m-0">To: {endDate}</p>
          </div>
        );
      },
    },
 
    {
      Header: "Job Description",
      accessor: "job_description",
      Cell: ({ value }) => {
        const isOverflowing = value?.length > 20;
        
        return (
          <>
            <div
              data-tooltip-id={isOverflowing ? "tooltip" : undefined}
              data-tooltip-content={value}
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: isOverflowing ? "pointer" : "default",
                padding: "8px",
                textAlign: "left",
              }}
            >
              {value}
            </div>
    
            {isOverflowing && (
              <Tooltip
                id="tooltip"
                place="top"
                effect="solid"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                  fontWeight: 400,
                  padding: "10px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  width: "400px",
                  wordWrap: "break-word",
                  zIndex: 10,
                }}
              />
            )}
          </>
        );
      },
    },
    
    
    
    // {
    //   Header: "Description",
    //   accessor: "job_description",
    // },
    {
      Header: "Is Minimum Bill",
      accessor: "is_minimum_billing",
      // Cell: ({ value }) => (value === "1" ? "Yes" : "No"),
      Cell: ({ value }) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <Stack direction="horizontal">
              {value === "1" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <Stack direction="horizontal">
              {row.original.job_status === "Completed" ? (
                <Badge bg="success">Completed</Badge>
              ) : row.original.job_status === "On Hold" ? (
                <Badge bg="danger">On Hold</Badge>
              ) : row.original.job_status === "Pending" ? (
                <Badge bg="danger">Pending</Badge>
              ) : row.original.job_status === "In Progress" ? (
                <Badge bg="warning" text="dark">
                  In Progress
                </Badge>
              ) : null}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Approved Time",
      accessor: "approved_time",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <Stack direction="horizontal">
              {row.original.approved_time === "yes" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    ...(userRole !== "members,team_sub_leader" &&
    userRole !== "team_leaders,members"
      ? [
          {
            Header: "Edit",
            Cell: ({ row }) => (
              <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
                {row.original.is_post_draft === "yes" &&
                row.original.is_post_draft_invoice_generated === "1" ? (
                  <>
                    <Tooltip
                      id="preview-post-draft-changes-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="preview-post-draft-changes-invoice-tooltip"
                      data-tooltip-content="Preview Post Draft Changes Invoice"
                      data-tooltip-place="top"
                    >
                      <div
                        onClick={() => {
                          navigate("/invoice", {
                            state: {
                              invoiceMeta: {
                                post_draft_invoice_id:
                                  row.original?.post_draft_invoice_id,
                              },
                              assignId: row.original?.assign_id,
                              isInvoicePreview: true,
                            },
                          });
                        }}
                      >
                        <InvoiceIcon />
                      </div>
                    </div>
                  </>
                ) : row.original.is_post_draft === "yes" ? (
                  <>
                    <Tooltip
                      id="generate-post-draft-changes-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff==",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="generate-post-draft-changes-invoice-tooltip"
                      data-tooltip-content="Generate Post Draft Changes Invoice"
                      data-tooltip-place="top"
                    >
                      <GenerateInvoiceModal
                        jobData={row.original}
                        setIsUpdated={setIsUpdated}
                      />
                    </div>
                  </>
                ) : null}
                {row.original.invoice_genrated === "Yes" &&
                row.original.job_status === "Completed" ? (
                  <>
                    <Tooltip
                      id="preview-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="preview-invoice-tooltip"
                      data-tooltip-content="Preview Invoice"
                      data-tooltip-place="top"
                    >
                      <div
                        onClick={() => {
                          const path =
                            row.original.is_minimum_billing === "1"
                              ? "/genrate/invoice/minimum-balance"
                              : "/invoice";
                          navigate(path, {
                            state: {
                              invoiceMeta: {
                                invoice_id: row.original?.invoice_id,
                              },
                              assignId: row.original?.assign_id,
                              isInvoicePreview: true,
                            },
                          });
                    
                        }}
                      >
                        <InvoiceIcon />
                      </div>
                    </div>
                  </>
                ) : row.original.job_status === "Completed" ? (
                  <>
                    <Tooltip
                      id="generate-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="generate-invoice-tooltip"
                      data-tooltip-content="Generate Invoice"
                      data-tooltip-place="top"
                    >
                      <GenerateInvoiceModal
                        type={
                          row.original.is_minimum_billing === "1"
                            ? "minimum"
                            : "invoice"
                        }
                        jobData={row.original}
                        setIsUpdated={setIsUpdated}
                      />
                    </div>
                  </>
                ) : null}

                <Tooltip
                  id="edit-assign-job-tooltip"
                  style={{
                    background: "#000",
                    color: "#fff",
                  }}
                  opacity={0.9}
                />
                <div
                  data-tooltip-id="edit-assign-job-tooltip"
                  data-tooltip-content="Edit Assigned Job"
                  data-tooltip-place="top"
                >
                  <EditAssignJobModal
                    assignJobData={row.original}
                    setIsUpdated={setIsUpdated}
                  />
                </div>
                {row.original.job_status !== "Completed" && (
                  <>
                    <Tooltip
                      id="delete-assign-job-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="delete-assign-job-tooltip"
                      data-tooltip-content="Delete Assigned Job"
                      data-tooltip-place="top"
                    >
                      <DeleteAssignJobModal
                        assignJobData={row.original}
                        setIsUpdated={setIsUpdated}
                      />
                    </div>
                  </>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];
  const columnHeaders = [
    "Sr no",
    "Job Name",
    "Client Name",
    "Assigned to",
    "Duration",
    "Description",
    "Is Minimum Bill",
    "Status",
    "Approved Time",
    "Edit",
  ];

  const userId = userDetails?.member_id;

  let filteredJobs = assignedJobs;

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => assignedJobs, [assignedJobs]);


  useEffect(() => {
    let filtered = fetchedAssignJobList || [];

    if (filters.status) {
      filtered = filtered.filter((job) => job.job_status === filters.status.value);
    }
  
    if (filters.assignedTo) {
      filtered = filtered.filter((job) => job.assign_to === filters.assignedTo.value);
    }
  
    if (filters.client) {
      filtered = filtered.filter((job) => job.client_id === filters.client.value);
    }

    setAssignedJobs(filtered);
  }, [filters, fetchedAssignJobList]);
  

  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Job assigned to", key: "assign_to" },
      { label: "Job assigned on", key: "assigned_on" },
      { label: "Job due date", key: "due_on" },
      { label: "Job Status", key: "job_status" },
      { label: "Is Minimum Bill", key: "is_minimum_billing" },
      { label: "Approved Time", key: "approved_time" },
      { label: "Is Minimum Bill", key: "is_minimum_billing" },
      { label: "Job Description", key: "job_description" },
    ],
    fileName: "Assigned Jobs",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev); 
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); 
    tableInstance.setPageSize(newSize); 
    setCurrentPage(1); 
    setIsUpdated((prev) => !prev); 
  };

  // Function to transform data for CSV export
  const transformDataForCSV = (AssignedJobs) => {
    return AssignedJobs.map((job) => {
      return {
        job_name: job?.job_name,
        client_name: job?.client_name,
        assign_to: job?.assign_to,
        assigned_on: job?.assigned_on,
        due_on: job?.due_on,
        job_status: job?.job_status,
        is_minimum_billing: job?.is_minimum_billing === "1" ? "Yes" : "No",
        approved_time: job?.approved_time ? job?.approved_time : "No",
        is_minimum_billing: job?.is_minimum_billing === "1" ? "Yes" : "No",
        job_description: job?.job_description,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <div className="d-flex justify-content-between align-items-center mr-40 ml-30 mb-15 ">
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">Assign Job</p>
        </div>

        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper w-25">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search..."
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>

          {userDetails?.member_role === "members,team_sub_leader" ||
          userDetails?.member_role === "team_leaders,members" ? (
            <AssignJobModalTL setIsUpdated={setIsUpdated} />
          ) : (
            <AssignJobModal setIsUpdated={setIsUpdated} />
          )}
        </div>
      </div>

      <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={searchIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={assignedToOptions}
            onChange={(option) => onFilterChange("assignedTo", option)}
            value={filters.assignedTo}
            placeholder="Select assigned to"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>

        <div className="relative-wrapper w-25">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) => onFilterChange("status", option)}
            value={filters.status}
            placeholder="Select status"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
        {/* <div className="relative-wrapper w-25">
          <img className="search-icon" src={clientsIcon} alt="search-icon" />
           <AsyncPaginate
              isClearable={true}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={filters.client}
              loadOptions={LoadClientsOptions}
              onChange={(option) => onFilterChange("client", option)}
              additional={{
                page: 1,
              }}
              placeholder={`Select Client`}
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Client found for "${inputValue}"`
                  : "No Client found"
              }
              onError={(error) => {
                ReactHotToast("Error loading clients", "error");
                console.error("Async Paginate Error:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#007bff"
                    : state.isFocused
                    ? "#e0e0e0"
                    : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
       
        </div> */}
      </div>

      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <AssignJobListTable
            tableInstance={tableInstance}
            headers={headers}
            assignedJobs={csvInvoiceData}
            columnHeaders={columnHeaders}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={fetchedAssignJobList}
          />
          
        </>
      )}
    </div>
  );
};

export default AssignJobsContent;
