import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import PageHeader from "../../../../templates/PageHeader";
import ReportsTable from "../../components/ReportsTable";
import ClientWiseTable from "./ClientWiseTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import {
  DownloadSVG,
  fileIcon,
  reportsIcon,
  userIcon1,
} from "../../../../utils/ImportingImages/ImportingImages";
import { ClientWiseReportModal } from "./ClientWiseReportModel";
import { ClientWiseInvoiceListModal } from "./ClientWiseInvoiceListModel";
import { CSVLink } from "react-csv";
import PaginationComponent from "../../../../Components/Pagination/PaginationComponent";

// Bread Crumbs
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  // {
  //   pageName: "Reports",
  //   pageURL: "/reports",
  // },
  {
    pageName: "Client Wise",
    pageURL: "/client-wise",
  },
];

// constructing Headers for React Skelton
const columnHeaders = ["Sr no.", "Client Code", "Name", "Email ID", "Action"];

// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Client Code", key: "client_code" },
    { label: "Email ID", key: "email" },
    { label: "Name", key: "name" },
  ],
  fileName: "Clients List",
};

const ClientWiseContent = ({
  clientsReportData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Client Code",
      accessor: "client_code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    // {
    //   Header: "Email ID",
    //   accessor: "email",
    //   Cell: ({ row }) => row.original?.email?.split(',')[0]?.trim() || "N.A"
    // },
    {
      Header: "Email ID",
      accessor: "email",
      Cell: ({ row }) => {
        const additionalData = row.original?.additional_data;
        return additionalData?.primary_email || "N.A";
      },
    },

    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            // style={{ marginRight: "19%", cursor: "pointer" }}
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Show more"
            data-tooltip-place="top"
          >
            <ClientWiseReportModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
          <Tooltip
            id="invoice-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            style={{ cursor: "pointer" }}
            data-tooltip-id="invoice-client-tooltip"
            data-tooltip-content="Get Invoices"
            data-tooltip-place="top"
          >
            <ClientWiseInvoiceListModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);

  const data = useMemo(() => clientsReportData, [clientsReportData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  //************************* */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // localStorage.setItem("currentPage", page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    // localStorage.setItem("pageSize", newSize);
    // localStorage.setItem("currentPage", 1);
    setIsUpdated((prev) => !prev);
  };
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}

      <div className="d-flex justify-content-between align-items-center  mr-40 ml-30 mb-15 ">
        {/* Title on the Left */}
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">Client Wise</p>
        </div>

        {/* Right side: Search bar and Button */}
        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper w-25">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search..."
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Clients Table  */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <ClientWiseTable
            tableInstance={tableInstance}
            headers={headers}
            clientWise={clientsReportData}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            dataList={clientsReportData}
          />
        </>
      )}
    </div>
  );
};

export default ClientWiseContent;
