// import Modal from "react-bootstrap/Modal";
// import { EyeIconWithCircle } from "../../../utils/ImportingImages/ImportingImages";
// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
// import { useNavigate } from "react-router-dom";
// import { DatePicker } from "antd";
// import {
//   isGreaterThan10,
//   getTwelveHoursTime,
// } from "../../../utils/utilities/utilityFunctions";
// import styles from "./MemberTimeEntries.module.css";
// import { nnAPIKey } from "../../../Context/ApiContext/ApiContext";
// import Calendar from "react-calendar";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";
// import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";

// const MyVerticallyCenteredModal = (props) => {
//   const { userDetails } = useContext(ContextAPI);
//   const [entryDetails, setEntryDetails] = useState({
//     calendarDate: new Date(),
//   });
//   const userRole = localStorage.getItem("userRole");
//   const formatDateToYYYYMMDD = (inputDate) => {
//     const date = new Date(inputDate);
//     const dd = isGreaterThan10(date.getDate());
//     const mm = isGreaterThan10(date.getMonth() + 1);
//     const yyyy = date.getFullYear();
//     return `${yyyy}-${mm}-${dd}`;
//   };
//   const formatDateToDDMMYYYY = (inputDate) => {
//     const date = new Date(inputDate);
//     const dd = isGreaterThan10(date.getDate());
//     const mm = isGreaterThan10(date.getMonth() + 1);
//     const yyyy = date.getFullYear();
//     return `${dd}-${mm}-${yyyy}`;
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       dialogClassName="modal-fullscreen"

//       // size="xl"
//       // aria-labelledby="contained-modal-title-vcenter"
//       // centered
//       // className="modal-xl"
//       // style={{ height: "100%" }}
//       // size="lg"
//       // aria-labelledby="contained-modal-title-vcenter"
//       // centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <span className="modal-title">Time Entries</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body
//         className="modal-body"

//         // style={{ width: "100%", overflowY: "auto" }}
//       >
//         <section className="main-content_form-section d-flex flex-column gap-3">
//           <div className="d-flex gap-4 primary-font-color">
//             <div>
//               <span className="fs-5 fw-bold">Member Name : </span>
//               <span className="fs-6 fw-semibold">{props?.memberName}</span>
//             </div>
//             {(userRole === "it_member" ||
//               userRole === "operation_member" ||
//               userDetails?.member_role === "it_member" ||
//               userDetails?.member_role === "operation_member") && (
//               <div>
//                 <span className="fs-5 fw-bold">Team Name : </span>
//                 <span className="fs-6 fw-semibold">{props?.teamName}</span>
//               </div>
//             )}
//           </div>
//           <div className="gap-5 d-flex align-items-start m-auto">
//             <div className="width-40 mt-3">
//               <Calendar
//                 className={`${styles.reactCalendar} ${styles.customReactCalendar}`}
//                 maxDate={new Date()}
//                 onChange={(date) => {
//                   const formattedDate = formatDateToYYYYMMDD(date);
//                   props.onDateSelect(formattedDate);
//                   setEntryDetails((prev) => ({ ...prev, calendarDate: date }));
//                 }}
//               />
//             </div>

//             <div
//               className="mt-3"
//               style={{
//                 background: "#e0fbfc",
//                 borderRadius: "10px",
//                 boxShadow: "1px 2px 4px gray",
//                 width: "100%",
//                 padding: "20px 20px",
//               }}
//             >
//               <div className="d-flex justify-content-between mb-3">
//                 <h3 style={{ color: "#00263d", fontSize: "26px" }}>
//                   Date: {formatDateToDDMMYYYY(entryDetails?.calendarDate)}
//                 </h3>

//                 <p style={{ width: "325px" }}>
//                   <span className="fs-5">
//                     Total Working Hours:{" "}
//                     {props?.memberTimeEntries.length > 0 ? (
//                       props?.memberTimeEntries
//                         .filter((a) => a.total_time_for_days)
//                         .map((e, index) => (
//                           <span key={index}>
//                             <span className=""> {e.total_time_for_days}</span>
//                           </span>
//                         ))
//                     ) : (
//                       <div className="message">0</div>
//                     )}
//                   </span>
//                 </p>
//               </div>

//               {props?.memberTotalTime &&
//                 Object.keys(props?.memberTotalTime).length > 0 && (
//                   <div className="d-flex gap-5">
//                     {props?.memberTotalTime?.total_billable_hours !== "" && (
//                       <p>
//                         <span className="fs-6">Billable Hours :</span>
//                         <span className="fw-bold fs-6 mx-1">
//                           {props?.memberTotalTime?.total_billable_hours}
//                         </span>
//                       </p>
//                     )}

//                     {props?.memberTotalTime?.total_side_works_hours !== "" && (
//                       <p>
//                         <span className="fs-6">Side-works Hours :</span>
//                         <span className="fw-bold fs-6 mx-1">
//                           {props?.memberTotalTime?.total_side_works_hours}
//                         </span>
//                       </p>
//                     )}

//                     {props?.memberTotalTime?.total_advance_billing_hours !==
//                       "" && (
//                       <p>
//                         <span className="fs-6">Advanced Billing Hours :</span>
//                         <span className="fw-bold fs-6 mx-1">
//                           {props?.memberTotalTime?.total_advance_billing_hours}
//                         </span>
//                       </p>
//                     )}
//                   </div>
//                 )}

//               <div
//                 className=" d-flex justify-content-space-between "
//                 style={{ backgroundColor: "#fff" }}
//               >
//                 <div
//                   className="w-100"
//                   style={
//                     props.memberTimeEntries.length > 0
//                       ? { height: "650px", overflowY: "auto" }
//                       : { height: "auto", overflowY: "visible" }
//                   }
//                 >
//                   <table
//                     className="table table-bordered"
//                     style={{ marginBottom: "0px" }}
//                   >
//                     <tbody>
//                       {/*
//                     {props?.isLoading ? "loading" :
//                       {props.memberTimeEntries.map((entry, index) => (
//                         <React.Fragment key={entry.id}>
//                           <tr>
//                             <th
//                               style={{
//                                 width: 100,
//                                 color: "#00263d",
//                                 background: "#fff",
//                               }}
//                             >
//                               {getTwelveHoursTime(
//                                 entry.work_start_time
//                                   .split(":")
//                                   .slice(0, -1)
//                                   .join(":")
//                               )}
//                             </th>
//                             <td rowSpan={2} valign="middle" className="">
//                               <div
//                                 className="d-flex justify-content-between align-items-center "
//                                 style={{ lineHeight: "1.7" }}
//                               >
//                                 <div>
//                                   <div>
//                                     <span style={{ fontWeight: "bold" }}>
//                                       Task Name:
//                                     </span>{" "}
//                                     {entry.task_name}
//                                   </div>
//                                   <div>
//                                     <span style={{ fontWeight: "bold" }}>
//                                       Status:
//                                     </span>
//                                     <Stack
//                                       direction="horizontal"
//                                       style={{
//                                         marginTop: "-24px",
//                                         marginLeft: "56px",
//                                       }}
//                                     >
//                                       {entry.time_entries_status ===
//                                       "pending" ? (
//                                         <Badge bg="warning" text="dark">
//                                           Pending
//                                         </Badge>
//                                       ) : entry.time_entries_status ===
//                                         "approved" ? (
//                                         <Badge bg="success">Approved</Badge>
//                                       ) : null}
//                                     </Stack>
//                                   </div>
//                                   <div>
//                                     <span style={{ fontWeight: "bold" }}>
//                                       Description:
//                                     </span>{" "}
//                                     {entry.work_description}
//                                   </div>
//                                   <div>
//                                     <span style={{ fontWeight: "bold" }}>
//                                       Entries Type:
//                                     </span>{" "}
//                                     {entry.time_entries_type}
//                                   </div>
//                                 </div>
//                               </div>
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#00263d",
//                                 background: "#fff",
//                               }}
//                             >
//                               {getTwelveHoursTime(
//                                 entry.work_end_time
//                                   .split(":")
//                                   .slice(0, -1)
//                                   .join(":")
//                               )}
//                             </th>
//                           </tr>

//                           {index < props.memberTimeEntries.length - 1 &&
//                             entry.work_end_time !==
//                               props.memberTimeEntries[index + 1]
//                                 .work_start_time && (
//                               <tr>
//                                 <th
//                                   className=""
//                                   style={{
//                                     background: "rgb(207 207 207)",
//                                     color: "rgb(131 128 128)",
//                                   }}
//                                 >
//                                   <div className="d-flex justify-content-between align-items-center">
//                                     <div>
//                                       {getTwelveHoursTime(
//                                         entry.work_end_time
//                                           .split(":")
//                                           .slice(0, -1)
//                                           .join(":")
//                                       )}{" "}
//                                       {getTwelveHoursTime(
//                                         props.memberTimeEntries[
//                                           index + 1
//                                         ].work_start_time
//                                           .split(":")
//                                           .slice(0, -1)
//                                           .join(":")
//                                       )}
//                                     </div>
//                                   </div>
//                                 </th>
//                                 <td
//                                   style={{
//                                     background: "rgb(207 207 207)",
//                                   }}
//                                 >
//                                   <div className="d-flex justify-content-between align-items-center">
//                                     <div
//                                       style={{
//                                         marginTop: "7px",
//                                         color: "rgb(131 128 128)",
//                                       }}
//                                     >
//                                       {"No Time Entry"}
//                                     </div>
//                                   </div>
//                                 </td>
//                               </tr>
//                             )}
//                         </React.Fragment>
//                       ))}
//                       {props.memberTimeEntries.length === 0 && (
//                         <tr>
//                           <td colSpan="2">
//                             <div className="message">
//                               No time entries available
//                             </div>
//                           </td>
//                         </tr>
//                       )}
//                     } */}
//                       {props?.isLoading ? (
//                         <div style={{ margin: "2px" }}>
//                           <div
//                             style={{
//                               background: "#00263d",
//                               width: "fit-content",
//                               padding: "5px",
//                               borderRadius: "5px",
//                             }}
//                           >
//                             <SpinningLoader myColor={"#00263d"} />
//                           </div>
//                         </div>
//                       ) : props?.memberTimeEntries?.length === 0 ? (
//                         <tr>
//                           <td colSpan="2">
//                             <div className="message">
//                               No time entries available
//                             </div>
//                           </td>
//                         </tr>
//                       ) : (
//                         props.memberTimeEntries.map((entry, index) => (
//                           <React.Fragment key={entry.id}>
//                             <tr>
//                               <th
//                                 style={{
//                                   width: 100,
//                                   color: "#00263d",
//                                   background: "#fff",
//                                 }}
//                               >
//                                 {getTwelveHoursTime(
//                                   entry.work_start_time
//                                     .split(":")
//                                     .slice(0, -1)
//                                     .join(":")
//                                 )}
//                               </th>
//                               <td rowSpan={2} valign="middle">
//                                 <div
//                                   className="d-flex justify-content-between align-items-center"
//                                   style={{ lineHeight: "1.7" }}
//                                 >
//                                   <div>
//                                     <div>
//                                       <span style={{ fontWeight: "bold" }}>
//                                         Task Name:
//                                       </span>{" "}
//                                       {entry.task_name}
//                                     </div>
//                                     <div>
//                                       <span style={{ fontWeight: "bold" }}>
//                                         Status:
//                                       </span>
//                                       <Stack
//                                         direction="horizontal"
//                                         style={{
//                                           marginTop: "-24px",
//                                           marginLeft: "56px",
//                                         }}
//                                       >
//                                         {entry.time_entries_status ===
//                                         "pending" ? (
//                                           <Badge bg="warning" text="dark">
//                                             Pending
//                                           </Badge>
//                                         ) : entry.time_entries_status ===
//                                           "approved" ? (
//                                           <Badge bg="success">Approved</Badge>
//                                         ) : null}
//                                       </Stack>
//                                     </div>
//                                     <div>
//                                       <span style={{ fontWeight: "bold" }}>
//                                         Description:
//                                       </span>{" "}
//                                       {entry.work_description}
//                                     </div>
//                                     <div>
//                                       <span style={{ fontWeight: "bold" }}>
//                                         Entries Type:
//                                       </span>{" "}
//                                       {entry.time_entries_type}
//                                     </div>
//                                   </div>
//                                 </div>
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 style={{ color: "#00263d", background: "#fff" }}
//                               >
//                                 {getTwelveHoursTime(
//                                   entry.work_end_time
//                                     .split(":")
//                                     .slice(0, -1)
//                                     .join(":")
//                                 )}
//                               </th>
//                             </tr>

//                             {index < props.memberTimeEntries.length - 1 &&
//                               entry.work_end_time !==
//                                 props.memberTimeEntries[index + 1]
//                                   .work_start_time && (
//                                 <tr>
//                                   <th
//                                     style={{
//                                       background: "rgb(207 207 207)",
//                                       color: "rgb(131 128 128)",
//                                     }}
//                                   >
//                                     <div className="d-flex justify-content-between align-items-center">
//                                       <div>
//                                         {getTwelveHoursTime(
//                                           entry.work_end_time
//                                             .split(":")
//                                             .slice(0, -1)
//                                             .join(":")
//                                         )}{" "}
//                                         {getTwelveHoursTime(
//                                           props.memberTimeEntries[
//                                             index + 1
//                                           ].work_start_time
//                                             .split(":")
//                                             .slice(0, -1)
//                                             .join(":")
//                                         )}
//                                       </div>
//                                     </div>
//                                   </th>
//                                   <td
//                                     style={{
//                                       background: "rgb(207 207 207)",
//                                     }}
//                                   >
//                                     <div className="d-flex justify-content-between align-items-center">
//                                       <div
//                                         style={{
//                                           marginTop: "7px",
//                                           color: "rgb(131 128 128)",
//                                         }}
//                                       >
//                                         No Time Entry
//                                       </div>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               )}
//                           </React.Fragment>
//                         ))
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const MemberTimeEntriesModal = ({
//   memberUserId,
//   setIsUpdated,
//   memberName,
//   teamName,
// }) => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [modalShow, setModalShow] = useState(false);
//   const [memberTimeEntries, setMemberTimeEntries] = useState([]);
//   const [memberTotalTime, setMemberTotalTime] = useState({});
//   const { mainURL, userDetails } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);

//   const token = localStorage.getItem("token");
//   const bearer = "Bearer " + token;
//   const newBearer = bearer.replace(/['"]+/g, "");

//   const formatDateToYYYYMMDD = (inputDate) => {
//     const date = new Date(inputDate);
//     const dd = isGreaterThan10(date.getDate());
//     const mm = isGreaterThan10(date.getMonth() + 1);
//     const yyyy = date.getFullYear();
//     return `${yyyy}-${mm}-${dd}`;
//   };

//   const timeEntries = async () => {
//     const userId =
//       localStorage.getItem("userId") ?? userDetails?.member_id ?? null;
//     const formattedDate = formatDateToYYYYMMDD(selectedDate);

//     const url = `${mainURL}get/members/daily-time-sheet/${userId}/${memberUserId}/${formattedDate}`;

//     const authentication = {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       "Nn-Api-Key": nnAPIKey,
//       Authorization: newBearer,
//     };

//     try {
//       setIsLoading(true);
//       const result = await axios.get(url, {
//         headers: authentication,
//       });

//       console.log("result", result);

//       if (result.status === 200) {
//         setMemberTimeEntries(result.data.time_sheet || []);
//         setMemberTotalTime(result.data.time_biffurcte || {});
//         setIsLoading(false);
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         ReactHotToast("Unauthorized access.");
//       }
//     }
//   };

//   useEffect(() => {
//     if (modalShow) {
//       timeEntries(selectedDate);
//     }
//   }, [modalShow, selectedDate]);

//   const handleDateSelect = (date) => {
//     setSelectedDate(date);
//   };

//   console.log("memberTotalTime", memberTotalTime);
//   console.log("memberName", memberName);
//   console.log("teamName", teamName);

//   return (
//     <>
//       <div
//         onClick={() => {
//           setModalShow(true);
//         }}
//         className="cursor-pointer"
//       >
//         <EyeIconWithCircle />
//       </div>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         memberTimeEntries={memberTimeEntries}
//         memberTotalTime={memberTotalTime}
//         onHide={() => setModalShow(false)}
//         memberUserId={memberUserId}
//         setIsUpdated={setIsUpdated}
//         calendarDate={selectedDate}
//         memberName={memberName}
//         teamName={teamName}
//         onDateSelect={handleDateSelect}
//         isLoading={isLoading}
//       />
//     </>
//   );
// };

import Modal from "react-bootstrap/Modal";
import {
  DownloadSVG,
  EyeIconWithCircle,
} from "../../../utils/ImportingImages/ImportingImages";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import {
  isGreaterThan10,
  getTwelveHoursTime,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import styles from "./MemberTimeEntries.module.css";
import { nnAPIKey } from "../../../Context/ApiContext/ApiContext";
import Calendar from "react-calendar";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";

const MyVerticallyCenteredModal = (props) => {
  const { userDetails, mainURL } = useContext(ContextAPI);
  const [entryDetails, setEntryDetails] = useState({
    calendarDate: new Date(),
  });
  const userRole = localStorage.getItem("userRole");
  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const dd = isGreaterThan10(date.getDate());
    const mm = isGreaterThan10(date.getMonth() + 1);
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const formatDateToDDMMYYYY = (inputDate) => {
    const date = new Date(inputDate);
    const dd = isGreaterThan10(date.getDate());
    const mm = isGreaterThan10(date.getMonth() + 1);
    const yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };
  const handleDownload = async () => {
    const requestData = {
      date: formatDateToYYYYMMDD(entryDetails?.calendarDate),
      user_ids: props?.memberUserId,
    };

    try {
      const response = await axios.post(
        `${mainURL}export/member-time-entries`,
        requestData,
        {
          headers: headerOptions(),
          responseType: "arraybuffer",
        }
      );

      if (response?.data?.error) {
        ReactHotToast(response?.data?.message, "error");
        return;
      }

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${props?.memberName}_${formatDateToYYYYMMDD(
        entryDetails?.calendarDate
      )}.pdf`;
      link.click();

      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      // Handle errors
      if (error?.response?.data) {
        ReactHotToast(
          error?.response?.data?.message ||
            "You haven't added any time entries yet",
          "error"
        );
      } else {
        ReactHotToast("An error occurred while fetching the PDF.", "error");
      }
      console.error("Download error:", error);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-fullscreen"

      // size="xl"
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
      // className="modal-xl"
      // style={{ height: "100%" }}
      // size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Time Entries</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="modal-body"

        // style={{ width: "100%", overflowY: "auto" }}
      >
        <section className="main-content_form-section d-flex flex-column gap-3">
          <div className="d-flex gap-4 primary-font-color">
            <div>
              <span className="fs-5 fw-bold">Member Name : </span>
              <span className="fs-6 fw-semibold">{props?.memberName}</span>
            </div>
            {(userRole === "it_member" ||
              userRole === "operation_member" ||
              userDetails?.member_role === "it_member" ||
              userDetails?.member_role === "operation_member") && (
              <div>
                <span className="fs-5 fw-bold">Team Name : </span>
                <span className="fs-6 fw-semibold">{props?.teamName}</span>
              </div>
            )}
          </div>
          <div className="gap-5 d-flex align-items-start m-auto">
            <div className="width-40 mt-3">
              <Calendar
                className={`${styles.reactCalendar} ${styles.customReactCalendar}`}
                maxDate={new Date()}
                onChange={(date) => {
                  const formattedDate = formatDateToYYYYMMDD(date);
                  props.onDateSelect(formattedDate);
                  setEntryDetails((prev) => ({ ...prev, calendarDate: date }));
                }}
              />
            </div>

            <div
              className="mt-3"
              style={{
                background: "#e0fbfc",
                borderRadius: "10px",
                boxShadow: "1px 2px 4px gray",
                width: "100%",
                padding: "20px 20px",
              }}
            >
              <div className="d-flex justify-content-between mb-3">
                <h3 style={{ color: "#00263d", fontSize: "26px" }}>
                  Date: {formatDateToDDMMYYYY(entryDetails?.calendarDate)}
                </h3>

                <p style={{ width: "325px" }}>
                  <span className="fs-5">
                    Total Working Hours:{" "}
                    {props?.memberTimeEntries.length > 0 ? (
                      props?.memberTimeEntries
                        .filter((a) => a.total_time_for_days)
                        .map((e, index) => (
                          <span key={index}>
                            <span className=""> {e.total_time_for_days}</span>
                          </span>
                        ))
                    ) : (
                      <div className="message">0</div>
                    )}
                  </span>
                </p>
              </div>

              {props?.memberTotalTime &&
                Object.keys(props?.memberTotalTime).length > 0 && (
                  <div className="d-flex justify-content-between gap-4">
                    {props?.memberTotalTime?.total_billable_hours !== "" && (
                      <p>
                        <span className="fs-6">Billable Hours :</span>
                        <span className="fw-bold fs-6 mx-1">
                          {props?.memberTotalTime?.total_billable_hours}
                        </span>
                      </p>
                    )}

                    {props?.memberTotalTime?.total_side_works_hours !== "" && (
                      <p>
                        <span className="fs-6">Side-works Hours :</span>
                        <span className="fw-bold fs-6 mx-1">
                          {props?.memberTotalTime?.total_side_works_hours}
                        </span>
                      </p>
                    )}

                    {props?.memberTotalTime?.total_advance_billing_hours !==
                      "" && (
                      <p>
                        <span className="fs-6">Advanced Billing Hours :</span>
                        <span className="fw-bold fs-6 mx-1">
                          {props?.memberTotalTime?.total_advance_billing_hours}
                        </span>
                      </p>
                    )}

                    <p onClick={handleDownload}>
                      <DownloadSVG />
                    </p>
                  </div>
                )}

              <div
                className=" d-flex justify-content-space-between "
                style={{ backgroundColor: "#fff" }}
              >
                <div
                  className="w-100"
                  style={
                    props.memberTimeEntries.length > 0
                      ? { height: "650px", overflowY: "auto" }
                      : { height: "auto", overflowY: "visible" }
                  }
                >
                  <table
                    className="table table-bordered"
                    style={{ marginBottom: "0px" }}
                  >
                    <tbody>
                      {props?.isLoading ? (
                        <div style={{ margin: "2px" }}>
                          <div
                            style={{
                              background: "#00263d",
                              width: "fit-content",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            <SpinningLoader myColor={"#00263d"} />
                          </div>
                        </div>
                      ) : props?.memberTimeEntries?.length === 0 ? (
                        <tr>
                          <td colSpan="2">
                            <div className="message">
                              No time entries available
                            </div>
                          </td>
                        </tr>
                      ) : (
                        props.memberTimeEntries.map((entry, index) => (
                          <React.Fragment key={entry.id}>
                            <tr>
                              <th
                                style={{
                                  width: 100,
                                  color: "#00263d",
                                  background: "#fff",
                                }}
                              >
                                {getTwelveHoursTime(
                                  entry.work_start_time
                                    .split(":")
                                    .slice(0, -1)
                                    .join(":")
                                )}
                              </th>
                              <td rowSpan={2} valign="middle">
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={{ lineHeight: "1.7" }}
                                >
                                  <div>
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Task Name:
                                      </span>{" "}
                                      {entry.task_name}
                                    </div>
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Status:
                                      </span>
                                      <Stack
                                        direction="horizontal"
                                        style={{
                                          marginTop: "-24px",
                                          marginLeft: "56px",
                                        }}
                                      >
                                        {entry.time_entries_status ===
                                        "pending" ? (
                                          <Badge bg="warning" text="dark">
                                            Pending
                                          </Badge>
                                        ) : entry.time_entries_status ===
                                          "approved" ? (
                                          <Badge bg="success">Approved</Badge>
                                        ) : null}
                                      </Stack>
                                    </div>
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Description:
                                      </span>{" "}
                                      {entry.work_description}
                                    </div>
                                    <div>
                                      <span style={{ fontWeight: "bold" }}>
                                        Entries Type:
                                      </span>{" "}
                                      {entry.time_entries_type}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th
                                style={{ color: "#00263d", background: "#fff" }}
                              >
                                {getTwelveHoursTime(
                                  entry.work_end_time
                                    .split(":")
                                    .slice(0, -1)
                                    .join(":")
                                )}
                              </th>
                            </tr>

                            {index < props.memberTimeEntries.length - 1 &&
                              entry.work_end_time !==
                                props.memberTimeEntries[index + 1]
                                  .work_start_time && (
                                <tr>
                                  <th
                                    style={{
                                      background: "rgb(207 207 207)",
                                      color: "rgb(131 128 128)",
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        {getTwelveHoursTime(
                                          entry.work_end_time
                                            .split(":")
                                            .slice(0, -1)
                                            .join(":")
                                        )}{" "}
                                        {getTwelveHoursTime(
                                          props.memberTimeEntries[
                                            index + 1
                                          ].work_start_time
                                            .split(":")
                                            .slice(0, -1)
                                            .join(":")
                                        )}
                                      </div>
                                    </div>
                                  </th>
                                  <td
                                    style={{
                                      background: "rgb(207 207 207)",
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div
                                        style={{
                                          marginTop: "7px",
                                          color: "rgb(131 128 128)",
                                        }}
                                      >
                                        No Time Entry
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                          </React.Fragment>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export const MemberTimeEntriesModal = ({
  memberUserId,
  setIsUpdated,
  memberName,
  teamName,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modalShow, setModalShow] = useState(false);
  const [memberTimeEntries, setMemberTimeEntries] = useState([]);
  const [memberTotalTime, setMemberTotalTime] = useState({});
  const { mainURL, userDetails } = useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");
  const bearer = "Bearer " + token;
  const newBearer = bearer.replace(/['"]+/g, "");

  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const dd = isGreaterThan10(date.getDate());
    const mm = isGreaterThan10(date.getMonth() + 1);
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const timeEntries = async () => {
    const userId =
      localStorage.getItem("userId") ?? userDetails?.member_id ?? null;
    const formattedDate = formatDateToYYYYMMDD(selectedDate);

    const url = `${mainURL}get/members/daily-time-sheet/${userId}/${memberUserId}/${formattedDate}`;

    const authentication = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Nn-Api-Key": nnAPIKey,
      Authorization: newBearer,
    };

    try {
      setIsLoading(true);
      const result = await axios.get(url, {
        headers: authentication,
      });

      // console.log("result", result);

      if (result.status === 200) {
        setMemberTimeEntries(result.data.time_sheet || []);
        setMemberTotalTime(result.data.time_biffurcte || {});
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        ReactHotToast("Unauthorized access.");
      }
    }
  };

  useEffect(() => {
    if (modalShow) {
      timeEntries(selectedDate);
    }
  }, [modalShow, selectedDate]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
        className="cursor-pointer"
      >
        <EyeIconWithCircle />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        memberTimeEntries={memberTimeEntries}
        memberTotalTime={memberTotalTime}
        onHide={() => setModalShow(false)}
        memberUserId={memberUserId}
        setIsUpdated={setIsUpdated}
        calendarDate={selectedDate}
        memberName={memberName}
        teamName={teamName}
        onDateSelect={handleDateSelect}
        isLoading={isLoading}
      />
    </>
  );
};
