import React, { useContext, useState, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import axios from "axios";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import {
  clientsIcon1,
  searchIcon,
  TrashSVG,
  EditSVG,
} from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import BillingServicesTable from "./BillingServicesTable";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import {
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import { EditBillingServicesModal } from "./EditBillingServicesModal";
import PaginationComponent from "../../../../Components/Pagination/PaginationComponent";

const BillingServicesContent = ({
  billingServicesList,
  isLoading,
  setIsUpdated,
  currentPage,
  setCurrentPage,
  searchInput,
  setSearchInput,
  pageSize,
  totalPages,
  setPageSize,
  handleSearch,
  handleInputChange,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { mainURL, logout, userDetails } = useContext(ContextAPI);

  const [isUserValid, setIsUserValid] = useState(false);
  const [billingServiceName, setBillingServiceName] = useState("");

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Billing Services",
      pageURL: "/clients/billing-services",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
      Cell: ({ row }) => row.original.sr_no,
    },
    
    {
      Header: "Service",
      accessor: "services_name",
    },
    {
      Header: "Status",
      accessor: "service_status",
      Cell: ({ row }) =>
        row.original.service_status === "active" ? "Active" : "Suspended",
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-billing-service-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            style={{ marginRight: "12%" }}
            data-tooltip-id="edit-billing-service-tooltip"
            data-tooltip-content="Edit Service"
            data-tooltip-place="top"
          >
            <EditBillingServicesModal
              setIsUpdated={setIsUpdated}
              billingServiceData={row.original}
            />
          </div>

          <Tooltip
            id="delete-billing-service-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          {/* <div
            data-tooltip-id="delete-billing-service-tooltip"
            data-tooltip-content="Delete Service"
            data-tooltip-place="top"
          >
            <TrashSVG />
          </div> */}
        </div>
      ),
    },
  ];

  const columnHeaders = ["Sr no.", "Service", "Status", "Edit"];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => billingServicesList, [billingServicesList]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Service", key: "services_name" },
      { label: "Status", key: "service_status" },
    ],
    fileName: "Billing Services",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); 
    tableInstance.setPageSize(newSize); 
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };
  // Adding new billing service API
  const addNewBillingService = async () => {
    const body = {
      billing_services: billingServiceName,
      current_user:
        localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
    };

    setIsUserValid(() => true);

    try {
      const url = `${mainURL}services/billing`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setBillingServiceName(() => "");
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsUserValid(() => false);
    }
  };

  const handleAddBillingService = (e) => {
    e.preventDefault();
    if (billingServiceName) {
      addNewBillingService();
    } else {
      ReactHotToast("Please add billing service name!", "error");
    }
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom custom-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={clientsIcon1} alt="members" />
          <p className="m-0 fs-4">Billing Service</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>

      <section className="main-content_form-section gap-3 d-flex flex-column justify-content-start align-items-center width-65 m-auto">
        <form
          onSubmit={handleAddBillingService}
          className="w-100 mt-5 d-flex justify-content-between align-items-end gap-3"
        >
          <div className="flex-1 form-group">
            <label htmlFor="name">Service Name:</label>
            <input
              id="name"
              name="name"
              placeholder="Eg: ITR Filling"
              type="text"
              required
              value={billingServiceName}
              onChange={(e) => setBillingServiceName(() => e.target.value)}
            />
          </div>
          <button type="submit" className=" custom-btn">
            {isUserValid ? <SpinningLoader /> : "Add Service"}
          </button>
        </form>

        <div className="d-flex flex-column gap-3 w-100">
          {/* Job Category Table */}
          {isLoading ? (
            <ReactTableSkeleton columnHeaders={columnHeaders} />
          ) : (
            <>
              <BillingServicesTable
                tableInstance={tableInstance}
                headers={headers}
                billingServicesList={billingServicesList}
              />
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                dataList={billingServicesList}
                text="billingServices"
              />
              {/* <div
                className="d-flex justfy-content-center align-items-center gap-3 smallText"
                style={{
                  width: "26%",
                  justifyContent: "space-between",
                  position: "absolute",
                  marginLeft: "90px",
                  bottom: "48px",
                }}
              >
                <div className="d-flex gap-1 align-items-center">
                  <button
                    className="cursor-pointer"
                    style={{ border: "none", background: "transparent" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                      />
                    </svg>
                  </button>
                  <input
                    className="gotoPage-input-field"
                    type="number"
                    defaultValue={currentPage}
                    readOnly
                  />
                  <span className="px-2">/</span>

                  <span> {totalPages}</span>
                  <button
                    style={{ border: "none", background: "transparent" }}
                    className="cursor-pointer"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      billingServicesList?.length < pageSize ||
                      totalPages === 1 ||
                      currentPage === totalPages
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <span className="px-1 smallText">Rows /page</span>
                  <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div> */}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default BillingServicesContent;
