import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import {
  clientsIcon,
  fileIcon,
  invoiceIcon,
  invoiceIcon1,
  searchIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import JobsTable from "../../../Components/JobsTable";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import { DeleteJobModal } from "./DeleteJobModal";
import { EditJobModal } from "./EditJobModal";
import { AddJobsModal } from "./AddJobsModal";
import PaginationComponent from "../../../../../Components/Pagination/PaginationComponent";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import { AsyncPaginate } from "react-select-async-paginate";

const JobsContent = ({
  jobsData,
  isUpdated,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchInput,
  handleInputChange,
  filters,
  setFilters,
  onFilterChange,
  LoadClientsOptions,
  JobsLoadOptions,
}) => {
  const userRole = localStorage.getItem("userRole");
  const { userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getAllJobCategories } = useContext(ContextAPI);

  const [filteredJobs, setFilteredJobs] = useState(jobsData);

  useEffect(() => {
    getAllJobCategories();
  }, []);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Jobs",
      pageURL: "/jobs",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
      Cell: ({ row }) => row.original?.sr_no,
    },
    {
      Header: "BPO No",
      accessor: "bpo_no",
    },
    {
      Header: "Job Name",
      accessor: "job_name",
       Cell: ({ value }) => {
             const isOverflowing = value?.length > 20;
             
             return (
               <>
                 <div
                   data-tooltip-id={isOverflowing ? "tooltip" : undefined}
                   data-tooltip-content={value}
                   style={{
                     maxWidth: "200px",
                     whiteSpace: "nowrap",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                     cursor: isOverflowing ? "pointer" : "default",
                     padding: "8px",
                     textAlign: "left",
                   }}
                 >
                   {value}
                 </div>
         
                 {isOverflowing && (
                   <Tooltip
                     id="tooltip"
                     place="top"
                     effect="solid"
                     style={{
                       backgroundColor: "#212529",
                       color: "#fff",
                       fontWeight: 400,
                       padding: "10px",
                       borderRadius: "5px",
                       fontSize: "14px",
                       width: "400px",
                       wordWrap: "break-word",
                       zIndex: 10,
                     }}
                   />
                 )}
               </>
             );
           },
    },
    
    
    // {
    //   Header: "Job Name",
    //   accessor: "job_name",
    //   Cell: ({ value }) => (
    //     <>
 
    //       <div
    //         data-tooltip-id="job-name-tooltip"  
    //         data-tooltip-content={value}
    //         style={{
    //           maxWidth: "200px",      
    //           whiteSpace: "nowrap",     
    //           overflow: "hidden",        
    //           textOverflow: "ellipsis",  
    //           cursor: "pointer",         
            
    //           padding: "8px",             
    //           textAlign: "left",           
    //         }}
    //       >
    //         {value}
    //       </div>
    
     
    //       <Tooltip
    //         id="job-name-tooltip"  
    //         place="top"
    //         effect="solid"
    //         style={{
    //           backgroundColor: "#306c8b",   
    //           color: "#fff",          
    //           padding: "10px",          
    //           borderRadius: "5px",    
    //           fontSize: "13px",  
    //           lineHeight: "1.6",        
    //           width: "450px",            
    //           wordWrap: "break-word",    
    //           zIndex: 10,                
    //         }}
    //       />
    //     </>
    //   ),
    // },
    // {
    //   Header: "Job Name",
    //   accessor: "job_name",
    // },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Job Arrival Date",
      accessor: "job_arrival_date",
      Cell: ({ row }) => {
        const jobAddOnDate = row.original.job_arrival_date;
        if (!jobAddOnDate) return "N/A";

        const dateObj = new Date(jobAddOnDate);
        if (isNaN(dateObj)) return "N/A";
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return dateObj.toLocaleDateString("en-GB", options);
      },
    },
    {
      Header: "Created By",
      accessor: "job_added_by",
    },
    {
      Header: "Category",
      accessor: "job_category",
    },

    // ...(userRole !== "members,team_sub_leader" &&
    // userRole !== "team_leaders,members"
    //   ? [
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="edit-job-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-job-tooltip"
            data-tooltip-content="Edit Job Details"
            data-tooltip-place="top"
          >
            <EditJobModal jobData={row.original} setIsUpdated={setIsUpdated} />
          </div>

          <Tooltip
            id="delete-job-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-job-tooltip"
            data-tooltip-content="Delete Job"
            data-tooltip-place="top"
          >
            <DeleteJobModal
              jobData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
    //   ]
    // : []),
  ];

  const columnHeaders = [
    "Sr no.",
    "BPO No",
    "Job Name",
    "Client Name",
    "Job Arrival Date",
    "Created By",
    "Category",
    "Edit",
  ];

  useEffect(() => {
    if (
      userDetails?.member_role === "members,team_sub_leader" ||
      userDetails?.member_role === "team_leaders,members"
    ) {
      // const filtered =
      //   jobsData?.filter((e) => e.job_added_by === userDetails?.member_name) ||
      //   [];
      // console.log("filtered", filtered);
      // setFilteredJobs(filtered);
      setFilteredJobs(jobsData);
    } else {
      setFilteredJobs([]);
    }
  }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

  // useEffect(() => {
  //   const uniqueJobs = [];
  //   const jobIds = new Set();

  //   jobsData.forEach((job) => {
  //     if (!jobIds.has(job.job_id)) {
  //       jobIds.add(job.job_id);
  //       uniqueJobs.push(job);
  //     }
  //   });

  //   if (
  //     userDetails?.member_role === "members,team_sub_leader" ||
  //     userDetails?.member_role === "members" ||
  //     userDetails?.member_role === "team_leaders,members"
  //   ) {
  //     const filtered =
  //       uniqueJobs.filter((e) => e.job_added_by === userDetails?.member_name) || [];
  //     setFilteredJobs(filtered);
  //   } else {
  //     setFilteredJobs(uniqueJobs);
  //   }
  // }, [jobsData, userDetails?.member_name, userDetails?.member_role]);

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => jobsData, [jobsData]);

  // const data = useMemo(() => {
  //   return userDetails?.member_role === "members,team_sub_leader" ||
  //     userDetails?.member_role === "members" ||
  //     userDetails?.member_role === "team_leaders,members"
  //     ? filteredJobs
  //     : jobsData;
  // }, [filteredJobs, jobsData, userDetails?.member_role]);

  const data = useMemo(() => {
    let filtered =
      userDetails?.member_role === "members,team_sub_leader" ||
      userDetails?.member_role === "members" ||
      userDetails?.member_role === "team_leaders,members"
        ? filteredJobs
        : jobsData;

    if (filters.client) {
      filtered = filtered.filter(
        (job) => job.client_id === filters.client.value
      );
    }

    if (filters.category) {
      filtered = filtered.filter(
        (job) => job.job_category_name === filters.category.value
      );
    }

    return filtered;
  }, [
    filteredJobs,
    jobsData,
    userDetails?.member_role,
    filters.client,
    filters.category,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    tableInstance.setPageSize(newSize);
    setPageSize(newSize);
    setCurrentPage(1);
    setIsUpdated((prev) => !prev);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headers = {
    headings: [
      { label: "BPO No", key: "bpo_no" },
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Job added by", key: "job_added_by" },
      { label: "Job Category", key: "job_category" },
      { label: "Job Arrival Date", key: "job_arrival_date" },
    ],
    fileName: "Jobs",
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={fileIcon} alt="All Jobs" />
          <p className="m-0 fs-4">All Jobs</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>

          <AddJobsModal setIsUpdated={setIsUpdated} />
        </div>
      </section>
      {/* <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={clientsIcon} alt="search-icon" />
          <AsyncPaginate
            isClearable={true}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
            value={filters.client}
            loadOptions={LoadClientsOptions}
            onChange={(option) => onFilterChange("client", option)}
            additional={{
              page: 1,
            }}
            placeholder={`Select Client`}
            debounceTimeout={300}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? `No Client found for "${inputValue}"`
                : "No Client found"
            }
            onError={(error) => {
              ReactHotToast("Error loading clients", "error");
              console.error("Async Paginate Error:", error);
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e0e0e0"
                  : "white",
                cursor: "pointer",
                color: state.isSelected ? "white" : "black",
                ":hover": {
                  backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>

        <div className="relative-wrapper w-25">
          <img className="search-icon" src={invoiceIcon} alt="search-icon" />
          <AsyncPaginate
            isClearable={true}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
            value={filters.category}
            loadOptions={JobsLoadOptions}
            onChange={(option) => onFilterChange("category", option)}
            additional={{
              page: 1,
            }}
            placeholder={`Select Category`}
            debounceTimeout={300}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? `No Category found for "${inputValue}"`
                : "No Category found"
            }
            onError={(error) => {
              ReactHotToast("Error loading Categorys", "error");
              console.error("Async Paginate Error:", error);
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#007bff"
                  : state.isFocused
                  ? "#e0e0e0"
                  : "white",
                cursor: "pointer",
                color: state.isSelected ? "white" : "black",
                ":hover": {
                  backgroundColor: state.isSelected ? "#0056b3" : "#f1f3f5",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>
      </div> */}

      {/* {/ Jobs Table /} */}
      <div className="mt-5">
        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : (
          <>
            <JobsTable
              tableInstance={tableInstance}
              headers={headers}
              jobData={jobsData}
            />

            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              dataList={jobsData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default JobsContent;
