// import React, { useState, useEffect, useContext, useCallback } from "react";
// import { debounce } from "lodash";
// import AssignJobsContent from "./components/AssignJobsContent";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

// const AssignJobs = () => {
//   const { getAllAssignJobsForPagination, initialState, initialStateLoading } =
//     useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filters, setFilters] = useState({
//     status: null,
//     assignedTo: null,
//   });

//   // To track if it's the initial load
//   const [isFirstLoad, setIsFirstLoad] = useState(true);

//   // Pagination state
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((query, currentFilters, page, limit) => {
//       getAllAssignJobsForPagination(page, limit, query, currentFilters);
//     }, 500),
//     []
//   );

//   // Reset pagination when filters or search changes
//   useEffect(() => {
//     setCurrentPage(1);
//   }, [searchQuery, filters]);

//   // Effect for data fetching
//   // useEffect(() => {
//   //   debouncedSearch(searchQuery, filters, currentPage, pageSize);

//   //   return () => {
//   //     debouncedSearch.cancel();
//   //   };
//   // }, [searchQuery, filters, currentPage, pageSize, isUpdated]);

//   // Effect for data fetching
//   useEffect(() => {
//     if (isFirstLoad) {
//       // On initial load, directly fetch without debounce
//       getAllAssignJobsForPagination(
//         currentPage,
//         pageSize,
//         searchQuery,
//         filters
//       );
//       setIsFirstLoad(false);
//     } else {
//       // Apply debounced search after initial load
//       debouncedSearch(searchQuery, filters, currentPage, pageSize);
//     }

//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [searchQuery, filters, currentPage, pageSize, isUpdated, isFirstLoad]);

//   // Handle filter changes
//   const handleFilterChange = (type, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [type]: value,
//     }));
//   };

//   // Handle search changes
//   const handleSearchChange = (value) => {
//     setSearchQuery(value);
//   };

//   return (
//     <AssignJobsContent
//       setIsUpdated={setIsUpdated}
//       isLoading={initialStateLoading?.getAllAssignJobsForPaginationIsLoading}
//       fetchedAssignJobList={initialState?.assignJobsList}
//       totalPages={initialState?.totalPages}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//       searchQuery={searchQuery}
//       onSearchChange={handleSearchChange}
//       filters={filters}
//       onFilterChange={handleFilterChange}
//     />
//   );
// };

// export default AssignJobs;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";
import AssignJobsContent from "./components/AssignJobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import axios from "axios";
import { headerOptions } from "../../../../utils/utilities/utilityFunctions";
const AssignJobs = () => {
  const { getAllAssignJobsForPagination, initialState, initialStateLoading,mainURL,userId} =
    useContext(ContextAPI);
  const [searchQuery, setSearchQuery] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
    client:null,
    team:null
  });

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query, currentFilters, page, limit) => {
      getAllAssignJobsForPagination(page, limit, query, currentFilters);
    }, 500),
    [isUpdated]
  );

  // Reset pagination when filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);

  // Effect for data fetching
  useEffect(() => {
    if (isFirstLoad) {
      // On initial load, directly fetch without debounce
      getAllAssignJobsForPagination(
        currentPage,
        pageSize,
        searchQuery,
        filters
      );
      setIsFirstLoad(false);
    } else {
      // Apply debounced search after initial load
      debouncedSearch(searchQuery, filters, currentPage, pageSize);
    }

    // Clean up function to cancel the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, filters, currentPage, pageSize, isFirstLoad, isUpdated]);

  // Handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  // Handle search changes
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // async function LoadClientsOptions(search, loadedOptions, { page }) {
  //   try {
  //     // Make the API request to fetch the data
  //     const response = await axios.get(
  //       `${mainURL}/list/all-client/${userId}`,
  //       {
  //         params: {
  //           page,
  //           limit: 10,
  //           searchQuery: search || "",
  //         },
  //         headers: headerOptions(),
  //       }
  //     );

  //     // Transform the data into the required format
  //     const transformedData = response.data?.client_list?.client_data.map(
  //       (item) => {
  //         try {
  //           // Parse the additional client data
  //           const { client_name, additional_data, client_id } = item;
  //           const companyName = additional_data?.company_name || "";
  //           const bpoNo = additional_data?.bpo_no?.toString() || "";
  //           const label = `${client_name} (${companyName}${
  //             bpoNo ? ` - ${bpoNo}` : ""
  //           })`;

  //           // Create the label for the job

  //           return {
  //             value: client_id,
  //             label: label,
  //           };
  //         } catch (parseError) {
  //           console.error("Error parsing item:", parseError);
  //           return {
  //             value: item?.client_id,
  //             label: "Parsing Error - Invalid Job Data",
  //           };
  //         }
  //       }
  //     );
  //     const combinedOptions =
  //     page === 1
  //       ? transformedData
  //       : [...(loadedOptions.options || []), ...transformedData];
        

  //   // Update valueForClient with the transformed data
  //   setValueForClient(combinedOptions);
  //     return {
  //       options: combinedOptions,
  //       hasMore: response.data?.client_list?.hasMore,
  //       additional: {
  //         page: page + 1,
  //       },
  //     };
  //   } catch (error) {
  //     console.error("Comprehensive Error Loading Options:", {
  //       message: error.message,
  //       response: error.response,
  //       request: error.request,
  //     });

  //     return {
  //       options: loadedOptions.options || [],
  //       hasMore: false,
  //     };
  //   }
  // }
  const LoadClientsOptions = async (search, loadedOptions, { page }) => {
    try {
 
      const response = await axios.get(
        `${mainURL}/list/all-client/${userId}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );
  

      const clientData = response.data?.client_list?.client_data || [];
      

      const transformedData = clientData.map((item) => {
        try {
          // Parse the additional client data
          const { client_name, additional_data, client_id } = item;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${bpoNo ? ` - ${bpoNo}` : ""})`;
  
          return {
            value: client_id,
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.client_id,
            label: "Parsing Error - Invalid Client Data",
          };
        }
      });
  
      const combinedOptions =
        page === 1 ? transformedData : [...(loadedOptions.options || []), ...transformedData];
  
      return {
        options: combinedOptions, 
        hasMore: response.data?.client_list?.hasMore ?? false,
        additional: {
          page: page + 1, 
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Client Options:", error);

      return {
        options: loadedOptions.options || [],
        hasMore: false, 
      };
    }
  };
  async function TeamLoadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}get/team/${userId}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
    
      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  return (
    <AssignJobsContent
      isLoading={initialStateLoading?.getAllAssignJobsForPaginationIsLoading}
      fetchedAssignJobList={initialState?.assignJobsList}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      filters={filters}
      onFilterChange={handleFilterChange}
      setIsUpdated={setIsUpdated}
      LoadClientsOptions={LoadClientsOptions}
      TeamLoadOptions={TeamLoadOptions}

    />
  );
};

export default AssignJobs;
