import React, { useContext, useEffect, useState } from "react";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { useLocation, useParams } from "react-router-dom";
import { SendMailAdvanceBilling } from "../../AdvanceBilling/components/SendMailAdvanceBilling";
import { UpdateAdHocInvoiceModel } from "./UpdateAdHocInvoiceModel";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";

const AdHocBillingInvoice = () => {
  const { id } = useParams();
  const { getSingleAdHocInvoice, initialState, mainURL } =
    useContext(ContextAPI);
  const location = useLocation();
  const { row, assignId, clientEmail, adHocMail } = location.state || {};
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const [invoiceMeta, setInvoiceMeta] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    if (id) getSingleAdHocInvoice(id);
  }, [id]);

  useEffect(() => {
    const { singleAdHocInvoice } = initialState;
    if (singleAdHocInvoice) {
      const data = singleAdHocInvoice.invoice_meta
        ? JSON.parse(singleAdHocInvoice.invoice_meta)
        : {};
      setInvoiceMeta(data);
      setInvoiceUrl(singleAdHocInvoice.invoice_url);
    }
  }, [initialState.singleAdHocInvoice]);

  useEffect(() => {
    if (invoiceUrl && isLoading) {
      setIsLoading(false);
      setTimestamp(Date.now());
    }
  }, [invoiceUrl, isLoading]);

  const newInvoiceUrl = invoiceUrl
    ? `${mainURL}/${invoiceUrl}?t=${timestamp}`
    : null;
  const breadcrumbs = location.state?.breadcrumbs || [
    { pageName: "Adv Billing Jobs", pageURL: "/advance-billing" },
    { pageName: "Adv Billing Invoice" },
  ];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>

      <div
        className="d-flex justify-content-end gap-4"
        style={{ marginRight: "30px" }}
      >
        <SendMailAdvanceBilling
          invoiceUrl={newInvoiceUrl}
          assignId={assignId}
          row={row}
          adHocMail={adHocMail}
          clientEmail={clientEmail}
        />
        {/* <UpdateAdHocInvoiceModel
          id={invoiceMeta?.id || initialState.singleAdHocInvoice.id}
          status={invoiceMeta?.payment_status}
          amount={invoiceMeta?.total_amount?.toFixed(2)}
          serviceName={invoiceMeta?.service_name}
          description={invoiceMeta?.service_description}
          vat={invoiceMeta?.vat_rate}
          partialPaid={invoiceMeta?.partial_paid}
          onUpdate={() => { setIsLoading(true); getSingleAdHocInvoice(id); }}
        /> */}
      </div>
      <div
        className="invoice-job"
        style={{ padding: "20px", textAlign: "center" }}
      >
        {isLoading ? (
          <SpinningLoader />
        ) : (
          <iframe
            key={timestamp}
            src={newInvoiceUrl}
            title="Invoice"
            style={{
              width: "100%",
              height: "700px",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
            onLoad={() => setIsLoading(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AdHocBillingInvoice;
