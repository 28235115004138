import React, { useCallback, useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import InvoiceList from "./components/InvoiceList";
import { debounce } from "lodash";
import axios from "axios";
import { headerOptions } from "../../../utils/utilities/utilityFunctions";
// import BillingServicesContent from "./components/BillingServicesContent";

const GetAllInvoice = () => {
  const { getAllInvoice, initialState, initialStateLoading,mainURL,userId } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
    client:null
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // useEffect(() => {
  //   getAllInvoice();
  // }, [isUpdated]);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllInvoice(currentPage, pageSize, searchQuery, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllInvoice(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);
  const onFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  

  const LoadClientsOptions = async (search, loadedOptions, { page }) => {
    try {
      const response = await axios.get(`${mainURL}/list/all-client/${userId}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });
  
      const clientData = response.data?.client_list?.client_data || []; 
  
      const transformedData = clientData.map((item) => {
        try {
  
          const { client_name, additional_data, client_id } = item;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${bpoNo ? ` - ${bpoNo}` : ""})`;
  
          return {
            value: client_id,
            label: label,
          };
        } catch (parseError) {
          console.error("Error parsing item:", parseError);
          return {
            value: item?.client_id,
            label: "Parsing Error - Invalid Client Data",
          };
        }
      });
  
      const combinedOptions = page === 1 ? transformedData : [...(loadedOptions.options || []), ...transformedData];
      const hasMore = response.data?.client_list?.hasMore ?? false; 
      return {
        options: combinedOptions,  
        hasMore: hasMore,         
        additional: {
          page: page + 1,  
        },
      };
    } catch (error) {
      console.error("Error loading client options:", error);

      return {
        options: [],   
        hasMore: false, 
      };
    }
  };
  

  return (
    <InvoiceList
      invoiceData={initialState?.getAllInvoice}
      setIsUpdated={setIsUpdated}
      isLoading={initialStateLoading?.getAllInvoiceIsLoading}
      totalPages={initialState?.totalPages}
      totalRecords={initialState?.totalRecords}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
      setFilters={setFilters}
      filters={filters}
      LoadClientsOptions={LoadClientsOptions}
    
      onFilterChange={onFilterChange} 
    />
  );
};

export default GetAllInvoice;
